import React, { useState } from 'react';
import { FiTrash2 } from "react-icons/fi";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch } from 'react-redux';
import { BsFillMicFill, BsFillMicMuteFill } from 'react-icons/bs'
import { ReactComponent as TransitionIcon } from '../../../images/transition.svg'
import { deleteBackTile, deleteTile, onAddSlide, onRemovePlaySlide, onSelectInnerBackground, rearrangeTiles, removeTtsFromSlides, updateScript } from '../../../Actions/contentAction';
import { AiFillPlusCircle } from "react-icons/ai"
import SweetAlertTwo from '../../SweetAlertTwo';
import Swal from 'sweetalert2';



const VideoTiles = ({ slideData }) => {

  const dispatch = useDispatch()
  const selectedSlideIndex = slideData.findIndex(({ isSelected }) => isSelected === "1")
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState({
    slide: false,
    index: -1
  })
  const [info, setInfo] = useState({
    enable: false,
    id: -1
  })

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };


  const onConfirm = (Swal1) => {
    if (info.slideIndex !== undefined) {
      dispatch(deleteBackTile(info.slideIndex, info.backIndex, Swal1))
    } else {
      if (slideData.length === 1) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'You cannot delete last slide!',
          confirmButtonColor: "#d03e3e"
        })
      } else {
        dispatch(deleteTile(state.slide, state.index, Swal1))
      }
    }
    setInfo({
      enable: false,
      id: -1
    })

  }

  const onCancel = () => {
    setInfo({
      enable: false,
      id: -1
    })
  }
  const onSetRecorder = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(slideData, result.source.index, result.destination.index);
    dispatch(rearrangeTiles(items));
  }

  const onSelectTile = (index) => {
    if (index !== selectedSlideIndex) {
      dispatch(updateScript(index, selectedSlideIndex))
      dispatch(onRemovePlaySlide(selectedSlideIndex))
    }
  }

  const selectInnerBackground = (index, ind) => {
    // e.stopPropagation()
    dispatch(onSelectInnerBackground(index, ind))
  }

  const handleDeleteAlert = (e, slide, index) => {
    e.stopPropagation()
    setState({
      ...state,
      slide: slide,
      index: index
    })
    setInfo({
      ...info,
      enable: true
    })
  }

  const handleDelete = (e, slideindex, backIndex) => {
    e.stopPropagation()
    setInfo({
      ...info,
      enable: true,
      slideIndex: slideindex,
      backIndex: backIndex
    })
  }



  const handleTts = (slideIndex, e) => {
    e.stopPropagation()
    dispatch(removeTtsFromSlides(slideIndex))
  }

  const handleAddSlide = (data) => {
    let obj = {
      videoId: data.videoId,
    }
    setLoader(true)
    dispatch(onAddSlide(obj, setLoader))
  }

  return (

    <>
      <div className="editorReelwrap">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="editorReel-scroll">

                <DragDropContext onDragEnd={onSetRecorder}>
                  <Droppable droppableId="droppable" direction="horizontal">
                    {(provided) => (
                      <ul
                        {...provided.droppableProps}
                        ref={provided.innerRef}

                      >
                        {slideData.length > 0 ?
                          slideData.map((curElem, index) => {

                            return (
                              <>
                                <Draggable key={index} draggableId={`id_${index}`} index={index}>
                                  {(provided) => (
                                    <li
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      key={index}
                                      onClick={() => onSelectTile(index)}
                                      className={`cursor-pointer editor_slide_bg ${+curElem.isSelected ? "active-tile-border" : ""}`}
                                    >
                                      <div
                                        className="editorReel-single-li"
                                        style={{
                                          border: +curElem.background[0].isSelected ? "2px solid #1478d3" : "",
                                          background: curElem.background[0].type === "color" ? curElem.background[0].thumbnail : ""
                                        }}
                                        onClick={(e) => selectInnerBackground(index, 0)}
                                      >
                                        <div className="editorReel-single">
                                          {curElem.background[0].type !== "color" ?
                                            <img src={curElem.background[0].thumbnail} alt="" /> : ""}
                                        </div>
                                        <div className='IconDel'>
                                          <FiTrash2
                                            style={
                                              {
                                                fontSize: "19px",
                                                cursor: 'pointer',
                                                color: `${+curElem.isSelected === 1 ? '#22a2ec' : '#fff'}`,
                                              }
                                            }
                                            onClick={(e) => handleDeleteAlert(e, curElem, index)}
                                          />
                                        </div>
                                      </div>
                                      <ul>
                                        {curElem.background.length > 0 ?
                                          curElem.background.map((curElem, ind) => {
                                            return (
                                              ind !== 0 ?
                                                <li className='editorReel-single-sub' key={ind} onClick={(e) => selectInnerBackground(index, ind)}>
                                                  <div className="Sub-editorReel-single-li" style={{ background: curElem.type === "color" ? curElem.thumbnail : "", border: +curElem.isSelected === 1 ? "2px solid #1478d3" : "" }}>
                                                    <div className="editorReel-single" >
                                                      {curElem.type !== "color" ?
                                                        <img src={curElem.thumbnail} alt="" />
                                                        : ""}
                                                    </div>
                                                    <div className='IconDel'>
                                                      <FiTrash2
                                                        style={
                                                          {
                                                            fontSize: "15px",
                                                            cursor: 'pointer',
                                                            color: `${+curElem.isSelected === 1 ? '#22a2ec' : '#fff'}`,
                                                          }
                                                        }
                                                        onClick={(e) => handleDelete(e, index, ind)}
                                                      />
                                                    </div>

                                                  </div>
                                                </li> : null
                                            )
                                          })

                                          : ""}

                                      </ul>

                                      <div className='micON'>
                                        <div className="d-flex">
                                          {curElem.audio.enable ?
                                            <BsFillMicFill
                                              style={{
                                                fontSize: "19px",
                                                cursor: 'pointer',
                                                color: `${+curElem.isSelected === 1 ? '#22a2ec' : '#fff'}`
                                              }}
                                              onClick={(e) => handleTts(index, e)}
                                            />
                                            :
                                            <BsFillMicMuteFill
                                              className="voiceIcn-off"
                                              style={{
                                                color: `${+curElem.isSelected === 1 ? '#22a2ec' : '#fff'}`,
                                                fontSize: "19px",
                                                cursor: 'pointer',
                                              }}
                                            />
                                          }
                                        </div>
                                      </div>

                                    </li>
                                  )}
                                </Draggable >
                                {curElem.transitionName !== "" && curElem.transitionName ?
                                  <div className="transition-wrapper mx-2" title={curElem.transitionName}>
                                    <svg width="22px" height="22px" viewBox="0 0 22 22" >
                                      <TransitionIcon style={{ color: '#fff' }} />
                                    </svg>
                                  </div> : ""}
                                <div className="addReelBt" title={"Add Slide"} >
                                  {
                                    loader ?
                                      <i className="fa fa-spinner fa-spin" style={{ color: "#25AAF1", fontSize: '25px' }} />
                                      :
                                      <span onClick={() => handleAddSlide(curElem)}>
                                        <AiFillPlusCircle />
                                      </span>
                                  }
                                </div>
                              </>
                            )
                          })
                          : ""}
                        {provided.placeholder}
                      </ul>
                    )
                    }
                  </Droppable>
                </DragDropContext>
                <SweetAlertTwo
                  show={info.enable}
                  title="Are You Sure?"
                  text="You won't be able to revert this!"
                  icon="warning"
                  showCancelButton={true}
                  confirmButtonColor='#b32f3d'
                  cancelButtonColor='#2a2c3a'
                  confirmButtonText='Yes, delete!'
                  confirmTitle='Deleting...'
                  confirmText="This won't be revert!"
                  confirmIcon='success'
                  handlePerform={onConfirm}
                  onCancel={onCancel}
                />

              </div>
            </div>
          </div>
        </div>
      </div >
    </>

  );
};




export default VideoTiles;

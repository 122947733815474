import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs"
import { BiSearch } from "react-icons/bi"
import { Link, useLocation, useNavigate } from "react-router-dom";
import { onFetchCountries, onFetchNiche } from "../../Actions/ChannelAction";
import { useDispatch } from "react-redux";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import queryString from "query-string";
import { onChangeNicheData, onFetchCamp, onGetNiches, onLodeMore, onLodeMoreNiche, onUpdateCampaign } from "../../Actions/CreateVideoAction";
import { useSelector } from "react-redux";
import { setAlert } from "../../Actions/AlertAction";

let interval = false
const Manual = () => {
   
   const dispatch = useDispatch()
   const location = useLocation()
   const navigate = useNavigate()

   const createVideo = useSelector(state => state.createVideo)
   const videoId = queryString.parse(location.search).id

   const [niches, setNiches] = useState([])
   const [savedNiches, setSavedNiches] = useState([])
   const [pageLoader, setPageLoader] = useState(true)
   const [saveLoader, setSaveLoader] = useState(false)
   const [progress, setProgress] = useState(0)
   const [loader, setLoader] = useState({
      searchLoader: false,
      niche: false
   })

   const handleSelect = (niche) => {
      dispatch(onChangeNicheData("selected", niche))
   }

   const handleChange = (e) => {
      const { name, value } = e.target
      if (value === "") {
         setNiches([])
      }
      dispatch(onChangeNicheData(name, value))
   }

   const handleSubmit = (e) => {
      e.preventDefault()
      setNiches([])
      setLoader({
         ...loader,
         searchLoader: true
      })
      let data = {
         country: createVideo.country,
         keyword: createVideo.niche.searchTerm,
         language: createVideo.language
      }
      dispatch(onGetNiches(data, niches, setNiches, loader, setLoader))
   }

   const handleLoadMore = () => {
      let arr = []
      niches.forEach((curElem) => {
         arr.push(curElem.name)
      })
      let data = {
         niches: arr,
         country: createVideo.country,
         keyword: createVideo.niche.searchTerm,
         language: createVideo.language
      }
      setLoader({
         ...loader,
         searchLoader: true
      })
      dispatch(onLodeMore("load-more-niches", data, niches, setNiches, loader, setLoader))
   }

   const handleNext = () => {
      if (createVideo.niche.selected !== "") {
         onGetProgress()
         setSaveLoader(true)
         let ep = "update-niche-campaign"
         let data = {
            ...createVideo.niche,
            cId: createVideo.id,
            results: niches
         }
         dispatch(onUpdateCampaign(ep, data, navigate, "video-idea", setSaveLoader, setProgress, interval))
      } else {
         dispatch(setAlert("Please select niche to continue!", "danger"))
      }
   }



   const fetchNiches = () => {
      setLoader({
         ...loader,
         submitLoader: true,
      })
      dispatch(onFetchNiche(setSavedNiches, loader, setLoader))
   }

   const fetchCampaign = () => {
      let data = {
         campaignId: videoId
      }
      dispatch(onFetchCamp(data, setPageLoader, navigate))
   }

   const onGetProgress = () => {
      let prog = 0
      interval = setInterval(() => {
         prog += 1;
         if (prog > 99) {
            clearInterval(interval);
            setProgress(99)
            prog = 0
         } else {
            setProgress(prog)
         }
      }, 50)
   }

   // useEffect(() => {
   //    if (createVideo.niche.searchTerm === "") {
   //       setNiches(createVideo.niche.results)
   //    }
   // }, [createVideo])


   useEffect(() => {
      fetchNiches()
   }, [])


   useState(() => {
      if (videoId) {
         fetchCampaign()
      }
   }, [videoId])

   return (
      !pageLoader ?
         <>
            <TitleBar title="Create Campaign" />
            <Navbar />

            <section className="siteWrap">
               <div className="siteWrap-in siteWrapN" style={{ paddingTop: "30px" }}>
                  <div className="container-fluid">
                     <div className="row justify-content-center">
                        <div className="col-lg-12">
                           <div className="ent-niche integr-wrap p-0">
                              <h2 className="p_space">Enter a Niche</h2>
                              <div className="progress" style={{ height: 5, background: "#424b6c" }}>
                                 <div className="progress-bar" role="progressbar" aria-valuenow={progress}
                                    aria-valuemin="0" aria-valuemax="100" style={{ width: `${progress}%`, background: "#22a0eb" }}>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="container">
                           <div className="row">
                              <div className="col-lg-12 p-0">
                                 <form className="niche-inp mt-4" onSubmit={(e) => handleSubmit(e)} style={{ width: "100%" }}>
                                    {/* <div className="niche-inp-left" style={{ width: "100%" }}> */}
                                    <div className="row align-items-center" style={{ width: "100%" }}>
                                       <div className="col-md-8 ">
                                          <input
                                             className="simple-inp"
                                             type="text"
                                             name="searchTerm"
                                             value={createVideo.niche.searchTerm}
                                             placeholder="Search a Niche"
                                             onChange={handleChange}
                                             required
                                          />
                                       </div>
                                       <div className="col-md-4 d-flex align-items-center mt-2 mb-2">
                                          <button className="demoLink_niche me-2 mt-0" type="submit" >
                                             <BiSearch style={{ marginLeft: "0" }} /> Search
                                          </button>
                                          <button onClick={handleNext} type="button" className="demoLink_niche mt-0" >

                                             Go To Ideas {saveLoader ? <i className="fa fa-spinner fa-spin mx-1" /> : <BsArrowRight />}

                                          </button>
                                       </div>

                                    </div>
                                    {/* </div> */}
                                 </form>
                              </div>
                              <div className="info-box-body alt" >
                                 <h6 style={{ color: "#fff" }} >Niche Search Results</h6>
                                 <hr style={{ color: "#fff" }} />
                                 <div className="table-responsive" style={{ height: "auto" }} >

                                    <table className="table video-table text-left">
                                       {niches.length > 0 ?
                                          niches.map((curElem, index) => {
                                             return (
                                                <tr key={index} onClick={() => handleSelect(curElem.name)} style={{ cursor: "pointer" }}>
                                                   <td>
                                                      <span>{curElem.name}</span>
                                                   </td>
                                                   <td className="text-right">
                                                      <IoCheckmarkCircleOutline
                                                         title='Add'
                                                         color={createVideo.niche ? createVideo.niche.selected === curElem.name ? "#24a8f0" : "gray" : "gray"}
                                                         size={32}
                                                         className="cursor-pointer p-0"
                                                      />
                                                   </td>
                                                </tr>
                                             )
                                          })
                                          :
                                          loader.searchLoader ? "" :
                                             <tr >
                                                <td colSpan={2} className="text-center" style={{ background: "none" }}>"You do not have search results!"</td>
                                             </tr>
                                       }
                                       {loader.searchLoader ?
                                          <tr >
                                             <td colSpan={2} className="text-center" style={{ background: "none" }}><i className="fa fa-spinner fa-spin" style={{ color: "#23a3ed", fontSize: 25 }} /></td>
                                          </tr> :
                                          ""
                                       }
                                    </table>
                                    {niches.length > 0 ?
                                       <div className="text-center">
                                          <button
                                             className="demoLink"
                                             onClick={handleLoadMore}
                                             disabled={loader.searchLoader}
                                          >
                                             Load More
                                          </button>
                                       </div> : ""}
                                 </div>
                                 <h6 style={{ color: "#fff", marginTop: 20 }} >Your Saved Niches</h6>
                                 <hr style={{ color: "#fff" }} />
                                 <div className="table-responsive" style={{ height: "auto" }}>
                                    <table className="table video-table text-left" >
                                       {savedNiches.length > 0 ?
                                          savedNiches.map((curElem, index) => {
                                             return (
                                                <tr key={index} onClick={() => handleSelect(curElem.name)} style={{ cursor: "pointer" }}>
                                                   <td>
                                                      <span>{curElem.name}</span>
                                                   </td>
                                                   <td className="text-right">
                                                      <IoCheckmarkCircleOutline
                                                         title='Add'
                                                         color={createVideo.niche ? createVideo.niche.selected === curElem.name ? "#24a8f0" : "gray" : "gray"}
                                                         size={32}

                                                         className="cursor-pointer p-0"
                                                      />
                                                   </td>
                                                </tr>
                                             )
                                          })
                                          : ""}
                                       {loader.submitLoader ?
                                          <tr >
                                             <td colSpan={2} className="text-center" style={{ background: "none" }}><i className="fa fa-spinner fa-spin" style={{ color: "#23a3ed", fontSize: 25 }} /></td>
                                          </tr> : ""
                                       }
                                    </table>
                                 </div>
                              </div>


                           </div>
                        </div>

                     </div>
                  </div>
               </div >
            </section >
            <Footer />
         </>
         :
         <div className="loader-sec">
            <div className="loader">
            </div>
         </div>
   )
}
export default Manual;
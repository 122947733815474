import React, { useEffect, useState } from "react";
import { BsPencil, BsTrash } from "react-icons/bs";
import { Link } from "react-router-dom";
import SweetAlertTwo from "../SweetAlertTwo";
import { useDispatch } from "react-redux";
import { onVideoDelete } from "../../Actions/CreateVideoAction";
import { onCheckStatus } from "../../Actions/contentAction";

const VideoRows = (props) => {
  let rendCheck = false;
  const dispatch = useDispatch();
  const [info, setInfo] = useState({
    id: -1,
    type: props.type,
    enable: false,
  });

  const [state, setState] = useState("-1");

  const deleteVideo = (id) => {
    setInfo({
      ...info,
      id: id,
      enable: true,
    });
  };

  const handleCancel = () => {
    setInfo({
      ...info,
      id: -1,
      enable: false,
    });
  };

  const performDelete = (Swal) => {
    let data = {
      id: info.id,
    };
    setInfo({
      ...info,
      id: -1,
      enable: false,
    });
    dispatch(onVideoDelete(data, props.fetchCampaign, Swal));
  };

  const checkStatus = () => {
    let data = {
      id: props.curElem.id,
    }
    dispatch(onCheckStatus(data, rendCheck, props.fetchCampaign, setState))

  }

  useEffect(() => {
    if (+state === 0 || +state === -1) {
      rendCheck = setInterval(() => {
        checkStatus()
      }, 5000)
    }
    setState(props.curElem.status)
    return () => {
      clearInterval(rendCheck)
    }
  }, [props.curElem])

  return (
    <tr>
      <td className="text-left">
        <div className="row">
          <div className="col-md-3 mb-2">
            <span className="video_page_thumb">
              <img src={props.thumb} alt="" />
            </span>
          </div>
          <div className="col-md-6">
            <span className="videoDesc mt-2">
              <h6>{props.title}</h6>
              <p className="mb-0 mt-2 p-0">{props.publishDate}</p>
            </span>
          </div>
        </div>
      </td>
      <td><span className="stat queue" style={{ background: +props.curElem.status === 0 ? "#ff9933" : +props.curElem.status === 1 ? "rgb(22, 126, 215)" : "", display: "inline-flex", width: 180, alignItems: "center" }}>

        {+state === 0 ?
          <>
            <div className="typing mr-1"  >
              <span></span>
              <span></span>
              <span></span>

            </div>
            Generating Scenes
          </>
          : +state === 1 ? "Awaiting Customization" : ""
        }
      </span></td>
      <td>
        {/* {+props.curElem.status === 0 ? "" : */}
        <Link to={`/editor?id=${props.curElem.id}`}>
          <BsPencil size={20} />
        </Link>
        {/* } */}
      </td>
      <td>
        <strong
          className="cursor-pointer"
          onClick={() => deleteVideo(props.curElem.id)}
        >
          <BsTrash size={20} />
        </strong>
      </td>

      <SweetAlertTwo
        show={info.enable}
        title="Are You Sure?"
        text="You won't be able to revert this!"
        icon="warning"
        showCancelButton={true}
        confirmButtonColor="#b32f3d"
        cancelButtonColor="#2a2c3a"
        confirmButtonText="Yes, Delete!"
        confirmTitle="Deleteing..."
        confirmText="This won't be revert!"
        confirmIcon="success"
        handlePerform={performDelete}
        onCancel={handleCancel}
      />
    </tr>
  );
};

export default VideoRows;

import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { onUpdateWatermarkInfo } from '../../../Actions/contentAction'

const Watermark = (props) => {

    const dispatch = useDispatch()
    const watermarkRef = useRef(null)

    useEffect(() => {
        if (props.watermark.src !== "") {
            setTimeout(() => {
                dispatch(onUpdateWatermarkInfo(watermarkRef.current.clientHeight, watermarkRef.current.clientWidth))
            }, 2000)
        } else {
            dispatch(onUpdateWatermarkInfo(0, 0))
        }
    }, [props.watermark.src])

    return (

        props.watermark.src !== "" ?
            <div className={`watermark-op logo-${props.watermark.pos} logo-style`}>
                <img
                    ref={watermarkRef}
                    src={props.watermark.src}
                    alt="watermark"
                />
            </div>
            : ''
    )
}

export default Watermark
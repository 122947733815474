import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import queryString from 'query-string';
import DesignTemplates from "./DesignTemplates";
import { useDispatch } from "react-redux";
import { onCreateImage, onFetchTemplates } from "../../Actions/DesignActions";
import { setAlert } from "../../Actions/AlertAction";
import { BsArrowLeftShort } from "react-icons/bs";

const CreatePage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const type = queryString.parse(location.search).type

    const [template, setTemplate] = useState([])
    const [loader, setLoader] = useState({
        fetchLoader: false,
        submitLoader: false
    })
    const [state, setState] = useState({
        name: "",
        templateId: -1,
        type: type
    })

    const handleChange = (e) => {
        setState({
            ...state,
            name: e.target.value
        })
    }

    const handleSubmit = (id) => {
        if (state.name !== "") {
            setLoader({
                ...loader,
                submitLoader: true
            })
            let data = { ...state }
            data = {
                ...data,
                templateId: id
            }
            dispatch(onCreateImage(data, navigate, loader, setLoader))
        } else {
            dispatch(setAlert(`Please enter ${type} name to create it!`, "danger"))
        }
    }

    const fetchTemplates = () => {
        let data = {
            type: type
        }
        setLoader({
            ...loader,
            fetchLoader: true
        })
        dispatch(onFetchTemplates(data, setTemplate, loader, setLoader))
    }

    useEffect(() => {
        fetchTemplates()
    }, [])

    return (
        <>
            <TitleBar title="Create" />
            <Navbar />

            <section className="siteWrap">
                <div className="container">

                    <div className="business-wrap pt-3">

                        <div className="business-top text-light p-0" >
                            <div className="d-flex bd-highlight">
                                <div className=" flex-grow-1 bd-highlight text-capitalize pt-2" >
                                    <Link to={'/design'} className=" text-light" style={{ fontSize: 25, fontWeight: 700 }}> <BsArrowLeftShort className="mr-0" size={40} style={{ paddingBottom: 4 }} />
                                        Create {type}
                                    </Link>
                                </div>

                            </div>
                        </div>
                        <hr style={{ color: "#fff", margin: 3 }} />
                        <div className="business-list">
                            <div className="inpLabelWrap">
                                <span className="labelTxt"><span style={{ textTransform: "capitalize" }}>{type}</span> name</span>
                                <div className="inpLabel">
                                    <label><span style={{ textTransform: "capitalize" }}>{type}</span> name</label>
                                    <input
                                        className="inpLabel-inp"
                                        type="text"
                                        placeholder="Enter Name"
                                        name="name"
                                        value={state.name}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className="list-template">
                                <div className="row">
                                    {template.length > 0 ?
                                        template.map((curElem, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <DesignTemplates
                                                        curElem={curElem}
                                                        state={state}
                                                        handleSubmit={handleSubmit}
                                                        loader={loader.submitLoader}
                                                    />
                                                </React.Fragment>
                                            )
                                        })
                                        :
                                        loader.fetchLoader ?
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 40, fontSize: 30, color: "#23a4ee" }}>
                                                <i className="fa fa-spinner fa-spin" />
                                            </div> : ""
                                    }
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section>

            <Footer />
        </>
    )
}

export default CreatePage;
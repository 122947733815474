import React, { useState } from 'react'
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa'

const DemoAndIdeasHeader = ({ ideasData, setIdeasData }) => {

    const [state, setState] = useState({
        competition: "",
        level: ""
    })

    const handleSort = (type) => {
        const data = [...ideasData]
        if (type === "competition") {
            if (state.competition) {
                data.sort((a, b) => {
                    if (Number(a.competition.toLowerCase()) < Number(b.competition.toLowerCase())) {
                        return 1;
                    } else {
                        return -1;
                    }
                });
                setState({
                    ...state,
                    competition: false,
                });
            } else {
                data.sort((a, b) => {
                    if (Number(a.competition.toLowerCase()) > Number(b.competition.toLowerCase())) {
                        return 1;
                    } else {
                        return -1;
                    }
                });
                setState({
                    ...state,
                    competition: true,
                });
            }
        }
        if (type === "level") {
            if (state.level) {
                data.sort((a, b) => {
                    if (a.competition_volume.toLowerCase() < b.competition_volume.toLowerCase()) {
                        return 1;
                    } else {
                        return -1;
                    }
                });
                setState({
                    ...state,
                    level: false,
                });
            } else {
                data.sort((a, b) => {
                    if (a.competition_volume.toLowerCase() > b.competition_volume.toLowerCase()) {
                        return 1;
                    } else {
                        return -1;
                    }
                });
                setState({
                    ...state,
                    level: true,
                });
            }
        }
        setIdeasData(data)
    }

    return (
        <thead style={{ position: "sticky", top: "-10px", zIndex: "1" }}>
            <tr>
                <th>Ideas</th>
                <th onClick={() => handleSort("competition")} className="text-center cursor-pointer">Competition <span>{state.competition === "" ? <FaSort /> : state.competition ? <FaSortDown /> : <FaSortUp />}</span></th>
                <th onClick={() => handleSort("level")} className="text-center cursor-pointer">Competition Level <span>{state.level === "" ? <FaSort /> : state.level ? <FaSortDown /> : <FaSortUp />}</span></th>
                <th className="text-center"></th>
            </tr>
        </thead >
    )
}

export default DemoAndIdeasHeader

import React, { useEffect, useRef, useState } from 'react'
import modalCloseIcon from '../../../images/modal-close.png';
import { Modal } from "react-bootstrap";
import { Player } from "@remotion/player";
import { useSelector } from 'react-redux';
import RemotionEditor from './RemotionEditor';

const Remotion = ({ show, handleClose }) => {

    const playerRef = useRef(null);
    const campaign = useSelector(state => state.content.campaign)
    const [duration, setDuration] = useState(0)

    useEffect(() => {
        let introDuration = (campaign?.intro?.enable === true && campaign?.intro?.src !== "") ? +campaign.intro.duration * 30 : 0
        let outroDuration = (campaign?.outro?.enable === true && campaign?.outro?.src !== "") ? +campaign.outro.duration * 30 : 0
        let dur = 0 + introDuration + outroDuration
        let audioDelay = 30
        if (campaign.slides?.length > 0) {
            campaign.slides.forEach((element, index) => {
                dur += +element.duration * 30
                if (index === 0) {
                    dur += (campaign?.intro?.enable === true && campaign?.intro?.src !== "") ? audioDelay : 0
                } else if (index === campaign.slides?.length - 1) {
                    // 1 sec extra for proper voice over
                    dur += (campaign?.outro?.enable === true && campaign?.outro?.src !== "") ? 0 : audioDelay
                } else {
                    dur += audioDelay
                }
            });
            setDuration(dur)
        }
    }, [campaign])

    return (
        // <Modal className={`theme-modal ${campaign.dimension === "1920x1080" ? "remotion-preview_16X9 xl" : campaign.dimension === "1080x1920" ? "remotion-preview_9X16" : "remotion-preview_1X1"}`} show={show} onHide={handleClose} centered scrollable>
        <Modal className="remotion-preview VideoModal small" show={show} onHide={handleClose} centered>
            <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} alt="" /></div>
            < Modal.Body >
                <Player
                    ref={playerRef}
                    component={RemotionEditor}
                    inputProps={{
                        slideData: campaign,
                        multiplyNum: 1,
                        playerRef: playerRef
                    }}
                    durationInFrames={duration}
                    // compositionWidth={campaign.dimension === "1920x1080" ? 640 : campaign.dimension === "1080x1920" ? 360 : 460}
                    // compositionHeight={campaign.dimension === "1920x1080" ? 360 : campaign.dimension === "1080x1920" ? 640 : 460}
                    compositionWidth={640}
                    compositionHeight={360}
                    fps={30}
                    controls={true}
                    style={{ width: "100%", border: "1px solid #201f1f" }}
                />
            </Modal.Body >
        </Modal >
    )
}

export default Remotion

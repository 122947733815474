import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import modalCloseIcon from "../../images/modal-close.png";
import { useDispatch } from 'react-redux';
import { onAddNiche } from '../../Actions/ChannelAction';

const AddNiches = (props) => {
    const dispatch = useDispatch()
    const [textNiche, setTextNiche] = useState("")
    const [loader, setLoader] = useState(false)
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        let data = {
            name: textNiche
        }
        dispatch(onAddNiche(data, props.fetchNiches, props.handleClose, setLoader))
    }


    return (
        <Modal className="VideoModal dark" show={props.show} onHide={props.handleClose} centered>
            <Modal.Body>
                <div onClick={props.handleClose} className="vidClose">
                    <img alt="" src={modalCloseIcon} />
                </div>
                <h4 className='text-center mb-2'>Add Your Niche</h4>
                <form onSubmit={handleSubmit}>
                    <div className="inp-group alt mt-0">
                        <label htmlFor="">Enter Niche</label>
                        <input
                            className="simple-inp"
                            type="text"
                            placeholder="Enter your niche"
                            value={textNiche}
                            onChange={(e) => setTextNiche(e.target.value)}
                            required
                        />
                    </div>

                    <div className="text-right foot-button">
                        <button className="demoLink ml-2" type='submit'>{loader ? <>Adding <i className="fa fa-spinner fa-spin mx-1" /></> : "Add"}</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal >
    )
}

export default AddNiches
import React from 'react'
import { fabric } from 'fabric';
import { useRef } from 'react';
import { useEffect } from 'react';
import { onGenerateThumbnailUpdate } from '../../Actions/CreateVideoAction';
import { useDispatch } from 'react-redux';

const FabricToGenerate = ({ canvases }) => {
  const dispatch = useDispatch()
  const canvasRefs = useRef([]);

  useEffect(() => {
    const processCanvasesSequentially = async () => {
      for (const [index, canvasData] of canvases.entries()) {
        if (canvasData.thumbnail === "") {
          const canvas = new fabric.Canvas(canvasRefs.current[index]);
          let jsonObj = canvasData.data;
          jsonObj = JSON.parse(jsonObj.replaceAll(`"crossOrigin":null`, `"crossOrigin" : "*"`));
          canvas.setWidth(640);
          canvas.setHeight(360);
          let img
          await new Promise((resolve) => {
            canvas.loadFromJSON(jsonObj, async function () {
              canvas.backgroundColor = 'white';
              let height = 360;
              let width = 640;
              let scaleRatio = Math.min(canvas.width / width, canvas.height / height);
              canvas.backgroundImage.scaleX = scaleRatio;
              canvas.backgroundImage.scaleY = scaleRatio;
              canvas.setDimensions({ width: width, height: height });

              img = await canvas.toDataURL('image/png');
              let data = {
                id: canvasData.id,
                type: "thumbnailUpdate",
                data: img
              };

              dispatch(onGenerateThumbnailUpdate(data, index, canvases.length));
              canvas.renderAll();
              resolve();
            });
          });
        }
      }
    };

    processCanvasesSequentially();
  }, [canvases]);


  return (
    <div className='mt-2' style={{ visibility: 'hidden', height: 0, position: 'fixed' }}>
      {canvases.map((canvasData, index) => (
        <canvas
          key={index}
          ref={(ref) => (
            canvasRefs.current[index] = ref
          )}
        />
      ))}
    </div>
  );
}

export default FabricToGenerate
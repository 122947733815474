import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addMediaLayer, getUploadData, onDeleteMedia, onUpload, updateBackground, updateMediaLayer } from '../../../Actions/contentAction';
import HoverVideoPlayer from "react-hover-video-player";
import { FaTrashAlt } from 'react-icons/fa';
import Swal from 'sweetalert2';
import SweetAlertTwo from '../../SweetAlertTwo';


const UploadMedia = ({ selectedSlideIndex, selectedLayerIndex, selectedSlide, type, selectedLayer }) => {
    const dispatch = useDispatch()
    const [percent, setPercent] = useState(0)
    const [loader, setLoader] = useState(false)
    const [images, setImages] = useState([])
    const [videos, setVideos] = useState([])
    const [button, setButton] = useState("Delete")
    const [showDelete, setShowDelete] = useState(false)
    const [meId, setMeId] = useState({
        id: -1,
        type: ""
    })

    const [info, setInfo] = useState({
        id: -1,
        enable: false,
        type: ""
    })

    let allowedExt = ['image/png', 'image/jpg', 'image/jpeg', 'video/mp4'];

    const onInputFile = (e) => {
        let myType = "video"
        let allowedSize = 20000000;
        const formData = new FormData()

        if (allowedExt.includes(e.target.files[0].type)) {
            if (e.target.files[0].type !== "video/mp4") {
                allowedSize = 5000000
                myType = "images"
            }
            if (e.target.files[0].size < allowedSize) {
                formData.append('upload_type', myType)
                formData.append('file', e.target.files[0])
                if (myType === "images") {
                    dispatch(onUpload(formData, fetchImgMedia, setLoader, setPercent, myType, setImages))
                } else {
                    dispatch(onUpload(formData, fetchVidMedia, setLoader, setPercent, myType, setVideos))
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Max allowed size for image 5MB for video 20MB!',
                    confirmButtonColor: "#d03e3e"
                })
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You have Selected Invalid File Type!',
                confirmButtonColor: "#d03e3e"
            })
        }
    }

    const selectImage = (image) => {
        let data = {
            url: image.url,
            thumbnail: image.thumbnail
        }
        if (type === "media") {
            if (selectedLayerIndex !== -1) {
                const type = selectedSlide.layers[selectedLayerIndex].type
                if (type === "image" || type === "video") {
                    dispatch(updateMediaLayer(data, selectedSlideIndex, selectedLayerIndex, "image"))
                }
                else {
                    dispatch(addMediaLayer(data, selectedSlideIndex, "image"))
                }
            } else {
                dispatch(addMediaLayer(data, selectedSlideIndex, "image"))
            }
        } else {
            dispatch(updateBackground(image, selectedSlideIndex, "image"))
        }
    }

    const selectVideo = async (video) => {
        let data = {
            url: video.url,
            thumbnail: video.thumbnail ? video.thumbnail : video.poster
        }
        if (type === "media") {
            if (selectedLayerIndex !== -1) {
                const type = selectedSlide.layers[selectedLayerIndex].type
                if (type === "image" || type === "video" || type === "gif") {
                    dispatch(updateMediaLayer(data, selectedSlideIndex, selectedLayerIndex, "video"))
                }
                else {
                    dispatch(addMediaLayer(data, selectedSlideIndex, "video"))
                }

            } else {
                dispatch(addMediaLayer(data, selectedSlideIndex, "video"))

            }
        } else {
            return new Promise((resolve, reject) => {
                let au = document.createElement('video');
                au.src = video.url
                au.addEventListener('loadedmetadata', function () {
                    video.bgDuration = Math.round(au.duration)
                    dispatch(updateBackground(video, selectedSlideIndex, "video"))
                    resolve(au.duration)
                }, false);
            })
        }
    }


    const handleDelete = (e, id, type) => {
        e.stopPropagation()
        setInfo({
            ...info,
            id: id,
            enable: true,
            type: type
        })
    }

    const handleCancel = () => {
        setInfo({
            ...info,
            id: -1,
            enable: false,
            type: ""
        })

    }

    const onConfirm = (Swal) => {
        const formData = new FormData()
        formData.append("id", info.id)

        if (info.type === "images") {
            dispatch(onDeleteMedia(formData, fetchImgMedia, Swal))
        }
        else {
            dispatch(onDeleteMedia(formData, fetchVidMedia, Swal))
        }
        setInfo({
            ...info,
            id: -1,
            enable: false,
            type: ""
        })
    }


    const fetchImgMedia = () => {
        let obj = {
            type: "images"
        }
        dispatch(getUploadData(obj, setImages))
    }

    const fetchVidMedia = () => {
        let obj = {
            type: "video"
        }
        dispatch(getUploadData(obj, setVideos))
    }

    useEffect(() => {
        fetchImgMedia()
        fetchVidMedia()
    }, [])

    return (
        <div className='row'>
            <div className='col-md-12 mt-4'>
                <div className="tabSearch">
                    <button className="demoLink alt btn-block mt-0 text-capitalize"><i className="fa fa-cloud-upload" aria-hidden="true"></i>Upload Media</button>
                    <input
                        className="absInp"
                        type="file"
                        onChange={(e) => onInputFile(e)}
                    />
                </div>
                {
                    loader ?
                        <div className="progress mt-3">
                            <div className="progress-bar" role="progressbar" style={{ width: `${percent}%`, background: "linear-gradient(to left, #C16BED 0%, #3DAEC7 100%)" }} aria-valuenow={`${percent}`}
                                aria-valuemin="0" aria-valuemax="100">{percent}%

                            </div>
                        </div>
                        : ''
                }
            </div>
            <div className="innertab-scroll" id="scrollableMediaImage" >
                <div className="row">
                    <div className="mediaList ">
                        <ul>
                            {images.length > 0 ?
                                images.map((curElem, index) => {
                                    return (
                                        <li className='myImage-section cursor-pointer'
                                            key={index}
                                        >
                                            <div className="mediaList-single myImage-respo m-1">
                                                <img className="mediaImg"
                                                    src={curElem.url}
                                                    alt={curElem.name}
                                                    onClick={() => selectImage(curElem, index)}
                                                />
                                                <FaTrashAlt
                                                    className='delete-icon-media'
                                                    onClick={(e) => handleDelete(e, curElem.id, "images")}
                                                />
                                                {type === "media" ?
                                                    selectedLayer ?
                                                        selectedLayer.type === "image" ?
                                                            curElem.url === selectedLayer.src ?
                                                                <div className="slide-active">
                                                                    <i className="fa fa-check" aria-hidden="true"></i>
                                                                </div>
                                                                : ''
                                                            : ""
                                                        : ""
                                                    :
                                                    selectedSlide ?
                                                        selectedSlide.background.type === "image" ?
                                                            curElem.url === selectedSlide.background.src ?
                                                                <div className="slide-active">
                                                                    <i className="fa fa-check" aria-hidden="true"></i>
                                                                </div>
                                                                : "" : ""
                                                        : ""
                                                }

                                            </div>
                                            {/* <div className="crop-icon"
                                                        onClick={() => selectCrop(curElem.url)}
                                                    >
                                                        <i className="fa fa-crop"></i>
                                                    </div> */}
                                        </li>
                                    )
                                })
                                : ''
                            }
                            {videos.length > 0 ?
                                videos.map((curElem, index) => {
                                    return (
                                        <>
                                            {curElem ?
                                                <li className='cursor-pointer'
                                                    key={index} onClick={() => selectVideo(curElem, index)}
                                                >

                                                    <HoverVideoPlayer
                                                        className="hover-video-player"
                                                        style={{ border: '1px solid gray', height: '110px', overflow: 'hidden' }}
                                                        videoSrc={curElem.url}
                                                        pausedOverlay={
                                                            <img
                                                                src={curElem.poster}
                                                                alt={curElem.name + `-${index}`}
                                                                style={{ objectFit: "cover", width: "100%", height: "100%" }} />
                                                        }
                                                        loadingOverlay={
                                                            <div><i className="fa fa-spinner fa-spin hover-loader-center" /></div>
                                                        }
                                                    />
                                                    <FaTrashAlt
                                                        className='delete-icon-media'
                                                        style={{ zIndex: 1, top: "8px", right: "6px" }}
                                                        onClick={(e) => handleDelete(e, curElem.id, "video")}
                                                    />
                                                    {type === "media" ?
                                                        selectedLayer ?
                                                            selectedLayer.type === "video" ?
                                                                curElem.url === selectedLayer.src ?
                                                                    <div className="slide-active">
                                                                        <i className="fa fa-check" aria-hidden="true"></i>
                                                                    </div>
                                                                    : ''
                                                                : ""
                                                            : ""
                                                        : selectedSlide ?
                                                            selectedSlide.background.type === "video" ?
                                                                curElem.url === selectedSlide.background.src ?
                                                                    <div className="slide-active">
                                                                        <i className="fa fa-check" aria-hidden="true"></i>
                                                                    </div>
                                                                    : "" : ""
                                                            : ""
                                                    }
                                                    {/* <div className="crop-icon"
                                                            onClick={(e) => selectCrop(e, curElem.url)}
                                                        >
                                                            <i className="fa fa-crop"></i>
                                                        </div> */}
                                                </li>
                                                : ''}
                                        </>

                                    )
                                }) : ''}

                        </ul>
                    </div>
                </div>
            </div>

            <SweetAlertTwo
                show={info.enable}
                title="Are You Sure?"
                text="You won't be able to revert this!"
                icon="warning"
                showCancelButton={true}
                confirmButtonColor='#b32f3d'
                cancelButtonColor='#2a2c3a'
                confirmButtonText='Yes, delete it!'
                confirmTitle='Deleting...'
                confirmText="This won't be revert!"
                confirmIcon='success'
                handlePerform={onConfirm}
                onCancel={handleCancel}
            />

        </div>
    )
}

export default UploadMedia;
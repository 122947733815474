import WebFont from "webfontloader"
import { commonAxios } from "../Global/CommonAxios"
import { setAlert } from "./AlertAction"

export const onFetchTemplates = (data, setTemplate, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-all-templates", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setTemplate(res.data.reverse())
            } else {
                setTemplate([])
            }
            setLoader({
                ...loader,
                fetchLoader: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                fetchLoader: false
            })
        })
}

export const onFetchTableData = (setTemplate, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-user-designs", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setTemplate(res.data)
            } else {
                setTemplate([])
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
}

export const onCreateImage = (state, navigate, loader, setLoader) => (dispatch, getState) => {
    commonAxios("create-design", state, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`/image-editor?id=${res.data}`)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submitLoader: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                submitLoader: false
            })
        })
}

export const onFetchDesign = (data, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-design", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "ADD_DESIGN_DATA", payload: res.data })
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
}

export const onDeleteDesign = (data, fetchDesign, Swal) => (dispatch, getState) => {
    commonAxios("delete-design", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchDesign()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            Swal.close()
        }).catch((err) => {
            console.log(err)
            Swal.close()
        })
}


export const onSaveImage = (newData, navigate, setCanvasLoader) => (dispatch, getState) => {
    commonAxios("update-design", newData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate('/design')
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setCanvasLoader(false)
        }).catch((err) => {
            console.log(err)
            setCanvasLoader(false)
        })
}



export const onfetchAiImage = (data, image, setImage, setLoader) => (dispatch, getState) => {
    commonAxios("generate-image-ai", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setImage({ ...image, imageData: res.data })
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader(false)
        })
}


export const onFetchDesignFonts = () => (dispatch, getState) => {
    commonAxios("fetch-design-font", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = res.data.map((val) => {
                    return (
                        val.fontName
                    )
                })
                WebFont.load({
                    custom: {
                        families: arr
                    },
                });
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onUploadImagesFile = (data, fetchData, loader, setLoader) => (dispatch, getState) => {
    commonAxios("upload-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchData()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                upload: false,
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                upload: false,
            })
            console.log(err)
        })
}

export const onFetchUploads = (data, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-user-uploaded-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            } else {
                setState([])
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                fetch: false
            })
        })
}

export const onDeleteMediaUpload = (formData, fetchData, Swal) => (dispatch, getState) => {
    commonAxios("delete-user-uploaded-file", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchData()
                dispatch(setAlert(res.msg, 'success'));
            }
            else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            Swal.close()
        }).catch((error) => {
            Swal.close()
            dispatch(setAlert(error.msg, 'danger'));
            console.log(error)
        })
}

// No Axios Here

export const onUnmountDesign = () => (dispatch) => {
    dispatch({ type: "ON_UNMOUNT_DESIGN" })
}
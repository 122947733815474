import React, { useEffect, useState } from 'react'
import TopNichesVideos from "./TopNichesVideos";
import DemoAndIdeas from "./DemoAndIdeas";
import { useDispatch } from 'react-redux'
import { onAddData, onChooseNiches, onFetchUserKeyword, onGetMediaInfo, onSearchKeyData } from "../../Actions/ChannelAction"
import { AiFillHeart, AiOutlineClose } from 'react-icons/ai';
import DashboardSectionHeader from './DashboardSectionHeader';
import { useSelector } from 'react-redux';

const DashboardSection2 = ({ ideas, fetchIdeas, topVideos, setTopVideos, videoLoader, keyword, fetchKeyword, setKeyword }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [search, setSearch] = useState('')
    const [loader, setLoader] = useState({
        add: false,
        search: false,
        selectedIndex: -1
    })

    const [keywordData, setKeywordData] = useState([])
    const [media, setMedia] = useState({
        image: "",
        video: ""
    })

    const onDislike = (val, isSearch) => {
        if (+auth.user.isClient !== 1) {
            let data = {
                id: val
            }
            const newArray = [...keyword];
            const indexToModify = newArray.findIndex(item => +item.id === +val);
            if (indexToModify !== -1) {
                newArray[indexToModify].is_like = "0";

                let data = newArray.sort((a, b) => {
                    if (a.is_like === "1" && b.is_like === "0") {
                        return -1;
                    } else if (a.is_like === "0" && b.is_like === "1") {
                        return 1;
                    } else {
                        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                    }
                })
                setKeywordData(data);
            }
            dispatch(onChooseNiches("remove-keyword", data, false))
        }
    }

    const handleAdd = (val, index) => {
        if (+auth.user.isClient !== 1) {
            if (val.isSearchedData) {
                let data = {
                    name: val.name,
                    competition: val.competition,
                    volume: val.volume,
                    overall: val.overall
                }
                const newArray = [...keyword];
                const indexToModify = newArray.findIndex(item => item.name === val.name && item.competition === val.competition && item.volume === val.volume && item.overall === val.overall);
                if (indexToModify !== -1) {
                    newArray[indexToModify].is_like = "1";
                    let data = newArray.sort((a, b) => {
                        if (a.is_like === "1" && b.is_like === "0") {
                            return -1;
                        } else if (a.is_like === "0" && b.is_like === "1") {
                            return 1;
                        } else {
                            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                        }
                    })
                    setKeywordData(data);
                }
                //need to match something else instde of index aad--user-keyword  
                dispatch(onAddData("add-user-keyword", data, false, false, loader, setLoader, indexToModify, keyword, setKeyword))
            } else {
                let data = {
                    id: val.id
                }
                const newArray = [...keyword];
                const indexToModify = newArray.findIndex(item => +item.id === +val.id);
                if (indexToModify !== -1) {
                    newArray[indexToModify].is_like = "1";
                    let data = newArray.sort((a, b) => {
                        if (a.is_like === "1" && b.is_like === "0") {
                            return -1;
                        } else if (a.is_like === "0" && b.is_like === "1") {
                            return 1;
                        } else {
                            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                        }
                    })
                    setKeywordData(data);
                }
                dispatch(onChooseNiches("like-keywords", data, false))
            }
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let data = {
            keyword: search
        }
        setLoader({
            ...loader,
            search: true
        })
        dispatch(onSearchKeyData(data, setKeyword, loader, setLoader))
    }

    const handleChange = (e) => {
        if (e.target.value === "") {
            fetchKeyword()
        }
        setSearch(e.target.value)
    }


    useEffect(() => {
        fetchKeyword()
        dispatch(onGetMediaInfo(media, setMedia))
    }, [])

    useEffect(() => {
        setKeywordData(keyword)
    }, [keyword])

    return (
        <div className="channel-wrap">
            <TopNichesVideos
                imageUrl={media.image}
                topVideos={topVideos}
                setTopVideos={setTopVideos}
                videoLoader={videoLoader}
            />
            <DemoAndIdeas
                videoUrl={media.video}
                ideas={ideas}
                fetchIdeas={fetchIdeas}
            />


            <div className="channel-stat pt-0">
                <div className="container-fluid">
                    <div className="info-box">
                        <div className="info-box-head">
                            <h2><img src={require('../../images/trophy.png')} /> Top Keyword Research</h2>
                        </div>
                        <div className="info-box-body">
                            {+auth.user.isClient !== 1 ?
                                <form className="key-search" onSubmit={handleSubmit}>
                                    <input
                                        type="text"
                                        placeholder="Search Keywords"
                                        value={search}
                                        onChange={(e) => handleChange(e)}
                                        required
                                    />
                                    <button className="demoLink" type='submit'>{loader.search ? <>Searching <i className="fa fa-spinner fa-spin mx-1" /></> : "Search"}</button>
                                </form> : ""}
                            <div className="mt-3" style={{ position: "relative" }} >
                                <div className="table-responsive">
                                    <table className="table video-table" style={{ marginTop: "-9px" }}>
                                        <DashboardSectionHeader keyword={keyword} setKeyword={setKeyword} />
                                        {keywordData.length > 0 ?
                                            keywordData.map((curElem, index) => {
                                                return (
                                                    < tr key={index}>
                                                        <td className="text-left">
                                                            {curElem.name}
                                                        </td>
                                                        <td>{curElem.competition}</td>
                                                        <td>{curElem.volume}</td>
                                                        <td><span className="keyColor green">{curElem.overall}</span></td>
                                                        <td>
                                                            {+curElem.is_like ?
                                                                <span
                                                                    className="delIcon cursor-pointer"
                                                                    onClick={() => onDislike(curElem.id, curElem.isSearchedData, index)}
                                                                    title='Remove from repository'
                                                                >
                                                                    <AiFillHeart />
                                                                </span>
                                                                :
                                                                <button
                                                                    className='wishIcon'
                                                                    title={"Add to repository"}
                                                                    onClick={() => handleAdd(curElem, index)}
                                                                >
                                                                    <AiFillHeart />
                                                                </button>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            }) :
                                            <tr><td colSpan="5">No data found</td></tr>
                                        }
                                    </table>
                                </div>
                                <div
                                    className="NO_daily_idea_wrap"
                                    style={{ display: keyword.length > 0 ? "" : "block" }}
                                >
                                    <p style={{ fontSize: 17 }}>No keyword generated for you yet. Please like some niches first.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default DashboardSection2;
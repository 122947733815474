import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { useDispatch } from "react-redux";
import { onDeleteSchadule, onFetchSchedule } from "../../Actions/ChannelAction";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";


const Schedules = () => {
   const dispatch = useDispatch()
   const auth = useSelector(state => state.auth)
   const [events, setEvents] = useState([]);
   const [gridType, setGridType] = useState("month")
   const [data, setData] = useState({
      event: false,
      activeId: false
   })
   const handleDayRender = (obj) => {
      return (
         <>
            {obj.isPast === false ? <>
               <i className="fa-custom fas fa-plus-circle"> </i>
               {obj.dayNumberText}</> : obj.dayNumberText
            }
         </>
      )
   }

   const handlePopup = (e) => {
      let length = 3
      if (gridType === "week" || gridType === "day") {
         length = 1
      }
      if (e.el.children.length === length) {
         let text = e.event.extendedProps.fullTitle
         let p = document.createElement('p')
         p.style.background = "#000"
         p.style.opacity = "1"
         p.style.padding = "7px 10px"
         p.style.color = "#fff"
         p.style.borderRadius = "7px"
         p.style.fontSize = "12px"
         p.style.position = "absolute"
         p.style.bottom = "20px"
         p.style.left = gridType === "day" ? "30%" : "-20%"
         p.style.zIndex = 999999
         p.innerText = text
         e.el.appendChild(p)
      }
   }

   const handlePopupLeave = (e) => {
      e.el.children[e.el.children.length - 1].remove()
   }

   const handleEvent = (e) => {
      if (+auth.user.isClient !== 1) {
         let length = 4
         if (gridType === "week" || gridType === "day") {
            length = 2
         }
         if (e.el.children.length === length) {
            setData({
               ...data,
               event: e,
               activeId: `del-button-${e.event.id}`
            })
            e.el.children[e.el.children.length - 1].remove()
            let popDel = document.createElement('div')
            popDel.setAttribute("class", "schadule-del-icon")
            popDel.innerHTML = `<span id="del-button-${e.event.id}" title="Delete Schadule" style="cursor:pointer"><i class="fa-solid fa-trash"></i><span>`
            if (length === 2) {
               popDel.style.top = "-50%"
            }
            e.el.appendChild(popDel)
         }
      }
   }
   const handleDeleteSchadule = () => {
      let val = data.event.event.extendedProps.publishing
      val = {
         ...val,
         youtube: {
            ...val.youtube,
            enable: false
         }
      }
      let obj = {
         id: data.event.event.id,
         object: val
      }
      const eventIndex = events.findIndex(({ id }) => +id === +data.event.event.id)
      if (eventIndex !== -1) {
         let arr = [...events]
         arr.splice(eventIndex, 1)
         setEvents(arr)
         dispatch(onDeleteSchadule(obj))
      }
   }

   const handleType = (e) => {
      if ("dayGridMonth" === e.view.type) {
         setGridType("month")
      } else if ("timeGridWeek" === e.view.type) {
         setGridType("week")
      }
      else {
         setGridType("day")
      }
   }

   useEffect(() => {
      if (data.activeId) {
         let spanTag = document.getElementById(data.activeId)
         spanTag.addEventListener("click", () => {
            handleDeleteSchadule()
            data.event.el.children[data.event.el.children.length - 1].remove()
         })

         return () => {
            spanTag.removeEventListener("click", () => {
               console.log("Removed Event")
               setData({
                  ...data,
                  activeId: false,
                  event: false
               })
            })
         }
      }
   }, [data])

   const fetchSchedule = () => {
      dispatch(onFetchSchedule(setEvents))
   }

   useEffect(() => {
      fetchSchedule()
   }, [])

   return (
      <>
         <TitleBar title="Schedules" />
         <Navbar />
         <section className="siteWrap">
            <div className="siteWrap-in siteWrapN" style={{ paddingTop: "32px" }}>
               <div className="container-fluid">
                  <div className="row justify-content-center">
                     <div className="col-xl-12">
                        <div className="business-top">
                           <h2 className=" pb-1 p_space">Schedules</h2>
                           <div className="d-flex justify-content-end mb-1">
                              <Link className='demoLink ml-2' to="/schedule-content"><span>View Schedules</span></Link>
                           </div>
                        </div>
                        <div className="Calendar-outer p_space px-5">
                           <FullCalendar
                              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                              initialView="dayGridMonth"
                              selectable={false}
                              eventTimeFormat={{
                                 hour: '2-digit',
                                 minute: '2-digit',
                                 meridiem: "2-digit",
                                 meridiem: "lowercase",
                                 hour12: true
                              }}
                              eventClick={handleEvent}
                              eventMouseEnter={handlePopup}
                              eventMouseLeave={handlePopupLeave}
                              headerToolbar={{
                                 left: "prev,next today",
                                 center: "title",
                                 right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                              }}
                              dayCellContent={handleDayRender}
                              events={events}
                              viewClassNames={(e) => handleType(e)}
                           />
                        </div>


                     </div>
                  </div>

               </div>
            </div>

         </section>
         <Footer />

      </>
   )
}
export default Schedules;
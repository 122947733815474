import React, { useState, useCallback } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import Cropper from "react-easy-crop";
import getCroppedImg from '../media/CropImage';
import closeModalx from "../../../images/modal-close.png"
import { onGenerateFile, onSearchMedia, updateBackground, addMediaLayer, updateMediaLayer } from '../../../Actions/contentAction';
import { useSelector } from 'react-redux';

const BgImageMedia = ({ selectedSlideIndex, selectedLayerIndex, selectedSlide, selectedLayer, type }) => {

    const dispatch = useDispatch();
    const campaign = useSelector(state => state.content.campaign)

    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);
    const [checkImage, setCheckImage] = useState(false);
    const [cropModel, setCropModel] = useState(false);
    const [currentImage, setCurrentImage] = useState(false);
    const [outputImage, setOutputImage] = useState(false);
    const [cropLoader, setCropLoader] = useState(false);

    /*Crop image or video*/
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    const [search, setSearch] = useState({
        keyword: '',
        search_type: 'image',
        page: 1
    });

    const [imageData, setImageData] = useState([]);

    const onInputChange = (e) => {
        setSearch({ ...search, keyword: e.target.value });
        setImageData([]);
        setError(false);
        setCheckImage(false)
    }



    const searchImage = (page = 1) => {
        setLoader(true);
        let data = {
            keyword: search.keyword,
            search_type: search.search_type,
            page_number: page
        }
        dispatch(onSearchMedia(data, imageData, setImageData, setLoader, search, setSearch, page, setError))
    }

    const handleClick = () => {
        setError(false);
        searchImage();
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setError(false);
        searchImage();
    }

    const selectImage = (image, index) => {
        let data = {
            url: image.url,
            thumbnail: image.thumbnail
        }
        if (type === "media") {
            if (selectedLayerIndex !== -1) {
                const type = selectedSlide.layers[selectedLayerIndex].type
                if (type === "image" || type === "video") {
                    dispatch(updateMediaLayer(data, selectedSlideIndex, selectedLayerIndex, "image"))
                }
                else {
                    dispatch(addMediaLayer(data, selectedSlideIndex, "image", campaign.dimension))
                }
                setCheckImage(index);
            } else {
                dispatch(addMediaLayer(data, selectedSlideIndex, "image", campaign.dimension))
                setCheckImage(index)
            }
        } else {
            dispatch(updateBackground(image, selectedSlideIndex, "image"))
            setCheckImage(index);
        }
    }

    const selectCrop = (url) => {
        setCropModel(true);
        setCurrentImage(url);
        setOutputImage(false)
    }

    const selectCropImage = (image) => {
        let jsonData = {
            url: image,
            thumbnail: image,
        }

        if (type === "media") {
            if (selectedLayerIndex !== -1) {
                const type = selectedSlide.layers[selectedLayerIndex].type
                if (type === "image" || type === "video") {
                    dispatch(updateMediaLayer(jsonData, selectedSlideIndex, selectedLayerIndex, "image"))
                }
                else {
                    dispatch(addMediaLayer(jsonData, selectedSlideIndex, "image", campaign.dimension))
                }
            } else {
                dispatch(addMediaLayer(jsonData, selectedSlideIndex, "image", campaign.dimension))

            }
        } else {
            dispatch(updateBackground(jsonData, selectedSlideIndex, "image"))
        }
        setCropModel(false);
        setOutputImage(false);
    }

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        setCropLoader(true);
        try {
            const croppedImage = await getCroppedImg(
                currentImage,
                croppedAreaPixels,
                0
            )
            downloadFiles(croppedImage);
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels])

    const downloadFiles = (imageData) => {
        let ext = currentImage.match(/\.([^\./\?]+)($|\?)/)[1];
        setCropLoader(true);
        let data = {
            data: imageData,
            format: ext
        }
        dispatch(onGenerateFile(data, setCropLoader, setOutputImage))
    }

    return (
        <>
            <div className="innertab">
                <form className="tabSearch"
                    onSubmit={(e) => onFormSubmit(e)}
                >
                    <input
                        type="text"
                        placeholder="Search"
                        onInput={(e) => onInputChange(e)}
                    />
                    <span
                        className="inp-icon"
                        onClick={() => handleClick()}
                    ><i className="fa fa-search" aria-hidden="true"></i></span>
                </form>
                <div className="innertab-scroll" id={`scrollableImage_${type}`} >
                    <div className="row">
                        <div className="mediaList">
                            <ul>
                                <InfiniteScroll
                                    dataLength={imageData.length} //This is important field to render the next data
                                    next={() => searchImage(search.page)}
                                    hasMore={true}
                                    scrollableTarget={`scrollableImage_${type}`}
                                >
                                    <div className="media-ul-list">
                                        {
                                            imageData.length > 0 ?
                                                imageData.map((curElem, index) => {
                                                    return (

                                                        <li className='col-md-6 myImage-section cursor-pointer'
                                                            key={index}
                                                        >
                                                            <div className="mediaList-single myImage-respo m-1">
                                                                <img className="mediaImg"
                                                                    src={curElem.thumbnail}
                                                                    alt={`${search.keyword + " " + index}`}
                                                                    onClick={() => selectImage(curElem, index)}
                                                                />
                                                                {
                                                                    type === "media" ?
                                                                        selectedLayer ?
                                                                            selectedLayer.type === "image" ?
                                                                                curElem.url === selectedLayer.src ?
                                                                                    <div className="slide-active">
                                                                                        <i className="fa fa-check" aria-hidden="true"></i>
                                                                                    </div>
                                                                                    : ''
                                                                                : ""
                                                                            : ""
                                                                        :
                                                                        checkImage === index ?
                                                                            <div className="slide-active">
                                                                                <i className="fa fa-check" aria-hidden="true"></i>
                                                                            </div>
                                                                            : ''
                                                                }
                                                            </div>
                                                            <div className="crop-icon"
                                                                onClick={() => selectCrop(curElem.url)}
                                                            >
                                                                <i className="fa fa-crop"></i>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                                : ''
                                        }
                                    </div>
                                </InfiniteScroll>
                            </ul>
                            {
                                loader ?
                                    <div className="">
                                        <h4 className='text-center'>
                                            <i className="fa fa-spinner fa-spin" style={{ color: "#25aaf1" }} />
                                        </h4>
                                    </div>
                                    : ''
                            }
                            {
                                error ?
                                    <div className="col-md-12">
                                        <h6 className='text-center'>{error}</h6>
                                    </div>
                                    : ''
                            }
                        </div>
                    </div>
                </div>
            </div >

            <div className={`modal ${cropModel ? 'show-modal' : ''} `} style={{}}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header crop-header">
                            <h5 className="modal-title text-center">Crop Image</h5>
                            <button
                                onClick={(e) => setCropModel(false)}
                                type="button"
                                className="close img-close"
                                data-dismiss="modal"
                                aria-label="Close">
                                <img src={closeModalx} alt="" />

                            </button>
                        </div>
                        <div className="modal-body" style={{ position: "relative", height: "50vh" }}>
                            <Cropper
                                image={currentImage}
                                crop={crop}
                                zoom={zoom}
                                aspect={campaign.dimension === "1920x1080" ? 16 / 9 : campaign.dimension === "1080x1920" ? 9 / 16 : campaign.dimension === "1080x1080" ? 1 / 1 : 16 / 9}
                                onCropChange={setCrop}
                                onZoomChange={setZoom}
                                onCropComplete={onCropComplete}
                                objectFit="contain"
                            />
                            {
                                croppedAreaPixels === null ?
                                    <div className="crop-icon-center" style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                    }}>
                                        <i className="fa fa-spinner fa-spin mr-2" style={{ color: "#CE88FF", fontSize: '25px' }} />
                                    </div> : ''
                            }

                        </div>
                        <div className="modal-footer d-flex justify-content-between">
                            <button className="btn-custom mr-1" type="button" onClick={() => showCroppedImage()}>
                                {cropLoader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''}
                                {cropLoader ? 'Cropping' : 'Crop'}
                            </button>
                            <button
                                className={`${outputImage === false ? 'btn-custom-disable' : 'btn-custom'}`}
                                type="button"
                                disabled={outputImage === false ? true : false}
                                onClick={() => selectCropImage(outputImage)}
                            >
                                Use
                            </button>
                        </div>

                    </div>
                </div>
            </div>

        </>

    )
}

export default BgImageMedia
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import modalCloseIcon from "../../images/modal-close.png";
import { useDispatch } from 'react-redux';
import { onAddData } from '../../Actions/ChannelAction';

const AddRepoModal = (props) => {
    const dispatch = useDispatch()
    const [text, setText] = useState("")
    const [loader, setLoader] = useState({
        add: false
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            add: true
        })
        if (props.type === "idea") {
            let data = {
                text: text
            }
            dispatch(onAddData("add-user-idea", data, props.fetchIdeas, props.handleClose, loader, setLoader))
        }
        else if (props.type === "niche") {
            let data = {
                name: text
            }
            dispatch(onAddData("add-user-niches", data, props.fetchNiches, props.handleClose, loader, setLoader))
        }
        else {
            let data = {
                name: text
            }
            dispatch(onAddData("add-user-keyword", data, props.fetchKeyword, props.handleClose, loader, setLoader))
        }
    }

    useEffect(() => {
        if (!loader.add) {
            setText("")
        }
    }, [loader])

    useEffect(() => {
        setText("")
    }, [props.type])

    return (
        <Modal className="VideoModal dark" show={props.show} onHide={props.handleClose} centered>
            <Modal.Body>
                <div onClick={props.handleClose} className="vidClose">
                    <img alt="" src={modalCloseIcon} />
                </div>
                <h4 className='text-center mb-2 text-capitalize'>Add Your {props.type}</h4>
                <form onSubmit={handleSubmit}>
                    <div className="inp-group alt mt-0">
                        <label htmlFor="" className='text-capitalize'>Enter {props.type}</label>
                        <input
                            className="simple-inp"
                            type="text"
                            placeholder={`Enter your ${props.type}`}
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                            required
                        />
                    </div>

                    <div className="text-right foot-button">
                        <button className="demoLink ml-2" type='submit'>{loader.add ? <>Adding <i className="fa fa-spinner fa-spin mx-1" /></> : "Add"}</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal >
    )
}

export default AddRepoModal
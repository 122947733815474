import React from 'react'
import { useState } from 'react';
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { randomstring } from 'randomstring-js'
import modalCloseIcon from '../../images/modal-close.png';
import { onConnectAWS, onConnectMailvio } from '../../Actions/SocialAction';

const AmazonPopup = (props) => {

    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [randomString, setRandomString] = useState(randomstring())
    const [state, setState] = useState({
        awsAccessKey: "",
        awsSecretKey: "",
        awsRegion: "",
        awsBucket: ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        let data = { ...state }
        dispatch(onConnectAWS(data, props.handleClose, props.fetchIntegration, setLoader))
    }



    return (
        <Modal className="VideoModal small" show={props.show} onHide={props.handleClose} centered>
            <Modal.Body>
                <div onClick={props.handleClose} className="vidClose"><img src={modalCloseIcon} alt="" /></div>
                <form className="formSec" onSubmit={handleSubmit}>
                    <h4 >AWS Connection</h4>
                    <hr style={{ opacity: 1 }} />
                    <div className="inpLabelWrap ">
                        <div className='row'>
                            <div className='col-lg-12 my-1'>
                                <div className='inpLabel'>
                                    <label className='text-light my-1' htmlFor="name">Enter Access Key</label>
                                    <input
                                        className="inpLabel-inp"
                                        placeholder="Enter Access Key"
                                        type="text"
                                        name='awsAccessKey'
                                        value={state.awsAccessKey}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='col-lg-12 my-1'>
                                <div className='inpLabel'>
                                    <label className='text-light' htmlFor="token">Enter Secret Key</label>
                                    <input
                                        className="inpLabel-inp"
                                        placeholder="Enter Secret Key"
                                        type="text"
                                        name='awsSecretKey'
                                        value={state.awsSecretKey}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='col-lg-12 my-1'>
                                <div className='inpLabel'>
                                    <label className='text-light' htmlFor="secret">Enter Region</label>
                                    <input
                                        className="inpLabel-inp"
                                        placeholder="Enter Region"
                                        type="text"
                                        name='awsRegion'
                                        value={state.awsRegion}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='col-lg-12 my-1'>
                                <div className='inpLabel'>
                                    <label className='text-light' htmlFor="secret">Enter Bucket</label>
                                    <input
                                        className="inpLabel-inp"
                                        placeholder="Enter Bucket"
                                        type="text"
                                        name='awsBucket'
                                        value={state.awsBucket}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <button type="submit" className="btn-block inpBtn mt-3 create-modal-btn">{loader ? <>Connecting <i className="fa fa-spinner fa-spin mx-1" /> </> : `Connect`}</button>
                </form>
            </Modal.Body >
        </Modal >
    )
}

export default AmazonPopup
import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { AiOutlineClose, AiFillHeart, AiOutlineCheck } from "react-icons/ai"
import { IoRemoveCircleOutline } from "react-icons/io5"
import { BsArrowRight, BsArrowLeft } from "react-icons/bs"
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { onGoogleLangFetch } from "../../Actions/contentAction";
import { useDispatch } from "react-redux";
import { onChooseNiches, onFetchUserIdeas, onSelectLanguage, onUserRemoveNiches } from "../../Actions/ChannelAction";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { onFetchCamp, onLodeMore, onLodeMoreNiche, onRemoveFromSavedIdeas, onSelectLang, onSetSelectedIdeas, onUpdateCampaign } from "../../Actions/CreateVideoAction";
import { setAlert } from "../../Actions/AlertAction";
import { GiCheckMark } from "react-icons/gi";

let interval = false
const VideoIdeas = () => {

    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()

    const createVideo = useSelector(state => state.createVideo)
    const videoId = queryString.parse(location.search).id

    const [progress, setProgress] = useState(0)
    const [savedIdeas, setSavedIdeas] = useState([])
    const [pageLoader, setPageLoader] = useState(true)
    const [saveLoader, setSaveLoader] = useState(false)
    const [loader, setLoader] = useState({
        searchLoader: false,
        index: -1
    })

    const handleChange = (name, value, checked) => {
        const fieldName = name
        let arr = [...createVideo.videoIdeas.selectedIdeas]
        if (checked) {
            if (createVideo.videoIdeas.selectedIdeas.length >= 5) {
                dispatch(setAlert("You can use maximum 5 ideas in a single campaign!", "danger"))
            }
            else {
                if (name === "saved") {
                    let data = savedIdeas.find(({ text }) => text === value)
                    data = {
                        ...data,
                        id: 0,
                        name: data.text,
                        type: "saved"
                    }
                    delete data.text
                    arr = [...arr, data]

                } else {
                    let data = createVideo.videoIdeas.newIdeas.find(({ text }) => text === value)
                    data = {
                        ...data,
                        id: 0,
                        name: data.text,
                        type: "new"
                    }
                    delete data.text
                    arr = [...arr, data]
                }
            }


        } else {
            const data = arr.findIndex(({ name, type }) => name === value && type === fieldName)
            if (data !== -1) {
                arr.splice(data, 1)
            }
        }
        dispatch(onSetSelectedIdeas(arr))
    }


    const onUnselectIdea = (nameData) => {
        let arr = [...createVideo.videoIdeas.selectedIdeas]
        const ideaIndex = arr.findIndex(({ name }) => name === nameData)
        if (ideaIndex !== -1) {
            arr.splice(ideaIndex, 1)
            dispatch(onSetSelectedIdeas(arr))
        }
    }

    const handleLoadMore = () => {
        let arr = []
        createVideo.videoIdeas.newIdeas.forEach((curElem) => {
            arr.push(curElem.text)
        })
        let data = {
            ideas: arr,
            country: createVideo.country,
            keyword: createVideo.niche.searchTerm,
            language: createVideo.language
        }
        setLoader({
            ...loader,
            searchLoader: true
        })
        dispatch(onLodeMore("load-more-ideas", data, false, false, loader, setLoader))
    }

    const handleRemove = (e, val) => {
        e.stopPropagation()
        let data = {
            dailyIdeaId: val.id
        }

        const newArray = [...savedIdeas];

        let arr = newArray.filter(item => +item.id !== +val.id);
        setSavedIdeas(arr)

        // const indexToModify = newArray.findIndex(item => item.id === val.id);
        // if (indexToModify !== -1) {
        //     newArray[indexToModify].is_like = "0";
        //     setSavedIdeas(newArray);
        // }
        dispatch(onUserRemoveNiches("remove-idea", data, false))
    }

    const handleAdd = (e, val, index) => {
        e.stopPropagation()
        const dailyideaId = val.id
        val = { ...val, dailyideaId }
        delete val.id

        let newArray = [...createVideo.videoIdeas.newIdeas]

        let indexToModify = newArray.find(item => item.text === val.text);
        if (indexToModify) {
            indexToModify = {
                ...indexToModify,
                is_like: "1"
            }
        }
        setSavedIdeas(prevData => [...prevData, indexToModify]);
        let funcData = {
            fetchIdeas: fetchSaved,
            fetchTopVideos: () => { },
            fetchKeyword: () => { }
        }
        dispatch(onChooseNiches("add-user-idea", val, funcData))
    }


    const handleNext = () => {
        if (createVideo.videoIdeas.selectedIdeas.length > 0) {
            let ep = "update-idea-campaign"
            let data = { ...createVideo.videoIdeas, cId: createVideo.id }
            setSaveLoader(true)
            onGetProgress()
            dispatch(onUpdateCampaign(ep, data, navigate, "review-meta-data", setSaveLoader, setProgress, interval))
        } else {
            dispatch(setAlert("Please select ideas to continue!", "danger"))
        }
    }

    const fetchSaved = () => {
        dispatch(onFetchUserIdeas(setSavedIdeas))
    }

    const onGetProgress = () => {
        let prog = 0
        interval = setInterval(() => {
            prog += 1;
            if (prog > 99) {
                clearInterval(interval);
                setProgress(99)
                prog = 0
            } else {
                setProgress(prog)
            }
        }, 50)
    }


    const fetchCampaign = () => {
        let data = {
            campaignId: videoId
        }
        dispatch(onFetchCamp(data, setPageLoader, navigate))
    }

    useState(() => {
        if (videoId) {
            fetchCampaign()
        }
    }, [videoId])

    useEffect(() => {
        fetchSaved()
    }, [])

    return (
        !pageLoader ?
            <>
                <TitleBar title="Create Campaign" />
                <Navbar />

                <section className="siteWrap">
                    <div className="siteWrap-in" style={{ paddingTop: "32px" }}>

                        <div className="conatiner-fluid">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-md-11">
                                    <div className="row">
                                        <div className=" business-heading " style={{ marginBottom: "15px", flexFlow: "wrap", border: "none" }}>
                                            <div className="col-md-6" >
                                                <h2 className="p_space" >Select Video Ideas</h2>
                                            </div>
                                            <div className="col-md-6 mt-md-0 mt-sm-2 mt-2">
                                                <div className=" d-flex justify-content-md-end  justify-content-sm-start align-items-center" >
                                                    <Link className="demoLink mt-0" to={`/manual?id=${videoId}`} style={{ padding: "14px 32px 14px 10px" }}> <BsArrowLeft />Go To Niche</Link>
                                                    <button onClick={handleNext} className="demoLink ml-2 mt-0" style={{ padding: "14px 16px 14px 32px" }}>Go To Meta Data {saveLoader ? <i className="fa fa-spinner fa-spin mx-1" /> : <BsArrowRight />}  </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="progress" style={{ height: 5, background: "#424b6c", marginBottom: "15px" }}>
                                        <div class="progress-bar" role="progressbar" aria-valuenow={progress}
                                            aria-valuemin="0" aria-valuemax="100" style={{ width: `${progress}%`, background: "#22a0eb" }}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Nav variant="h" className="siteTab">
                                    <Nav.Item><Nav.Link eventKey="first">Saved</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="second">New</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="third">Selected</Nav.Link></Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <div>
                                            <div className="info-box-body alt">
                                                <div className="table-responsive">
                                                    <table className="table video-table text-left">
                                                        {savedIdeas.length > 0 ?
                                                            savedIdeas.filter((idea) => {
                                                                return +idea.is_like === 1
                                                            }).length > 0 ?
                                                                savedIdeas.filter((idea) => {
                                                                    return +idea.is_like === 1
                                                                }).map((curElem, index) => {
                                                                    return (
                                                                        <tr
                                                                            key={index}
                                                                            onClick={() => handleChange("saved", curElem.text, createVideo.videoIdeas.selectedIdeas.findIndex(({ name, type }) => (name === curElem.text) && (type === "saved")) === -1 ? true : false)}
                                                                            style={{ cursor: "pointer" }}
                                                                        >
                                                                            <td>
                                                                                <label class="custom-check mr-2">
                                                                                    {createVideo.videoIdeas.selectedIdeas.findIndex(({ name, type }) => (name === curElem.text) && (type === "saved")) === -1 ? "" :
                                                                                        <GiCheckMark size={15} style={{ position: "absolute", top: 5, left: 5, color: "#1478d3", zIndex: 1 }} />}
                                                                                    <span class="checkmark"></span>
                                                                                </label>

                                                                                <span>{curElem.text}</span>

                                                                            </td>
                                                                            <td className="text-right">
                                                                                <span
                                                                                    className="delIcon cursor-pointer"
                                                                                    onClick={(e) => handleRemove(e, curElem)}
                                                                                    title="Remove"
                                                                                >
                                                                                    <AiOutlineClose />
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                                :
                                                                <tr>
                                                                    <td colSpan={2} className="text-center" style={{ background: "none" }}>"No idea liked yet!"</td>
                                                                </tr>
                                                            : ""}

                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <div>
                                            <div className="info-box-body alt">
                                                <div className="table-responsive">
                                                    <table className="table video-table text-left">
                                                        {createVideo.videoIdeas.newIdeas.length > 0 ?
                                                            createVideo.videoIdeas.newIdeas.filter((ideas) => {
                                                                if (savedIdeas.find(({ text, is_like }) => +is_like === 1 && text === ideas.text)) {
                                                                } else {
                                                                    return ideas
                                                                }
                                                            }).map((curElem, index) => {
                                                                return (
                                                                    <tr
                                                                        key={index}
                                                                        onClick={() => handleChange("new", curElem.text, createVideo.videoIdeas.selectedIdeas.findIndex(({ name, type }) => (name === curElem.text) && (type === "new")) === -1 ? true : false)}
                                                                        style={{ cursor: "pointer", }}
                                                                    >
                                                                        <td>
                                                                            <label class="custom-check mr-2">
                                                                                {createVideo.videoIdeas.selectedIdeas.findIndex(({ name, type }) => (name === curElem.text) && (type === "new")) === -1 ? "" :
                                                                                    <GiCheckMark size={15} style={{ position: "absolute", top: 5, left: 5, color: "#1478d3", zIndex: 1 }} />}
                                                                                <span class="checkmark"></span>
                                                                            </label>
                                                                            <span>{curElem.text}</span>
                                                                        </td>
                                                                        <td className="text-right" >
                                                                            <span
                                                                                className="wishIcon cursor-pointer"
                                                                                style={{ background: curElem.is_selected ? "" : "gray", border: "none", color: "white" }}
                                                                                title={"Add to repository"}
                                                                                onClick={(e) => handleAdd(e, curElem, index)}
                                                                            >
                                                                                <AiFillHeart />
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                            :
                                                            <tr>
                                                                <td colSpan={2} className="text-center" style={{ background: "none" }}>"No new idea generated yet!"</td>
                                                            </tr>
                                                        }

                                                    </table>

                                                </div>
                                                <div className="text-center">
                                                    {
                                                        loader.searchLoader ? <i className="fa fa-spinner fa-spin" style={{ color: "#23a3ed", fontSize: 25 }} /> : ""
                                                    }
                                                </div>
                                                {createVideo.videoIdeas.newIdeas.length > 0 ?
                                                    <div className="text-center">
                                                        <button
                                                            className="demoLink"
                                                            onClick={handleLoadMore}
                                                            disabled={loader.searchLoader}
                                                        >
                                                            Load More
                                                        </button>
                                                    </div> : ""}
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                        <div>
                                            <div className="info-box-body alt">
                                                <div className="table-responsive">
                                                    <table className="table video-table text-left">
                                                        {createVideo.videoIdeas.selectedIdeas.length > 0 ?
                                                            createVideo.videoIdeas.selectedIdeas.map((curElem, index) => {
                                                                return (
                                                                    <tr key={index} onClick={() => onUnselectIdea(curElem.name)} style={{ cursor: "pointer" }}>
                                                                        <td>
                                                                            <span>{curElem.name}</span>
                                                                        </td>
                                                                        <td className="text-right">
                                                                            <span
                                                                                className="cursor-pointer"
                                                                                title="Unselect"
                                                                            >
                                                                                <IoRemoveCircleOutline
                                                                                    size={30}
                                                                                    color="red"
                                                                                />
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                            :
                                                            <div className="text-center">
                                                                You have not selected any idea yet!
                                                            </div>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>




                        </div>
                    </div >
                </section >
                {/* <SweetAlertTwo
                    show={info.enable}
                    title="Are You Sure?"
                    text={`Do you want to remove this idea!`}
                    icon="warning"
                    showCancelButton={true}
                    confirmButtonColor='#b32f3d'
                    cancelButtonColor='#2a2c3a'
                    confirmButtonText='Yes, remove!'
                    confirmTitle='Removing...'
                    confirmText="This won't be revert!"
                    confirmIcon='success'
                    handlePerform={handlePerformDelete}
                    onCancel={handleCancel}
                /> */}
                <Footer />
            </> :

            <div className="loader-sec">
                <div className="loader">
                </div>
            </div>
    )
}

export default VideoIdeas;
import React, { useEffect } from 'react'
import { IoEye } from "react-icons/io5"
import { HiHand } from "react-icons/hi"
const DesignTemplates = ({ curElem, state, handleSubmit, loader }) => {

    return (
        <div className={`col-lg-${state.type === "thumbnail" ? "4" : "3"} col-md-6 col-sm-12 col-xs-12 cursor-pointer`}>
            <div className="list-template-wrap">
                <div className="list_template_business text-center" style={{ border: +curElem.id === +state.templateId ? "1px solid #1478d3" : "" }}>
                    <img src={curElem.preview} alt="" />
                    <p style={{fontSize:13, marginTop:4}}>{curElem.name}</p>
                    <div className="list-template-btn">
                        <div>
                            {loader ?
                                <i className="fa fa-spinner fa-spin mx-1" style={{ fontSize: "35px", color: "#1478D3" }} />
                                :
                                <span className="demoLink" title="Use this template" onClick={() => handleSubmit(curElem.id)}><HiHand /></span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DesignTemplates
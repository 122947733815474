import React, { useEffect, useState, useCallback } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import queryString from 'query-string';
import { onFetchDesign, onFetchDesignFonts, onSaveImage, onUnmountDesign, onfetchAiImgae } from "../../Actions/DesignActions";
import { useDispatch } from "react-redux";
import { fabric } from 'fabric';
import { MdImageSearch } from 'react-icons/md'
import downloaded from '../../images/downloaded.png';
import save from '../../images/save.png';
import images from '../../images/images.png';
import text from '../../images/text.png';
import cinema from '../../images/cinema.png';
import speach from '../../images/speach.png';
import borders from '../../images/borders.png';
import ImageSearch from "./ImageSearch";
import TextEditor from "./TextEditor";
import emojiData from '../../Global/emoji.json';
import iconData from '../../Global/icon.json';
import parse from "html-react-parser";
import './ImageEditor.css'

import { SketchPicker } from "react-color";
import UploadSearch from './UploadSearch';
import { FaUndoAlt, FaRedoAlt, FaCopy, FaTrashAlt } from 'react-icons/fa'
import { BiChevronLeft, BiDownvote, BiUpvote } from 'react-icons/bi'
import { useSelector } from "react-redux";
import AiImageSearch from "./AiImageSearch";
import { IoArrowBackSharp } from "react-icons/io5";
import { onGenerateImage } from "../../Actions/CreateVideoAction";

const ImageEditor = (props) => {
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const auth = useSelector(state => state.auth)
    const id = queryString.parse(location.search).id
    const backType = queryString.parse(location.search).type
    const designData = useSelector(state => state.design.data)
    const [loader, setLoader] = useState(false)

    const initCanvas = () => (
        new fabric.Canvas('canvas', {
            preserveObjectStacking: true,
            height: designData ? designData.type === "profile" ? 400 : 360 : 360,
            width: designData ? designData.type === "profile" ? 400 : 640 : 640,
        })
    );


    const [canvasLoader, setCanvasLoader] = useState(false);
    const [canvas, setCanvas] = useState(false);
    const [canvasJson, setCanvasJson] = useState({
        data: []
    });
    const [textClick, setTextClick] = useState(false);

    const [canvasHistory, setCanvasHistory] = useState([canvas]);
    const [canvasState, setCanvasState] = useState(canvas);

    const [emoji, setEmoji] = useState(emojiData);
    const [iconImg, setIconImg] = useState(iconData);
    const [emojiQ, setEmojiQ] = useState('');
    const [iconQ, setIconQ] = useState('');


    /*color picker*/
    const [canvasColor, setCanvasColor] = useState('');

    const [showShapeColor, setShowShapeColor] = useState(false);
    const [shapeHex, setShapeHex] = useState('#000');

    const [showBorderColor, setShowBorderColor] = useState(false);
    const [borderHex, setBorderHex] = useState('#000');

    const onObjectModified = useCallback(
        e => {
            const newCanvasState = e.target.canvas.toJSON();
            setCanvasState(newCanvasState);
            // Limit history depth
            setCanvasHistory(history => [...history, newCanvasState]);
        },

        [setCanvasState, setCanvasHistory]
    );

    /** Save Canvas JSON Data */
    const saveJSon = () => {
        let newCanvasState = JSON.stringify(canvas);
        setCanvasState(newCanvasState);
        setCanvasHistory(history => [...history, newCanvasState]);
    }

    /** Initialize Canvas */

    const initCanvasStyle = () => {
        fabric.Object.prototype.set({
            borderColor: '#3e99f3',
            cornerColor: '#25aaf1',
            cornerSize: 10,
            cornerStyle: 'circle',
            transparentCorners: false,
            padding: 5,
            rotatingPointOffset: 20,
            borderDashArray: [5, 5],
            objectCaching: true,
            hoverCursor: 'pointer'
        });
    }

    const canvasBgColor = (e) => {
        setCanvasColor(e.hex);
        canvas.backgroundColor = e.hex;
        canvas.renderAll();
    }

    const addText = (itext, type) => {
        setTextClick(true);
        let text = new fabric.IText(itext, {
            fontFamily: 'arial',
            left: 100,
            top: 100,
            objecttype: 'text',
            fill: '#000000'
        });
        text.type = 'text';
        if (type === 'head') {
            text.fontWeight = 'bold';
            text.fontSize = 40;
        } else if (type === 'sub') {
            text.fontWeight = 400;
            text.fontSize = 30;
        } else if (type === 'body') {
            text.fontWeight = 'normal';
            text.fontSize = 20;
        }
        canvas.add(text);
        canvas.renderAll();
    }



    /** Add Images in Canvas */
    const addImage = (canvi, url, type = '') => {
        let activeObject = canvas.getActiveObject()
        if (activeObject) {
            if (activeObject.type === "image") {
                activeObject.setSrc(url, () => {
                    canvas.renderAll()
                })
            }
            else {
                new fabric.Image.fromURL(url, img => {
                    img.scale(type !== '' ? 0.4 : 0.2);
                    canvi.add(img);
                    canvi.centerObject(img);
                    canvi.renderAll();
                }, { crossOrigin: 'Anonymous' });
                setCanvasJson({ ...canvasJson, data: canvi.toJSON() });
                saveJSon();
            }
        } else {
            new fabric.Image.fromURL(url, img => {
                img.scale(type !== '' ? 0.4 : 0.2);
                canvi.add(img);
                canvi.centerObject(img);
                canvi.renderAll();
            }, { crossOrigin: 'Anonymous' });
            setCanvasJson({ ...canvasJson, data: canvi.toJSON() });
            saveJSon();
        }


    }

    /** Add Shapes in Canvas */
    const addShapes = (canvi, type) => {
        let shape;
        if (type === 'rect') {
            shape = new fabric.Rect({
                height: 100,
                width: 150,
                fill: '#000000',
                id: 'filled'
            });
        } else if (type === 'square') {
            shape = new fabric.Rect({
                height: 100,
                width: 100,
                fill: '#000000',
                id: 'filled'
            });
        } else if (type === 'round') {
            shape = new fabric.Rect({
                height: 100,
                width: 100,
                rx: 6,
                ry: 6,
                fill: '#000000',
                id: 'filled'
            });
        } else if (type === 'circle') {
            shape = new fabric.Circle({
                radius: 55,
                stroke: 'rgb(0,0,0)',
                strokeWidth: 2,
                fill: '#000000',
                id: 'filled'
            });
        }
        else if (type === 'triangle') {
            shape = new fabric.Triangle({
                width: 100,
                height: 100,
                rx: 30,
                ry: 30,
                stroke: 'rgb(0,0,0)',
                strokeWidth: 2,
                fill: '#000000'
            });
        } else if (type === 'circle-border') {
            shape = new fabric.Circle({
                radius: 55,
                stroke: 'rgb(0,0,0)',
                strokeWidth: 2,
                fill: 'rgba(0, 0, 0, 0)',
                id: 'nofilled'
            });
        } else if (type === 'triangle-border') {
            shape = new fabric.Triangle({
                width: 100,
                height: 100,
                rx: 30,
                ry: 30,
                stroke: 'rgb(0,0,0)',
                strokeWidth: 2,
                fill: 'rgba(0, 0, 0, 0)',
                id: 'nofilled'
            });
        }
        else if (type === 'rect-border') {
            shape = new fabric.Rect({
                height: 100,
                width: 150,
                fill: 'rgba(0, 0, 0, 0)',
                stroke: 'rgb(0,0,0)',
                strokeWidth: 2,
                id: 'nofill'
            });
        } else if (type === 'square-border') {
            shape = new fabric.Rect({
                height: 100,
                width: 100,
                fill: 'rgba(0, 0, 0, 0)',
                stroke: 'rgb(0,0,0)',
                strokeWidth: 2,
                id: 'nofill'
            });
        } else if (type === 'round-border') {
            shape = new fabric.Rect({
                height: 100,
                width: 100,
                rx: 6,
                ry: 6,
                fill: 'rgba(0, 0, 0, 0)',
                stroke: 'rgb(0,0,0)',
                strokeWidth: 2,
                id: 'nofill'
            });
        }

        canvi.add(shape);
        canvi.renderAll();
        setCanvasJson({ ...canvasJson, data: canvi.toJSON() });
        saveJSon();
    }

    const shapeColor = (e) => {
        let activeObject = canvas.getActiveObject();
        let objID = activeObject.id;
        setShapeHex(e.hex);

        if (activeObject !== null) {

            if (objID == 'nofill') {
                canvas.getActiveObject().set("stroke", e.hex);
            } else {
                canvas.getActiveObject().set("fill", e.hex);
                canvas.getActiveObject().set("stroke", e.hex);
            }

            canvas.renderAll();
        }
        saveJSon();
    }

    /** Add Border in Canvas */
    const addBorder = (type) => {
        let border = new fabric.Rect({
            width: 347,
            height: 50,
            fill: 'transparent',
            stroke: '#222',
            id: 'border'
        });

        if (type === 'solid-border') {
            border.set({
                strokeWidth: 3
            })
        }
        else if (type === 'dashed-border') {
            border.set({
                strokeWidth: 3,
                strokeDashArray: [8, 8]
            });
        }
        else if (type === 'dashed-border-light') {
            border.set({
                strokeWidth: 2,
                strokeDashArray: [5, 5]
            });
        }
        else if (type === 'dotted-border') {
            border.set({
                strokeWidth: 3,
                strokeLineCap: "round",
                strokeDashArray: [0, 8],
                strokeLineJoin: 'crop'
            });
        }

        canvas.add(border);
        canvas.renderAll();
    }

    const borderColor = (e) => {
        let activeObject = canvas.getActiveObject();
        setBorderHex(e.hex);
        if (activeObject !== null) {
            canvas.getActiveObject().set({
                stroke: e.hex
            });
            canvas.renderAll();
        }
    }

    /** Add Emoji in Canvas */
    const addEmoji = (code) => {
        let text = new fabric.IText(code, {
            fontSize: 40,
            editable: false,
        });

        text.setControlsVisibility({
            mt: false,
            mb: false,
            ml: false,
            mr: false,
        });

        canvas.add(text);
        text.center();
    }

    /** Add Icon in canvas */
    const addIcon = (code) => {
        let iconUnicode = getIconUnicode(code);
        let icon = new fabric.IText(iconUnicode, {
            fill: '#000000',
            fontSize: 50,
            fontFamily: 'FontAwesome',
            editable: false,
        });

        icon.setControlsVisibility({
            mt: false,
            mb: false,
            ml: false,
            mr: false,
        });

        canvas.add(icon);
        canvas.renderAll();
        icon.center();
    }

    function getIconUnicode(elem) {
        let content = window.getComputedStyle(
            document.querySelector('.' + elem), ':before'
        ).getPropertyValue('content');
        return content.replace(/["']/g, '');
    }

    /** Add top or Bottom layer */

    const onSearchEmoji = (e) => {
        setEmojiQ(e.target.value)
    };

    const onIconSearch = (e) => {
        setIconQ(e.target.value);
    }

    const searchEmoji = (emojis) => {
        return emojis.filter(
            emoji => emoji.tag.toLowerCase().indexOf(emojiQ.toLowerCase()) > -1
        );
    }

    const searchIcon = (icons) => {
        return icons.filter(
            icon => icon.toLowerCase().indexOf('fa-' + iconQ.toLowerCase()) > -1
        );
    }

    /** Delete Selected Layer */
    const deleteLayer = () => {
        let activeLayer = canvas.getActiveObjects();
        activeLayer.forEach(function (object) {
            canvas.remove(object);
        });
        saveJSon();
    }

    /** Layer Front or Back */
    const layerFront = (canvas) => {
        let activeObject = canvas.getActiveObject();
        canvas.bringToFront(activeObject);
        canvas.renderAll();
    }

    const layerBack = (canvas) => {
        let activeObject = canvas.getActiveObject();
        canvas.sendToBack(activeObject);
        canvas.renderAll();
    }

    /** Duplicate Layer */
    const duplicate = () => {
        let activeObject = canvas.getActiveObject();
        if (activeObject) {
            activeObject.clone(function (clonedObj) {
                canvas.discardActiveObject();
                clonedObj.set({
                    left: clonedObj.left + 10,
                    top: clonedObj.top + 10,
                    evented: true,
                });
                if (clonedObj.type === 'activeSelection') {
                    clonedObj.canvas = canvas;
                    clonedObj.forEachObject(function (obj) {
                        canvas.add(obj);
                    });
                    clonedObj.setCoords();
                } else {
                    canvas.add(clonedObj);
                }
                activeObject.top += 10;
                activeObject.left += 10;
                canvas.setActiveObject(clonedObj);
                canvas.requestRenderAll();
            });
        }
    }


    /** Get All JSON Data */
    const getJson = () => {
        let data = JSON.stringify(canvas.toJSON());
        let newData = { ...designData }
        newData.data = data
        setCanvasLoader(true);
        dispatch(onSaveImage(newData, navigate, setCanvasLoader))
    }

    const moveHistory = useCallback(
        step => {
            const currentStateIndex = canvasHistory.indexOf(canvasState);
            const prevState = canvasHistory[currentStateIndex + step];
            canvas.loadFromJSON(prevState);
            setCanvasState(prevState);
        },
        [canvas, canvasState, canvasHistory, setCanvasState]
    );

    const onUndo = useCallback(() => moveHistory(-1), [moveHistory]);

    const onRedo = useCallback(() => moveHistory(1), [moveHistory]);

    const downloadCanvas = () => {
        const link = document.createElement('a');
        link.download = 'download.png';
        link.href = canvas.toDataURL({
            format: 'png',
            multiplier: designData.type === "logo" || designData.type === "profile" ? 2 : designData.type === "cover" ? 4 : designData.type === "thumbnail" ? 3 : 2
        }
            // 'image/png'
        );
        link.click();
    }

    const onUseImage = () => {
        let img = canvas.toDataURL('image/png');
        setCanvasLoader(true);
        let data = {
            id: id,
            type: designData.type,
            data: img
        }
        if (props.type === "auto") {
            dispatch(onGenerateImage(data, props.setState, props.setActiveEditor, setCanvasLoader, props.state, "auto", canvas))
        } else {
            dispatch(onGenerateImage(data, props.activeIndex.index, props.setActiveEditor, setCanvasLoader, false, "manual", canvas))
        }
    }

    useEffect(() => {
        const handleKey = (event) => {
            if (event.keyCode === 40) {
                event.preventDefault()
                layerBack(canvas)
            }
            if (event.keyCode === 38) {
                event.preventDefault()
                layerFront(canvas)
            }
            if (event.keyCode === 46) {
                deleteLayer()
            }
            if (event.keyCode === 90 && (event.ctrlKey || event.metaKey)) {
                onUndo()
            }
            if (event.keyCode === 89 && (event.ctrlKey || event.metaKey)) {
                onRedo()
            }
        }
        document.addEventListener("keydown", handleKey)
        return () => document.removeEventListener("keydown", handleKey)
    }, [canvas, canvasState])


    useEffect(() => {
        if (canvas) {

            canvas.loadFromJSON(canvas);
            canvas.on("object:modified", onObjectModified);
        }
    }, [canvas, onObjectModified]);

    const onObjectSelected = (e) => {
        if (e.target?.get('type') === 'text') {
            setTextClick(true);
        } else {
            setTextClick(false);
        }
    }

    useEffect(() => {
        if (canvas) {
            canvas.on('selection:updated', onObjectSelected);
        }
    }, [canvas, onObjectSelected])


    useEffect(() => {
        if (canvas) {
            initCanvasStyle();
            canvas.loadFromJSON(designData.data, function () {
                canvas.backgroundColor = 'white';
                let height = designData.type === "profile" ? 400 : 360
                let width = designData.type === "profile" ? 400 : 640
                let scaleRatio = Math.min(canvas.width / width, canvas.height / height);
                canvas.backgroundImage.scaleX = scaleRatio;
                canvas.backgroundImage.scaleY = scaleRatio;
                canvas.setDimensions({ width: width, height: height });
                canvas.renderAll();
            })

        }
    }, [canvas, designData])

    useEffect(() => {
        if (canvas) {
            if (id === undefined) {
                canvas.dispose();
                setCanvas(initCanvas);
                initCanvasStyle();
            }
        }
    }, [location])


    const fetchDesign = () => {
        let data = { id: id }
        dispatch(onFetchDesign(data, setLoader))
    }
    useEffect(() => {
        if (id && !props.activeEditor) {
            fetchDesign()
        }
        return () => {
            dispatch(onUnmountDesign())
        }
    }, [id])

    useEffect(() => {
        if (designData) {
            initCanvasStyle()
            setCanvas(initCanvas)
            dispatch(onFetchDesignFonts())
        }
    }, [designData])

    useEffect(() => {
        if (props.data) {
            initCanvasStyle()
            setCanvas(initCanvas)
            dispatch(onFetchDesignFonts())
        }
    }, [props])

    return (
        loader ?
            <div className="loader-sec">
                <div className="loader" />
            </div> :

            <>
                {props.activeEditor ? "" :
                    <>
                        <TitleBar title="Create" />
                        <Navbar />
                    </>
                }
                <section className="siteWrap">

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="business-wrap pt-3" style={{ padding: "40px 57px 0 87px" }} >

                                    <div className="business-top text-light">
                                        {props.activeEditor ?
                                            <h2 onClick={() => props.setActiveEditor(false)} className="cursor-pointer"><IoArrowBackSharp style={{ paddingBottom: 3, fontSize: 30 }} /> Thumbnail Editor</h2>
                                            :
                                            <>
                                                <div className="d-flex bd-highlight">
                                                    <div
                                                        className=" flex-grow-1 bd-highlight text-capitalize pt-2"
                                                        style={{ fontSize: 25, fontWeight: 700 }}
                                                    >
                                                        <Link to={backType === "edit" ? "/design" : `/create-page?type=${designData.type}`} style={{ color: "#fff", fontSize: 25, fontWeight: "bold" }}>
                                                            <BiChevronLeft style={{ paddingBottom: 2 }} size={40} />
                                                            Create {designData.type}
                                                        </Link>
                                                    </div>
                                                </div>
                                                <hr style={{ color: "#fff", margin: 3 }} />
                                            </>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="container-fluid" style={{ margin: "initial", paddingLeft: "113px" }}>
                        <div className="row  justify-content-start">
                            <div className={`canvas_edit ${designData.type === "profile" ? "profile_canvas_editor col-lg-4" : "col-lg-5"}  p-0 `}>
                                {/* <div className={`creator_wrapper p-0`}> */}
                                <div className={` ${designData.type === "profile" ? "profile_canvas_box" : "creator-box"} `}>
                                    <div className="creator">
                                        <div className="row">
                                            <div className="col-md-6 ">
                                                <div className="creator-icon">
                                                    <ul className="image_canvas_action">
                                                        <li>
                                                            <span
                                                                title='Undo'
                                                                onClick={() => onUndo()}
                                                                className={`${canvasHistory[0] === canvasState ? 'hide-button' : ''} cursor-pointer hover-button-prop`}
                                                            >
                                                                <FaUndoAlt
                                                                    size={20}
                                                                />
                                                            </span>
                                                        </li>
                                                        |
                                                        <li>
                                                            <span
                                                                title='Redo'
                                                                onClick={(e) => onRedo()}
                                                                className={`${canvasHistory[canvasHistory.length - 1] === canvasState ? 'hide-button' : ''} cursor-pointer hover-button-prop`}
                                                            >
                                                                <FaRedoAlt
                                                                    size={20}
                                                                />
                                                            </span>
                                                        </li>
                                                        |
                                                        <li>
                                                            <span
                                                                title='Duplicate Layer'
                                                                onClick={(e) => duplicate(e)}
                                                                className="cursor-pointer hover-button-prop"
                                                            >
                                                                <FaCopy
                                                                    size={20}
                                                                />
                                                            </span>
                                                        </li>
                                                        |
                                                        <li className="downsize">
                                                            <span
                                                                title='Down Layer'
                                                                onClick={(e) => layerBack(canvas)}
                                                                className="cursor-pointer hover-button-prop"
                                                            >
                                                                <BiDownvote
                                                                    size={23}
                                                                />
                                                            </span>
                                                        </li>
                                                        |
                                                        <li className="downsize">
                                                            <span
                                                                title='Up Layer'
                                                                onClick={(e) => layerFront(canvas)}
                                                                className="cursor-pointer hover-button-prop"
                                                            >
                                                                <BiUpvote
                                                                    size={23}
                                                                />
                                                            </span>
                                                        </li>
                                                        |
                                                        <li>
                                                            <span
                                                                title='Delete'
                                                                onClick={(e) => deleteLayer(e)}
                                                                className="cursor-pointer hover-button-prop"
                                                            >
                                                                <FaTrashAlt
                                                                    size={20}
                                                                />
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="creator-download"  >
                                                    <ul className="">
                                                        <li>
                                                            <span onClick={(e) => downloadCanvas(e)} className="cursor-pointer">
                                                                <span>
                                                                    <img src={downloaded} alt="download-icon" />
                                                                </span>
                                                                Download
                                                            </span>
                                                            {/* <span id="downloadCanvas" style={{ display: "none" }}></span> */}
                                                        </li>
                                                        {
                                                            +auth.user.isClient !== 1 ?
                                                                props.activeEditor ?
                                                                    <li>
                                                                        <span onClick={onUseImage} className="cursor-pointer">
                                                                            {canvasLoader ?
                                                                                <span><i className="fa fa-spinner fa-spin mx-1" />
                                                                                    Using
                                                                                </span>
                                                                                :
                                                                                <span><img src={save} alt="save-icon" className="mx-1" />
                                                                                    Use
                                                                                </span>
                                                                            }
                                                                        </span>
                                                                    </li>
                                                                    :
                                                                    <li>
                                                                        <span onClick={getJson} className="cursor-pointer">
                                                                            {canvasLoader ?
                                                                                <span><i className="fa fa-spinner fa-spin mx-1" />
                                                                                    Saving
                                                                                </span>
                                                                                :
                                                                                <span><img src={save} alt="save-icon" className="mx-1" />Save</span>
                                                                            }
                                                                        </span>
                                                                    </li>
                                                                : ""
                                                        }

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row mt-2">
                                        <div className="col-md-12">
                                            <canvas id="canvas" className='creator-img'>
                                                <h4>Search from library or upload an image to start customizing it</h4>
                                            </canvas>
                                        </div>
                                    </div>
                                </div>
                                {/* </div> */}
                            </div>
                            <div className="canvas_tools col-lg-7 ">
                                {/* <div className="social-media-covers"> */}
                                <div className={`${designData.type === "profile" ? "profile_canvas_tools " : "social-media-covers"}`}>
                                    <nav>
                                        <ul className="laryer_options_wrap nav nav-tabs" role="tablist">

                                            <li class="nav-item">
                                                <a class="nav-link active " id="nav-profile-tab" data-bs-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="true">
                                                    <span><img src={images} alt="images-img" /></span>
                                                    <h6 className="">Images</h6>
                                                </a>
                                            </li>

                                            <li class="nav-item">
                                                <a class="nav-link" id="nav-text-tab" data-bs-toggle="tab" href="#nav-text" role="tab" aria-controls="nav-text" aria-selected="false">
                                                    <span><img src={text} alt="star-img" /></span>
                                                    <h6 className="">Text</h6>
                                                </a>
                                            </li>

                                            <li class="nav-item">
                                                <a class="nav-link" id="nav-cinema-tab" data-bs-toggle="tab" href="#nav-cinema" role="tab" aria-controls="nav-cinema" aria-selected="false">
                                                    <span><img src={cinema} alt="star-img" /></span>
                                                    <h6 className="">Extras</h6>
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="nav-speach-tab" data-bs-toggle="tab" href="#nav-speach" role="tab" aria-controls="nav-speach" aria-selected="false">
                                                    <span><img src={speach} alt="star-img" /></span>
                                                    <h6 className="">Shapes</h6>
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="nav-border-tab" data-bs-toggle="tab" href="#nav-border" role="tab" aria-controls="nav-border" aria-selected="false">
                                                    <span><img src={borders} alt="star-img" /></span>
                                                    <h6 className="">Border</h6>
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="nav-border-tab" data-bs-toggle="tab" href="#Ai-Image" role="tab" aria-controls="nav-border" aria-selected="false">
                                                    <span style={{
                                                        marginTop: '-4px', display: 'inherit'
                                                    }}><MdImageSearch style={{
                                                        fontSize: "25px",
                                                    }} /></span>
                                                    <h6 className="">AI Images</h6>
                                                </a>
                                            </li>

                                        </ul>
                                    </nav>
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className=" tab-pane p-2 fade show active " id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                            <div className="nav-img-content ">
                                                <ul className="nav nav-pills laryer_options_wrap2 mb-2 justify-content-center" id="pills-tab" role="tablist">
                                                    <li className="nav-item">
                                                        <a className="nav-link active show" id="pills-home-tab" data-bs-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Library</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link " id="pills-upload-tab" data-bs-toggle="pill" href="#pills-upload" role="tab" aria-controls="pills-upload" aria-selected="false">Uploads</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" id="pills-color-tab" data-bs-toggle="pill" href="#pills-color" role="tab" aria-controls="pills-color" aria-selected="false">Color</a>
                                                    </li>

                                                </ul>
                                                <div className="tab-content" id="pills-tabContent">
                                                    <div className="tab-pane fade active show" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                                        <ImageSearch addImage={addImage} canvas={canvas} />
                                                    </div>
                                                    <div className="tab-pane fade " id="pills-upload" role="tabpanel" aria-labelledby="pills-upload-tab">
                                                        <UploadSearch
                                                            addImage={addImage}
                                                            canvas={canvas}

                                                        />
                                                    </div>
                                                    <div className="tab-pane p-2 fade" id="pills-color" role="tabpanel" aria-labelledby="pills-color-tab">
                                                        <div className="row p-2 align_items-center">
                                                            <div className="col-md-12 p-0 ">
                                                                <div className="d-flex color_picker_wrapper justify-content-md-between justify-content-xl-evenly">
                                                                    <h6 className="bgcolor">Background Color</h6>
                                                                    <SketchPicker color={canvasColor} onChange={(e) => canvasBgColor(e)} styles={{ position: 'static' }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tab-pane p-2 fade" id="nav-text" role="tabpanel" aria-labelledby="nav-text-tab">
                                            <div className={`text-items ${textClick ? 'hide-social' : ''} `} id="text-realative">
                                                <a onClick={(e) => addText('Add a Heading', 'head')} className="text-item item item-1 cursor-pointer">Add a Heading</a>
                                                <a onClick={(e) => addText('Add a SubHeading', 'sub')} className="text-item item item-1 subhead cursor-pointer">Add a SubHeading</a>
                                                <a onClick={(e) => addText('Add some body text', 'body')} className="text-item item item-1 txtbody cursor-pointer">Add some body text</a>
                                            </div>
                                            <div id={`text-styles`} className={`text-styles-wrap ${textClick ? 'display-div left-0' : 'hide-social'}  `}>
                                                <a onClick={(e) => setTextClick(false)} className="visit"><i className="fas fa-long-arrow-alt-left cursor-pointer" /></a>
                                                <TextEditor canvas={canvas} />
                                            </div>
                                        </div>

                                        <div className="tab-pane p-2 fade" id="nav-cinema" role="tabpanel" aria-labelledby="nav-cinema-tab">
                                            <div className="cinema-tab">
                                                <ul className="nav nav-pills  d-flex justify-content-center mb-3" id="pills-tab" role="tablist">
                                                    <li className="nav-item">
                                                        <a className="nav-link active" id="pills-icon-tab" data-bs-toggle="pill" href="#pills-icon" role="tab" aria-controls="pills-icon" aria-selected="true">Icons</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" id="pills-emoji-tab" data-bs-toggle="pill" href="#pills-emoji" role="tab" aria-controls="pills-emoji" aria-selected="false">Emoji</a>
                                                    </li>
                                                </ul>
                                                <div className="tab-content emoji_custom_wrapper" id="pills-tabContent ">
                                                    <div className="tab-pane fade show active" id="pills-icon" role="tabpanel" aria-labelledby="pills-icon-tab">
                                                        <div className="search-img">
                                                            <div className="form-group has-search">
                                                                <input type="text" onChange={(e) => onIconSearch(e)} className="form-control" placeholder="Search" style={{ position: 'relative' }} />
                                                                <span className="fa fa-search form-control-feedback" style={{ position: "absolute" }} />
                                                            </div>
                                                        </div>
                                                        <div className="icon-items">
                                                            {
                                                                iconImg.length > 0 ?
                                                                    searchIcon(iconImg).map((item, index) => {
                                                                        return (
                                                                            <a onClick={(e) => addIcon(item)} key={index} className="emoji-custom">
                                                                                <i className={`fa ${item}`} aria-hidden="true"></i>
                                                                            </a>
                                                                        )
                                                                    })
                                                                    : ''
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="pills-emoji" role="tabpanel" aria-labelledby="pills-emoji-tab">
                                                        <div className="search-img">
                                                            <div className="form-group has-search">
                                                                <input type="text" onChange={(e) => onSearchEmoji(e)} className="form-control" placeholder="Search" style={{ position: 'relative' }} />
                                                                <span className="fa fa-search form-control-feedback" style={{ position: "absolute" }} />
                                                            </div>
                                                        </div>
                                                        <div className="icon-items">
                                                            {
                                                                emoji.length > 0 ?
                                                                    searchEmoji(emoji).map((item, index) => {
                                                                        return (
                                                                            <>
                                                                                <a onClick={(e) => addEmoji(parse(`&#x${item.code};`))} key={index} className="emoji-custom">
                                                                                    <span title={item.tag}>{parse(`&#x${item.code};`)}</span>
                                                                                </a>
                                                                            </>
                                                                        )
                                                                    })
                                                                    : ''
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="nav-speach" role="tabpanel" aria-labelledby="nav-speach-tab">
                                            <div className="shape-color p-2">
                                                <div className="shape_color_wrapper">
                                                    <div id="cp6" className="input-group image-editor-2" title="Using color option">
                                                        {
                                                            <>
                                                                <div className="d-flex justify-content-between align-items-center" style={{ width: "100%", borderBottom: ' 1px solid #1e1e1f' }}>
                                                                    <h5 style={{ fontSize: '15px', }}>Shape Color</h5>
                                                                    <div className="">
                                                                        <div className="swatch" onClick={(e) => setShowShapeColor(true)} style={{ width: "50px", height: "45px" }}>
                                                                            <div className="swatch-color" style={{ backgroundColor: shapeHex }} />
                                                                        </div>
                                                                        {showShapeColor ? <div>
                                                                            <div className="swatch-cover" onClick={(e) => setShowShapeColor(false)} />
                                                                            <SketchPicker color={shapeHex} onChange={(e) => shapeColor(e)} />
                                                                        </div> : null}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                    <div className="shape-box d-flex justify-content-evenly  mt-2">
                                                        <div className="shape-item">
                                                            <a onClick={(e) => addShapes(canvas, 'square')} />
                                                        </div>
                                                        <div className="shape-item2">
                                                            <a onClick={(e) => addShapes(canvas, 'round')} />
                                                        </div>
                                                        <div className="shape-item3">
                                                            <a onClick={(e) => addShapes(canvas, 'rect')} />
                                                        </div>
                                                    </div>

                                                    <div className="shape-box d-flex justify-content-evenly  mt-2">
                                                        <div className="shape-item">
                                                            <a onClick={(e) => addShapes(canvas, 'circle')} className="circle"></a>
                                                            <a onClick={(e) => addShapes(canvas, 'triangle')} className="triangle"></a>
                                                        </div>
                                                    </div>


                                                    <div className="shape-box d-flex justify-content-evenly  mt-2">
                                                        <div className="shape-item-border">
                                                            <a onClick={(e) => addShapes(canvas, 'square-border')} />
                                                        </div>
                                                        <div className="shape-item2-border">
                                                            <a onClick={(e) => addShapes(canvas, 'round-border')} />
                                                        </div>
                                                        <div className="shape-item3-border">
                                                            <a onClick={(e) => addShapes(canvas, 'rect-border')} />
                                                        </div>
                                                    </div>

                                                    <div className="shape-box d-flex justify-content-evenly   mt-2">
                                                        <div className="shape-item">
                                                            <a onClick={(e) => addShapes(canvas, 'circle-border')} className="cliped-circle " />
                                                            <a onClick={(e) => addShapes(canvas, 'triangle-border')} className="cliped-triangle " />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane p-2 fade" id="nav-border" role="tabpanel" aria-labelledby="nav-border-tab">
                                            <div className="border-content mt-2">
                                                <div className="border_content_wrapper ">
                                                    <div className="d-flex justify-content-between align-items-center" style={{ width: "100%", height: "auto", borderBottom: ' 1px solid #1e1e1f' }}>
                                                        <h5 style={{ fontSize: '15px', }}>Border color</h5>
                                                        <div style={{ marginBottom: "5px" }}>
                                                            <div id="cp7" className="input-group image-editor-2" title="Using color option">
                                                                {
                                                                    <>
                                                                        <div className="swatch" onClick={(e) => setShowBorderColor(true)}>
                                                                            <div className="swatch-color" style={{ backgroundColor: borderHex }} />
                                                                        </div>
                                                                        {showBorderColor ? <div>
                                                                            <div className="swatch-cover" onClick={(e) => setShowBorderColor(false)} />
                                                                            <SketchPicker color={borderHex} onChange={(e) => borderColor(e)} />
                                                                        </div> : null}
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="change-border-color mt-3">
                                                        <a onClick={(e) => addBorder('solid-border')} className="border-style solid-border" />
                                                        <a onClick={(e) => addBorder('dashed-border')} className="border-style dashed-border" />
                                                        <a onClick={(e) => addBorder('dashed-border-light')} className="border-style dashed-border-light" />
                                                        <a onClick={(e) => addBorder('dotted-border')} className="border-style dotted-border" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane p-2 fade" id="Ai-Image" role="tabpanel" aria-labelledby="nav-border-tab">
                                            <div className="border-content mt-2">
                                                <h6 style={{ marginBottom: "10px" }}>AI Image</h6>
                                                <div className="tab-pane fade active show" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                                    <AiImageSearch addImage={addImage} canvas={canvas} />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >

                {
                    props.activeEditor ? "" :
                        < Footer />
                }

            </>
    )
}

export default ImageEditor;
import React, { useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { FiEdit, FiUpload } from "react-icons/fi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs"
import Accordion from 'react-bootstrap/Accordion';
import ThumbModal from "./ThumbModal";
import ImageEditor from "../Design/ImageEditor";
import UploadModal from "./UploadModal";
import { onEditThumbnail, onFetchCamp, onGenerateThumbnailUpdate, onUpdateCampaign } from "../../Actions/CreateVideoAction";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { useEffect } from "react";
import FabricToGenerate from "./FabricToGenerate";

let interval = false
const Thumbnail = () => {

    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()

    const createVideo = useSelector(state => state.createVideo)
    const videoId = queryString.parse(location.search).id

    const [progress, setProgress] = useState(0)
    const [pageLoader, setPageLoader] = useState(true)
    const [saveLoader, setSaveLoader] = useState(false)
    const [tempId, setTempId] = useState(false)
    const [activeEditor, setActiveEditor] = useState(false)
    const [activeIndex, setActiveIndex] = useState({
        index: -1
    })

    const [thumbArr, setThumbArr] = useState([])
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const handleEdit = (curElem, index) => {
        setActiveEditor(true)

        let data

        if (curElem.data) {
            data = {
                type: "thumbnail",
                data: typeof (curElem.data) === "string" ? curElem.data : JSON.stringify(curElem.data)
            }
        } else {
            data = {
                type: "thumbnail",
                data: {
                    "version": "5.3.0",
                    "objects": [
                        {
                            "type": "image",
                            "version": "5.3.0",
                            "originX": "left",
                            "originY": "top",
                            "left": 183,
                            "top": 86.7,
                            "width": 1280,
                            "height": 853,
                            "fill": "rgb(0,0,0)",
                            "stroke": null,
                            "strokeWidth": 0,
                            "strokeDashArray": null,
                            "strokeLineCap": "butt",
                            "strokeDashOffset": 0,
                            "strokeLineJoin": "miter",
                            "strokeUniform": false,
                            "strokeMiterLimit": 4,
                            "scaleX": 0.2,
                            "scaleY": 0.2,
                            "angle": 0,
                            "flipX": false,
                            "flipY": false,
                            "opacity": 1,
                            "shadow": null,
                            "visible": true,
                            "backgroundColor": "",
                            "fillRule": "nonzero",
                            "paintFirst": "fill",
                            "globalCompositeOperation": "source-over",
                            "skewX": 0,
                            "skewY": 0,
                            "cropX": 0,
                            "cropY": 0,
                            "src": curElem.thumbnail,
                            "crossOrigin": "*",
                            "filters": []
                        }
                    ],
                    "background": "white"
                }
            }
            data.data = JSON.stringify(data.data)
        }

        dispatch(onEditThumbnail(data))
        setActiveIndex({
            ...activeIndex,
            index: index
        })
    }
    const onGetProgress = () => {
        let prog = 0
        interval = setInterval(() => {
            prog += 1;
            if (prog > 99) {
                clearInterval(interval);
                setProgress(99)
                prog = 0
            } else {
                setProgress(prog)
            }
        }, 50)
    }

    const onOpenUpload = (index) => {
        setActiveIndex({
            ...activeIndex,
            index: index
        })
        handleShow1()
    }

    const handleNext = () => {
        let ep = "update-thumbnail-campaign"
        let data = { thumbnail: createVideo.thumbnail, cId: createVideo.id }
        setSaveLoader(true)
        onGetProgress()
        dispatch(onUpdateCampaign(ep, data, navigate, "video-page", setSaveLoader, setProgress, interval))
    }


    const fetchCampaign = () => {
        let data = {
            campaignId: videoId
        }
        dispatch(onFetchCamp(data, setPageLoader, navigate))
    }

    useEffect(() => {
        if (createVideo.thumbnail.length > 0) {
            setTempId(createVideo.thumbnail[0].templateId)
            setThumbArr(createVideo.thumbnail)
        }
    }, [createVideo.thumbnail])

    useState(() => {
        if (videoId) {
            fetchCampaign()
        }
    }, [videoId])


    return (
        !pageLoader ?
            <>
                <TitleBar title="Create Campaign" />
                <Navbar />
                {activeEditor ?
                    <>
                        <ImageEditor
                            setActiveEditor={setActiveEditor}
                            activeEditor={activeEditor}
                            activeIndex={activeIndex}
                        />
                    </> :


                    <section className="siteWrap">
                        <div className="siteWrap-in" style={{ paddingTop: "32px" }}>

                            <div className="conatiner-fluid">
                                <div className="row justify-content-center align-items-center">
                                    <div className="col-md-11">
                                        <div className=" business-heading " style={{ marginBottom: "15px", flexFlow: "wrap", border: "none" }}>
                                            <div className="row align-items-center" style={{ width: "100%" }}>
                                                <div className="col-md-6" >
                                                    <h2>Thumbnail</h2>
                                                </div>
                                                <div className="col-md-6 mt-md-0 mt-sm-2 mt-2">
                                                    <div className=" d-flex justify-content-end align-items-center">
                                                        <Link className="demoLink mt-0" to={`/publishing?id=${videoId}`} style={{ padding: "14px 32px 14px 10px" }}> <BsArrowLeft />Go To Publishing</Link>
                                                        <button onClick={handleNext} className="demoLink ml-2 mt-0" style={{ padding: "14px 16px 14px 32px" }}> Go To Videos {saveLoader ? <i className="fa fa-spinner fa-spin mx-1" /> : <BsArrowRight />} </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="progress" style={{ height: 5, background: "#424b6c", marginBottom: "15px" }}>
                                            <div className="progress-bar" role="progressbar" aria-valuenow={progress}
                                                aria-valuemin="0" aria-valuemax="100" style={{ width: `${progress}%`, background: "#22a0eb" }}>
                                            </div>
                                        </div>
                                        <div className="text-end" style={{ paddingRight: "29px" }}>
                                            <button className="demoLink mb-4 mt-0 " onClick={handleShow}>Change Thumbnail</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-12">
                                        <div className="site-accordion">
                                            <Accordion defaultActiveKey="0">
                                                {thumbArr.length > 0 ?
                                                    thumbArr.map((curElem, index) => {
                                                        return (
                                                            <Accordion.Item eventKey={index} key={index} onClick={() => setTempId(curElem.templateId)}>
                                                                <Accordion.Header>Video #{index + 1}</Accordion.Header>
                                                                <Accordion.Body>
                                                                    <div className="video-group">
                                                                        <ul>
                                                                            <li>
                                                                                <div className="video-group-thumb">
                                                                                    {curElem.thumbnail === "" ?
                                                                                        <div className="d-flex align-items-center justify-content-center" style={{ height: "100%", border: "1px solid #495057" }}>
                                                                                            <i className="fa fa-spinner fa-spin" style={{ color: "#22a1ec", fontSize: 30 }} />
                                                                                        </div>
                                                                                        : <img src={curElem.thumbnail} alt="" />
                                                                                    }
                                                                                </div>
                                                                                <div className="video-group-action">
                                                                                    <span onClick={() => handleEdit(curElem, index)}><FiEdit /> Edit</span>
                                                                                    <span onClick={() => onOpenUpload(index)}><FiUpload /> Upload New</span>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        )
                                                    })
                                                    : ""}
                                            </Accordion>
                                        </div>
                                        {thumbArr.length > 0 ?
                                            <FabricToGenerate
                                                canvases={thumbArr}
                                            /> : ""}
                                    </div>
                                </div>
                            </div>
                            <ThumbModal
                                show={show}
                                handleClose={handleClose}
                                fetchCampaign={fetchCampaign}
                                tempId={tempId}
                                videoId={videoId}
                            />

                            <UploadModal
                                show={show1}
                                handleClose={handleClose1}
                                activeIndex={activeIndex.index}
                            />
                        </div>
                    </section>
                }
                <Footer />
            </>
            : <div className="loader-sec">
                <div className="loader">
                </div>
            </div>
    )
}
export default Thumbnail;
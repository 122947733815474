import React from 'react'
import { FaEye, FaTrash } from 'react-icons/fa'
import vooduuIcon from "../../images/fav-new.png"
import { useEffect } from 'react'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import SweetAlertTwo from '../SweetAlertTwo'
import { useDispatch } from 'react-redux'
import { onDeleteCampaign } from '../../Actions/ChannelAction'
import Swal from 'sweetalert2'
import { onFetchAutoVideo } from '../../Actions/CreateVideoAction'
import { useSelector } from 'react-redux'

const CampaignRows = ({ curElem, fetchCampaigns }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const auth = useSelector(state => state.auth)
    const [camp, setCamp] = useState(curElem)
    const [noOfVideoAuto, setNoOfVideosAuto] = useState({
        videos: 0,
        status: 1
    })

    const [info, setInfo] = useState({
        enable: false
    })

    const handleCancel = () => {
        setInfo({
            ...info,
            enable: false
        })
    }
    const handleDelete = (e) => {
        e.stopPropagation()
        if (+curElem.noOfVideos === 0) {
            setInfo({
                ...info,
                enable: true
            })
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'This campaign contains videos in it, Please delete videos to delete this campaign!',
                confirmButtonColor: "#d03e3e"
            })
        }

    }

    const handlePerform = (Swal) => {
        let data = {
            cId: curElem.id
        }
        dispatch(onDeleteCampaign(data, fetchCampaigns, Swal))
        setInfo({
            ...info,
            enable: false
        })
    }
    const handleOpenVideo = () => {
        navigate(`/campaigns?id=${curElem.id}&cname=${curElem.name}`)
    }


    useEffect(() => {
        setCamp(curElem)
        if (curElem.mode === "auto") {
            let data = {
                id: curElem.id
            }
            dispatch(onFetchAutoVideo(data, noOfVideoAuto, setNoOfVideosAuto))
        }
    }, [curElem])

    return (
        <tr onClick={handleOpenVideo} className='cursor-pointer'>
            <td>
                <span className="proSpace"><img src={vooduuIcon} alt="" /></span>
            </td>
            <td>{camp.name}</td>
            <td className='text-capitalize text-center'>
                {camp.mode}
                <div>
                    {camp.mode === "auto" ?
                        <div className='stat' style={{ background: +noOfVideoAuto.status === 0 ? "#ff9933" : "rgb(22, 126, 215)", display: "inline-flex", width: "auto", alignItems: "center" }}>
                            {
                                +noOfVideoAuto.status === 0 ?
                                    <>
                                        <div className="typing me-1" >
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                        Generating Scenes
                                    </>
                                    :
                                    "Videos Genarated"
                            }
                        </div >
                        : ""
                    }
                </div>
            </td>
            <td >{camp.mode === "auto" ? noOfVideoAuto.videos : camp.noOfVideos}</td>
            <td >{camp.created}</td>
            {/* <td><span className="stat queue">Active</span></td> */}
            {+auth.user.isClient === 1 ? "" :
                <td>
                    {/* <Link className="roundBox" title='View' to={`/campaigns?id=${curElem.id}&cname=${curElem.name}`} ><FaEye /></Link> */}
                    <span className="roundBox" title='Delete' onClick={handleDelete}><FaTrash /></span>
                </td>
            }
            <SweetAlertTwo
                show={info.enable}
                title="Are You Sure?"
                text={`Do you want to delete this campaign!`}
                icon="warning"
                showCancelButton={true}
                confirmButtonColor='#b32f3d'
                cancelButtonColor='#2a2c3a'
                confirmButtonText='Yes, Delete!'
                confirmTitle='Deleting...'
                confirmText="This won't be revert!"
                confirmIcon='success'
                handlePerform={handlePerform}
                onCancel={handleCancel}
            />
        </tr>
    )
}

export default CampaignRows
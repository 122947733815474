import React, { useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs"
import { Link, useLocation, useNavigate } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import modalCloseIcon from "../../images/modal-close.png";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import { onFetchCamp, onSetMetaData, onUpdateCampaign, onUpdateMetaCtaBegin } from "../../Actions/CreateVideoAction";

let interval = false
const ReviewMetaData = () => {

   const dispatch = useDispatch()
   const location = useLocation()
   const navigate = useNavigate()

   const createVideo = useSelector(state => state.createVideo)
   const videoId = queryString.parse(location.search).id

   const [pageLoader, setPageLoader] = useState(true)
   const [saveLoader, setSaveLoader] = useState(false)
   const [progress, setProgress] = useState(0)

   const [cta, setCta] = useState({
      text: "",
      pos: "beginning"
   })
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);

   const handleChange = (e, index) => {
      const { name, value } = e.target
      let arr = [...createVideo.metaData]
      arr[index] = {
         ...arr[index],
         [name]: value
      }
      dispatch(onSetMetaData(arr))
   }

   const handleCta = (e) => {
      const { name, value } = e.target
      setCta({
         ...cta,
         [name]: value
      })
   }


   const handleSubmitCta = (e) => {
      e.preventDefault()
      if (cta.pos === "beginning") {
         dispatch(onUpdateMetaCtaBegin(cta.text, "begin"))

      } else if (cta.pos === "end") {
         dispatch(onUpdateMetaCtaBegin(cta.text, "end"))
      }
      else {
         dispatch(onUpdateMetaCtaBegin(cta.text, cta.pos))
      }
      setCta({
         text: "",
         pos: "beginning"
      })
      handleClose()
   }

   const onGetProgress = () => {
      let prog = 0
      interval = setInterval(() => {
         prog += 1;
         if (prog > 99) {
            clearInterval(interval);
            setProgress(99)
            prog = 0
         } else {
            setProgress(prog)
         }
      }, 50)
   }


   const handleNext = () => {
      let ep = "update-metadata-campaign"
      let data = { metaData: createVideo.metaData, cId: createVideo.id }
      setSaveLoader(true)
      onGetProgress()
      dispatch(onUpdateCampaign(ep, data, navigate, "review-scripts", setSaveLoader, setProgress, interval))
   }

   const fetchCampaign = () => {
      let data = {
         campaignId: videoId
      }
      dispatch(onFetchCamp(data, setPageLoader, navigate))
   }

   useState(() => {
      if (videoId) {
         fetchCampaign()
      }
   }, [videoId])


   return (
      !pageLoader ?
         <>
            <TitleBar title="Create Campaign" />
            <Navbar />
            <section className="siteWrap">
               <div className="siteWrap-in" style={{ paddingTop: "32px" }}>

                  <div className="conatiner-fluid">
                     <div className="row justify-content-center align-items-center">
                        <div className="col-md-11">
                           <div className=" business-heading " style={{ marginBottom: "15px", flexFlow: "wrap", border: "none" }}>
                              <div className="row align-items-center" style={{ width: "100%" }}>
                                 <div className="col-md-6" >
                                    <h2 className="p_space">Review Meta Data</h2>
                                 </div>
                                 <div className="col-md-6 mt-md-0 mt-sm-2 mt-2">
                                    <div className=" d-flex justify-content-end align-items-center">
                                       <Link className="demoLink mt-0" to={`/video-idea?id=${videoId}`} style={{ padding: "14px 32px 14px 10px" }}> <BsArrowLeft />Go To Ideas</Link>
                                       <button onClick={handleNext} className="demoLink ml-2 mt-0" style={{ padding: "14px 16px 14px 32px" }}> Go To Scripts {saveLoader ? <i className="fa fa-spinner fa-spin mx-1" /> : <BsArrowRight />} </button>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="progress" style={{ height: 5, background: "#424b6c", marginBottom: "15px" }}>
                              <div class="progress-bar" role="progressbar" aria-valuenow={progress}
                                 aria-valuemin="0" aria-valuemax="100" style={{ width: `${progress}%`, background: "#22a0eb" }}>
                              </div>
                           </div>
                           <div className="text-end" style={{ paddingRight: "29px" }}>
                              <button
                                 className="demoLink mb-4 mt-0"
                                 onClick={handleShow}
                                 disabled={createVideo.metaData.length === 0}
                                 style={{ background: createVideo.metaData.length > 0 ? "orange" : "gray" }}
                              >
                                 CTA
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="container">

                     <div className="row justify-content-center">
                        <div className="col-lg-12">
                           <div className="site-accordion">
                              <Accordion defaultActiveKey="0">
                                 {createVideo.metaData.length > 0 ?
                                    createVideo.metaData.map((curElem, index) => {
                                       return (
                                          <React.Fragment key={index}>
                                             <Accordion.Item eventKey={index}>
                                                <Accordion.Header>Video #{index + 1}</Accordion.Header>
                                                <Accordion.Body>
                                                   <div className="inp-group mt-0">
                                                      <label htmlFor="">Title</label>
                                                      <input
                                                         className="simple-inp"
                                                         type="text"
                                                         placeholder="Add Title"
                                                         name="title"
                                                         value={curElem.title}
                                                         onChange={(e) => handleChange(e, index)}
                                                      />
                                                   </div>
                                                   <div className="inp-group">
                                                      <label htmlFor="">Description</label>
                                                      <textarea
                                                         className="simple-inp"
                                                         placeholder="Add Description"
                                                         name="description"
                                                         value={curElem.description}
                                                         onChange={(e) => handleChange(e, index)}
                                                      />
                                                   </div>
                                                   <div className="inp-group">
                                                      <label htmlFor="">Tags</label>
                                                      <input
                                                         className="simple-inp"
                                                         type="text"
                                                         name="tags"
                                                         placeholder="Add Tags"
                                                         value={curElem.tags}
                                                         onChange={(e) => handleChange(e, index)}
                                                      />
                                                   </div>
                                                </Accordion.Body>
                                             </Accordion.Item>
                                          </React.Fragment>
                                       )
                                    })

                                    : ""}
                              </Accordion>
                           </div>
                        </div>
                     </div>

                  </div>
               </div>
            </section>
            <Footer />

            <Modal className="VideoModal dark" show={show} onHide={handleClose} centered>
               <Modal.Body>
                  <div onClick={handleClose} className="vidClose">
                     <img alt="" src={modalCloseIcon} />
                  </div>
                  <form onSubmit={handleSubmitCta}>
                     <div className="inp-group alt mt-0">
                        <label htmlFor="">Text</label>
                        <textarea
                           className="simple-inp"
                           type="text"
                           name="text"
                           placeholder="Add Text"
                           value={cta.text}
                           onChange={handleCta}
                           required
                        />
                     </div>
                     <div className="inp-group alt">
                        <label htmlFor="">Location</label>
                        <select
                           className="simple-inp"
                           name="pos"
                           value={cta.pos}
                           onChange={handleCta}
                           required
                        >
                           <option value="beginning">Beginning</option>
                           <option value="end">End</option>
                           <option value="b&e">Beginning & End</option>
                        </select>
                     </div>
                     <div className="text-right foot-button">
                        <button className="demoLink ml-2" type="submit">Apply</button>
                     </div>
                  </form>
               </Modal.Body>
            </Modal>

         </>
         : <div className="loader-sec">
            <div className="loader">
            </div>
         </div>
   )
}
export default ReviewMetaData;
import React, { useState } from 'react'
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import { GoDesktopDownload } from "react-icons/go"
import { onDeleteDesign } from '../../Actions/DesignActions';
import { useDispatch } from 'react-redux';
import SweetAlertTwo from '../SweetAlertTwo';

const DesignTableRows = ({ curElem, fetchTableData }) => {
    const dispatch = useDispatch()
    const [info, setInfo] = useState({
        id: -1,
        enable: false
    })

    const handleCancle = () => {
        setInfo({
            ...info,
            id: -1,
            enable: false
        })
    }

    const onDelete = (id) => {
        setInfo({
            ...info,
            id: id,
            enable: true
        })
    }
    const deleteDesign = (Swal) => {
        let data = { id: curElem.id }
        setInfo({
            ...info,
            id: -1,
            enable: false
        })
        dispatch(onDeleteDesign(data, fetchTableData, Swal))
    }
    const handleDownload = (url) => {
        let a = document.createElement('a')
        a.href = url
        a.type = "download"
        a.click()
    }

    return (
        <tr>
            <td>
                <span className="proSpace"><img src={curElem.url} alt="" /></span>
            </td>
            <td className='text-capitalize'>{curElem.name}</td>
            <td className='text-capitalize'>{curElem.type}</td>
            <td><span className="stat" style={{ background: +curElem.status === 0 ? "#167ed7" : "#73a90c" }}>{+curElem.status === 0 ? "Awaiting Customization " : "Completed"}</span></td>
            <td>
                <Link className="roundBox" to={`/image-editor?id=${curElem.id}&type=edit`} title='Edit'><FiEdit /></Link>
                <span className="roundBox" title='Delete' onClick={onDelete}><FiTrash2 /></span>
                {/* <span className="roundBox" title='Download' onClick={() => handleDownload(curElem.url)}><GoDesktopDownload /></span> */}
            </td>

            <SweetAlertTwo
                show={info.enable}
                title="Are You Sure?"
                text="You won't be able to revert this!"
                icon="warning"
                showCancelButton={true}
                confirmButtonColor='#b32f3d'
                cancelButtonColor='#2a2c3a'
                confirmButtonText='Yes, delete it!'
                confirmTitle='Deleting...'
                confirmText="This won't be revert!"
                confirmIcon='success'
                handlePerform={deleteDesign}
                onCancel={handleCancle}
            />
        </tr>
    )
}

export default DesignTableRows
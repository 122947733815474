import produce from "immer"
const initialState = {
    id: false,
    channel: [],
    name: "",
    mode: "manual",
    language: "",
    country: "",
    niche: {
        searchTerm: "",
        results: [],
        selected: ""
    },
    videoIdeas: {
        newIdeas: [],
        selectedIdeas: []
    },
    metaData: [],
    scripts: [],
    publishing: [],
    thumbnail: [],
    videos: []
}
export const CreateVideoReducer = (state = initialState, action) => {
    switch (action.type) {

        case "ON_ADD_CAMP_NAME":
            return produce(state, (draft) => {
                draft.name = action.payload
            })
        case "ON_SELECT_CHANNEL":
            return produce(state, (draft) => {
                draft.channel = action.payload
            })

        case "ON_SELECT_MODE":
            return produce(state, (draft) => {
                draft.mode = action.payload
            })

        case "ON_SET_CAMP_DATA":
            let obj = { ...action.payload }
            if (!obj.niche) {
                obj.niche = {
                    searchTerm: "",
                    results: [],
                    selected: "",
                }
            }

            return obj

        case "ON_CHANGE_NICHE_DATA":
            return produce(state, (draft) => {
                draft.niche = {
                    ...draft.niche,
                    [action.payload.name]: action.payload.value
                }
            })

        case "ON_ADD_IDEAS":
            return produce(state, (draft) => {
                draft.videoIdeas.selectedIdeas = action.payload
            })

        case "ADD_MORE_NEW_IDEAS":
            return produce(state, (draft) => {
                draft.videoIdeas.newIdeas = draft.videoIdeas.newIdeas.concat(action.payload)
            })

        case "ON_SELECT_LANGUAGE":
            return produce(state, (draft) => {
                draft.language = action.payload
            })

        case "ON_SELECT_COUNTRY":
            return produce(state, (draft) => {
                draft.country = action.payload
            })
        case "ON_SET_META":
            return produce(state, (draft) => {
                draft.metaData = action.payload
            })

        case "UPDATE_META_CTA_BEGIN":
            return produce(state, (draft) => {
                draft.metaData.forEach((curElem) => {
                    curElem.description = action.payload + "\n" + (curElem.description ? curElem.description : "")
                })
            })

        case "UPDATE_META_CTA_END":
            return produce(state, (draft) => {
                draft.metaData.forEach((curElem) => {
                    curElem.description = (curElem.description ? curElem.description : "") + "\n" + action.payload
                })
            })


        case "UPDATE_SCRIPT_TEXT":
            return produce(state, (draft) => {
                draft.scripts[action.payload.index].script = action.payload.text
            })

        case "UPDATE_TALKINGHEAD_IMAGE":
            return produce(state, (draft) => {
                draft.faceNarration.media.url = action.payload.path
                draft.faceNarration.media.type = action.payload.type
            })

        case "ON_TOGGLE_TALKING_HEAD":
            return produce(state, (draft) => {
                draft.faceNarration.enable = action.payload
            })
        case "ON_TOGGLE_HEAD_MOVEMENT":
            return produce(state, (draft) => {
                draft.faceNarration.headMovement = action.payload
            })
        case "ON_UPDATE_COVERAGE":
            return produce(state, (draft) => {
                draft.faceNarration.coverage = action.payload
            })

        case "ON_CHANGE_PUBLISH":
            return produce(state, (draft) => {
                if (action.payload.type === "yt") {
                    draft.publishing[action.payload.index].youtube = {
                        ...draft.publishing[action.payload.index].youtube,
                        [action.payload.name]: action.payload.value
                    }
                    draft.publishing[action.payload.index].facebook = {
                        ...draft.publishing[action.payload.index].facebook,
                        [action.payload.name]: action.payload.value
                    }
                }
                if (action.payload.type === "fb") {
                    draft.publishing[action.payload.index].facebook = {
                        ...draft.publishing[action.payload.index].facebook,
                        [action.payload.name]: action.payload.value
                    }
                }
            })

        case "ON_SET_AUTO_DT":

            return produce(state, (draft) => {
                draft.publishing.forEach((curElem) => {
                    if (curElem.facebook.enable) {
                        curElem.facebook.publishData = action.payload
                    }
                    if (curElem.youtube.enable) {
                        curElem.youtube.publishData = action.payload
                    }
                    if (curElem.instagram.enable) {
                        curElem.instagram.publishData = action.payload
                    }
                })
            })

        case "ON_UPDATE_THUMB":
            return produce(state, (draft) => {
                draft.thumbnail[action.payload.index].thumbnail = action.payload.url
                draft.thumbnail[action.payload.index].data = ""
            })

        case "ON_REMOVE_FROM_SAVED":
            return produce(state, (draft) => {
                draft.videoIdeas.newIdeas.splice(action.payload, 1)
            })

        case "ON_USE_EDITOR_THUMB":
            return produce(state, (draft) => {
                draft.thumbnail[action.payload.usedIndex].data = action.payload.data
                draft.thumbnail[action.payload.usedIndex].thumbnail = action.payload.thumbnail
            })

        case "SET_THUMBNAIL":
            return produce(state, (draft) => {
                draft.thumbnail[action.payload.thumbIndex].thumbnail = action.payload.da
            })

        /**REVIEW SCRIPT PAGE ~ Rehan */
        case "ADD_FACE_NARRATION_DATA":

            return produce(state, (draft) => {
                draft.scripts[action.payload.index].faceNarration = { ...draft.scripts[action.payload.index].faceNarration, addFaceNarrationData: action.payload.addFaceNarrationData };
            })
        case "SAVE_UPLOAD_IMAGE":
            return produce(state, (draft) => {
                draft.scripts[action.payload.index].faceNarration.media.url = action.payload.image;
            })

        case "INCLUDE_HEAD_MOVEMENT":
            return produce(state, (draft) => {
                draft.scripts[action.payload.index].faceNarration.headMovement = action.payload.isChecked
            })

        case "UPDATE_COVERAGE":
            return produce(state, (draft) => {
                draft.scripts[action.payload.index].faceNarration.coverage = action.payload.value;
            })
        case "TOGGLE_FACE_NARRATION":
            return produce(state, (draft) => {
                draft.scripts[action.payload.index].faceNarration.enable = action.payload.toggle
            })
        default:
            return state
    }
}
import React, { useState } from 'react'
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa'

const TopNichesVideosHeader = ({ topVideos, setTopVideos }) => {
    const [state, setState] = useState({
        views: "",
        likes: "",
        comments: ""
    })

    const handleSort = (type) => {
        const data = [...topVideos]
        if (type === "views") {
            if (state.views) {
                data.sort((a, b) => {
                    if (Number(a.stats.views.toLowerCase()) < Number(b.stats.views.toLowerCase())) {
                        return 1;
                    } else {
                        return -1;
                    }
                });
                setState({
                    ...state,
                    views: false,
                });
            } else {
                data.sort((a, b) => {
                    if (Number(a.stats.views.toLowerCase()) > Number(b.stats.views.toLowerCase())) {
                        return 1;
                    } else {
                        return -1;
                    }
                });
                setState({
                    ...state,
                    views: true,
                });
            }
        }
        if (type === "likes") {
            if (state.likes) {
                data.sort((a, b) => {
                    if (Number(a.stats.like.toLowerCase()) < Number(b.stats.like.toLowerCase())) {
                        return 1;
                    } else {
                        return -1;
                    }
                });
                setState({
                    ...state,
                    likes: false,
                });
            } else {
                data.sort((a, b) => {
                    if (Number(a.stats.like.toLowerCase()) > Number(b.stats.like.toLowerCase())) {
                        return 1;
                    } else {
                        return -1;
                    }
                });
                setState({
                    ...state,
                    likes: true,
                });
            }
        }
        if (type === "comments") {
            if (state.comments) {
                data.sort((a, b) => {
                    if (Number(a.stats.comments?.toLowerCase()) < Number(b.stats.comments?.toLowerCase())) {
                        return 1;
                    } else {
                        return -1;
                    }
                });
                setState({
                    ...state,
                    comments: false,
                });
            } else {
                data.sort((a, b) => {
                    console.log(a, b, "-------->")

                    if (Number(a.stats.comments?.toLowerCase()) > Number(b.stats.comments?.toLowerCase())) {
                        return 1;
                    } else {
                        return -1;
                    }
                });
                setState({
                    ...state,
                    comments: true,
                });
            }
        }
        setTopVideos(data)
    }

    return (
        <thead style={{ position: "sticky", top: "-10px", zIndex: "1" }}>
            <tr>
                <th className="text-left">Videos</th>
                <th onClick={() => handleSort("views")} className='cursor-pointer'>Views <span> {state.views === "" ? <FaSort /> : state.views ? <FaSortDown /> : <FaSortUp />}</span></th>
                <th onClick={() => handleSort("likes")} className='cursor-pointer'>Likes <span>{state.likes === "" ? <FaSort /> : state.likes ? <FaSortDown /> : <FaSortUp />}</span></th>
                <th onClick={() => handleSort("comments")} className='cursor-pointer'>Comments <span>{state.comments === "" ? <FaSort /> : state.comments ? <FaSortDown /> : <FaSortUp />}</span></th>
            </tr>
        </thead>
    )
}

export default TopNichesVideosHeader

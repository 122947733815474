import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { onfetchAiImage } from '../../Actions/DesignActions';

const AiImageSearch = ({ addImage, canvas }) => {

    const dispatch = useDispatch();
    const [msg, setMsg] = useState("")
    const [checkImage, setCheckImage] = useState('');
    const [image, setImage] = useState({
        keyword: "",
        imageData: ""
    })

    const [loader, setLoader] = useState(false);

    const onImageClick = (canvas) => {
        setCheckImage(true);
        addImage(canvas, image.imageData);
    }

    const handleSearch = (val) => {
        setImage({
            ...image,
            keyword: val
        })
        if (msg !== "") {
            setMsg("")
        }
        setCheckImage(false)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setImage({ ...image, imageData: "" })
        setLoader(image.keyword === "" ? false : true)
        e.preventDefault()
        let data = {
            prompts: image.keyword
        }
        dispatch(onfetchAiImage(data, image, setImage, setLoader))
    }


    return (
        <>
            <form method="post" onSubmit={(e) => handleSubmit(e)} className="search-img">
                <div className="form-group has-search">
                    <input type="text" className="form-control" onChange={(e) => handleSearch(e.target.value)} placeholder="Search" />
                    <span className="fa fa-search form-control-feedback" />
                    <button type="submit" style={{ display: "none" }}></button>
                </div>
            </form>
            <div className="alert alert-info mt-4" style={{ fontSize: "small", padding: "5px" }}>
                <strong>NOTE:</strong> AI can take upto 2-3 minutes for content to be generated.
            </div>
            {loader ? <h6 style={{ width: "100%" }} className="m-0 text-center"><i style={{ fontSize: "40px", color: "rgb(37 170 241)" }} className="fa fa-spinner fa-spin mr-2" /></h6> : ""}
            <div className="row">
                <div className="col-md-4" style={{ marginBottom: "10px" }}>
                    <div className="Ai-img-part cursor-pointer" style={{ position: "relative" }} >
                        {image.imageData !== "" ?
                            <img crossOrigin="anonymous" src={image.imageData} className="img-fluid" onClick={(e) => onImageClick(canvas)} alt />
                            : ""
                        }
                        {
                            checkImage ? <span className="checked-icon" style={{
                                color: "rgb(37 170 241)",
                                top: "34%",
                                left: "40%",
                                fontSize: 25,
                                position: "absolute",
                                maxWidth: "100%",
                                height: "auto"
                            }}><i className="far fa-check-circle"></i></span> : ''
                        }
                    </div>
                </div>

            </div>

        </>
    )
}

export default AiImageSearch;
import React, {  useState } from 'react';
import { useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { onSearchMedia } from '../../Actions/contentAction';

const ImageSearch = ({ addImage, canvas }) => {

    const dispatch = useDispatch();
    const [msg, setMsg] = useState("")
    const [checkImage, setCheckImage] = useState('');
    const [imageList, setImageList] = useState([])
    const [image, setImage] = useState({
        keyword: "",
        page_number: 1,
        search_type: "image"
    })

    const [loader, setLoader] = useState(false);

    const onImageClick = (canvas, url, index) => {
        setCheckImage(index);
        addImage(canvas, url);
    }

    const fetchImages = (page = 1) => {
        setLoader(true)
        dispatch(onSearchMedia(image, imageList, setImageList, setLoader, image, setImage, page, setMsg))
    }

    const handleSearch = (val) => {
        setImage({
            ...image,
            keyword: val,
            page_number: 1
        })
        setImageList([])
        if (msg !== "") {
            setMsg("")
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        fetchImages()
    }


    return (
        <>
            <form onSubmit={(e) => handleSubmit(e)} className="search-img">
                <div className="form-group has-search">
                    <input type="text" className="form-control" onChange={(e) => handleSearch(e.target.value)} placeholder="Search" />
                    <span className="fa fa-search form-control-feedback" />
                    <button type="submit" style={{ display: "none" }}></button>
                </div>
            </form>
            <div id="scrollableImage" className="mt-2" style={{ overflowX: "hidden", overflowY: "auto", maxHeight: "256px" }}>
                <InfiniteScroll
                    dataLength={imageList.length} //This is important field to render the next data
                    next={(e) => fetchImages(image.page_number)}
                    hasMore={imageList.length > 0 ? true : false}
                    scrollableTarget="scrollableImage"
                    className="align-loader"
                >
                    <div className="row">
                        {
                            imageList.length > 0 ?
                                imageList.map((item, index) => {
                                    return (
                                        <div className="col-md-4" key={index} style={{ marginBottom: "10px" }}>
                                            <div className="img-part cursor-pointer" style={{ position: "relative" }}>
                                                <img crossOrigin="anonymous" width="100%" src={item.thumbnail} className="img-fluid" onClick={(e) => onImageClick(canvas, item.url, index)} alt />
                                                {
                                                    checkImage === index ? <div className="checked-icon" style={{
                                                        color: "#1882d9",
                                                        top: 0,
                                                        fontSize: '25px',
                                                        position: 'absolute',
                                                        height: "100%",
                                                        width: "100%",
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}><i className="far fa-check-circle"></i></div> : ''
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <div className="col-md-12 text-center">
                                    {msg}
                                </div>

                        }
                        {loader ? <h6 style={{ width: "100%" }} className="m-0 text-center"><i style={{ fontSize: "30px", }} className="fa fa-spinner fa-spin mr-2" /></h6> : ""}
                    </div>
                </InfiniteScroll>

            </div>
        </>
    )
}

export default ImageSearch;
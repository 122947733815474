import React, { useState } from 'react';
import { AiTwotoneEdit } from 'react-icons/ai'
import { CgArrowsExchange } from 'react-icons/cg'
import { BiUpload } from "react-icons/bi"
import ThumbModal from '../ThumbModal';
import UploadModal from '../UploadModal';
import ImageEditor from '../../Design/ImageEditor';
import { onEditThumbnail, onSubmitAuto } from '../../../Actions/CreateVideoAction';
import { useDispatch } from 'react-redux';
import VideoTempModal from '../VideoTempModal';
import { BsArrowRight } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

const AutoCreateTwo = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [show, setShow] = useState(false)
    const [show1, setShow1] = useState(false)
    const [show2, setShow2] = useState(false)

    const [loader, setLoader] = useState(false)
    const [activeEditor, setActiveEditor] = useState(false)

    const handleShow = () => setShow(true)
    const handleShow1 = () => setShow1(true)
    const handleShow2 = () => setShow2(true)
    const handleClose = () => setShow(false)
    const handleClose1 = () => setShow1(false)
    const handleClose2 = () => setShow2(false)

    const handleEdit = () => {
        setActiveEditor(true)
        let data
        if (props.state.thumbnail.data) {
            data = {
                type: "thumbnail",
                data: props.state.thumbnail.data
            }
        } else {
            data = {
                type: "thumbnail",
                data: {
                    "version": "5.3.0",
                    "objects": [
                        {
                            "type": "image",
                            "version": "5.3.0",
                            "originX": "left",
                            "originY": "top",
                            "left": 183,
                            "top": 86.7,
                            "width": 1280,
                            "height": 853,
                            "fill": "rgb(0,0,0)",
                            "stroke": null,
                            "strokeWidth": 0,
                            "strokeDashArray": null,
                            "strokeLineCap": "butt",
                            "strokeDashOffset": 0,
                            "strokeLineJoin": "miter",
                            "strokeUniform": false,
                            "strokeMiterLimit": 4,
                            "scaleX": 0.2,
                            "scaleY": 0.2,
                            "angle": 0,
                            "flipX": false,
                            "flipY": false,
                            "opacity": 1,
                            "shadow": null,
                            "visible": true,
                            "backgroundColor": "",
                            "fillRule": "nonzero",
                            "paintFirst": "fill",
                            "globalCompositeOperation": "source-over",
                            "skewX": 0,
                            "skewY": 0,
                            "cropX": 0,
                            "cropY": 0,
                            "src": props.state.thumbnail.thum + "?not-from-cache-please",
                            "crossOrigin": "*",
                            "filters": []
                        }
                    ],
                    "background": "white"
                }
            }
            data.data = JSON.stringify(data.data)
        }
        dispatch(onEditThumbnail(data))
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        props.setState({
            ...props.state,
            cta: {
                ...props.state.cta,
                [name]: value
            }
        })
    }

    const handleSubmit = () => {
        setLoader(true)
        dispatch(onSubmitAuto(props.state, setLoader, navigate))
    }


    return (

        activeEditor ?
            <ImageEditor
                setActiveEditor={setActiveEditor}
                activeEditor={activeEditor}
                type="auto"
                state={props.state}
                setState={props.setState}
            /> :

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">

                        <div className="Auto_create_wrapper">
                            <section className="connection">
                                <div className="integr-wrap pt-0">
                                    <h4> Thumbnail Template</h4>
                                </div>
                            </section>

                            <div className="col-xl-3 col-md-6 col-sm-12">
                                <div className="autoProject_wrap ">

                                    <div className="auto_videoProject_temp">
                                        <div className="video-card-wrapper-1 ">
                                            <img src={props.state.thumbnail.thum} alt="" style={{ height: "100%", width: "100%" }} />
                                        </div>
                                        <div className="vidHover Auto_create_icon" >
                                            <div className="vidiconAll">
                                                <div
                                                    className="vidHover-single cursor-pointer"
                                                    onClick={handleEdit}
                                                >
                                                    <a>
                                                        <span className="vidHover-icon"><AiTwotoneEdit /></span>
                                                        <span style={{ color: "#fff" }}>Edit</span>
                                                    </a>
                                                </div>
                                                <div
                                                    className="vidHover-single cursor-pointer"
                                                    onClick={handleShow1}
                                                >
                                                    <a>
                                                        <span className="vidHover-icon"><BiUpload /></span>
                                                        <span style={{ color: "#fff" }}>Upload</span>
                                                    </a>
                                                </div>
                                                <div
                                                    className="vidHover-single cursor-pointer"
                                                    onClick={handleShow}
                                                >
                                                    <a>
                                                        <span className="vidHover-icon">
                                                            <CgArrowsExchange />
                                                        </span><span style={{ color: "#fff" }}>Change</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <section className="connection">
                                <div className="integr-wrap pt-0">
                                    <h4> Video Template</h4>
                                </div>
                            </section>

                            <div className="col-xl-3 col-md-6 col-sm-12">
                                <div className="autoProject_wrap">

                                    <div className="auto_videoProject_temp">
                                        <div className="video-card-wrapper-1 ">
                                            <img src={props.state.videoTemplate.image} alt='' style={{ height: "100%", width: "100%" }} />
                                        </div>
                                        <div className="vidHover Auto_create_icon">
                                            <div className="vidiconAll">
                                                <div
                                                    className="vidHover-single cursor-pointer"
                                                    onClick={handleShow2}
                                                >
                                                    <a>
                                                        <span className="vidHover-icon"><CgArrowsExchange /></span>
                                                        <span style={{ color: "#fff" }}>Change</span>
                                                    </a>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <section className="connection">
                                <div className="integr-wrap pt-0">
                                    <h4> CTA</h4>
                                </div>
                            </section>

                            <div className="col-sm-12 mt-2">
                                <div
                                >
                                    <div className="inp-group alt mt-0">
                                        <label htmlFor="" style={{ fontSize: "15px" }}>Text</label>
                                        <textarea
                                            className="simple-inp"
                                            type="text"
                                            name="text"
                                            placeholder="Add Text"
                                            value={props.state.cta.text}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="inp-group alt">
                                        <label htmlFor="" style={{ fontSize: "15px" }}>Location</label>
                                        <select
                                            className="simple-inp"
                                            name="pos"
                                            value={props.state.cta.pos}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="beginning">Beginning</option>
                                            <option value="end">End</option>
                                            <option value="b&e">Beginning & End</option>
                                        </select>
                                    </div>

                                </div>

                                <div className="auto_nich_btn text-end mt-2">
                                    <button
                                        className="demoLink ml-2 mt-0"
                                        style={{ padding: "14px 16px 14px 32px" }}
                                        type='button'
                                        onClick={handleSubmit}
                                        disabled={loader}
                                    > Next
                                        {loader ? <i className="fa fa-spinner fa-spin mx-1" /> : <BsArrowRight />}
                                    </button>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>

                <ThumbModal
                    show={show}
                    handleClose={handleClose}
                    setState={props.setState}
                    state={props.state}
                    type="auto"
                    tempId={props.state.thumbnail.id}
                />

                <UploadModal
                    show={show1}
                    handleClose={handleClose1}
                    setState={props.setState}
                    state={props.state}
                    type="auto"
                />

                <VideoTempModal
                    show={show2}
                    handleClose={handleClose2}
                    setData={props.setState}
                    data={props.state}
                    type="auto"
                // fetchCampaign={fetchCampaign}
                />
            </div>
    );
}

export default AutoCreateTwo;

import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { onFetchTransition } from '../../../Actions/contentAction';
import TransCards from './TransCards';
const Transition = ({ slideData, transActive }) => {

    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const selectedSlide = slideData.find(({ isSelected }) => isSelected === "1")
    const selectedSlideIndex = slideData.findIndex(({ isSelected }) => isSelected === "1")

    const [data, setData] = useState([])
    const [loader, setLoader] = useState(false)
    const [memberShip, setMemberShip] = useState([])
    const fetchTransition = () => {
        setLoader(true)
        dispatch(onFetchTransition(setData, setLoader, memberShip))
    }

    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split("__"))
        }
    }, [auth.user])

    useEffect(() => {
        if (transActive) {
            fetchTransition()
        }
    }, [transActive, memberShip])

    return (
        <div className="tabInner">
            <div className="media-block">
                <div className="add-block-single">
                    <h2>Transition</h2>
                    <div className="add-block-main">
                        <div className="motionlist">
                            <div className='mediaList mt-3 text-center'>
                                <ul>
                                    {data.length > 0 ?
                                        data.map((curElem, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <TransCards
                                                        curElem={curElem}
                                                        selectedSlide={selectedSlide}
                                                        selectedSlideIndex={selectedSlideIndex}
                                                    />
                                                </React.Fragment>
                                            )
                                        }) : ""
                                    }
                                </ul>
                                {loader ?
                                    <i className="fa fa-spinner fa-spin mx-1" style={{ fontSize: 25 }} />
                                    : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Transition


import React, { useState } from 'react'
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa'

const DashboardSectionHeader = ({ keyword, setKeyword }) => {

    const [state, setState] = useState({
        searchVol: "",
        competition: "",
        overAll: ""
    })

    const handleSort = (type) => {
        const data = [...keyword]
        if (type === "searchVol") {
            if (state.searchVol) {
                data.sort((a, b) => {
                    if (Number(a.volume.toLowerCase()) < Number(b.volume.toLowerCase())) {
                        return 1;
                    } else {
                        return -1;
                    }
                });
                setState({
                    ...state,
                    searchVol: false,
                });
            } else {
                data.sort((a, b) => {
                    if (Number(a.volume.toLowerCase()) > Number(b.volume.toLowerCase())) {
                        return 1;
                    } else {
                        return -1;
                    }
                });
                setState({
                    ...state,
                    searchVol: true,
                });
            }
        }
        if (type === "competition") {
            if (state.competition) {
                data.sort((a, b) => {
                    if (Number(a.competition.toLowerCase()) < Number(b.competition.toLowerCase())) {
                        return 1;
                    } else {
                        return -1;
                    }
                });
                setState({
                    ...state,
                    competition: false,
                });
            } else {
                data.sort((a, b) => {
                    if (Number(a.competition.toLowerCase()) > Number(b.competition.toLowerCase())) {
                        return 1;
                    } else {
                        return -1;
                    }
                });
                setState({
                    ...state,
                    competition: true,
                });
            }
        }
        if (type === "overAll") {
            if (state.overAll) {
                data.sort((a, b) => {
                    if (Number(a.overall.toLowerCase()) < Number(b.overall.toLowerCase())) {
                        return 1;
                    } else {
                        return -1;
                    }
                });
                setState({
                    ...state,
                    overAll: false,
                });
            } else {
                data.sort((a, b) => {
                    if (Number(a.overall.toLowerCase()) > Number(b.overall.toLowerCase())) {
                        return 1;
                    } else {
                        return -1;
                    }
                });
                setState({
                    ...state,
                    overAll: true,
                });
            }
        }
        setKeyword(data)
    }

    return (
        <thead style={{ position: "sticky", top: "-10px", zIndex: "1" }}>
            <tr>
                <th className="text-left">Keywords</th>
                <th onClick={() => handleSort("competition")} className='cursor-pointer'>Competition <span>{state.competition === "" ? <FaSort /> : state.competition ? <FaSortDown /> : <FaSortUp />}</span></th>
                <th onClick={() => handleSort("searchVol")} className='cursor-pointer'>Search Volume <span>{state.searchVol === "" ? <FaSort /> : state.searchVol ? <FaSortDown /> : <FaSortUp />}</span></th>
                <th onClick={() => handleSort("overAll")} className='cursor-pointer'>Bid($) <span>{state.overAll === "" ? <FaSort /> : state.overAll ? <FaSortDown /> : <FaSortUp />}</span></th>
                <th>
                </th>
            </tr>
        </thead >
    )
}

export default DashboardSectionHeader
import { combineReducers } from "redux";
import { AlertReducer } from "./AlertReducer";
import { AuthReducer } from "./AuthReducer";
import { RebrandReducer } from "./RebrandReducer";
import { SupportReducer } from "./SupportReducer";
import contentReducer from "./contentReducer";
import { SocialReducer } from "./SocialReducer";
import { DesignReducer } from "./DesignReducer";
import { CreateVideoReducer } from "./CreateVideoReducer";
import { ChatReducer } from "./ChatReducer";

const appReducer = combineReducers({
    alert: AlertReducer,
    auth: AuthReducer,
    support: SupportReducer,
    rebrand: RebrandReducer,
    content: contentReducer,
    social: SocialReducer,
    design: DesignReducer,
    createVideo: CreateVideoReducer,
    chat: ChatReducer
})

const rootReducers = (state, action) => {
    if (action.type === 'LOGOUT') {
        localStorage.clear();
        state = undefined
    }
    return appReducer(state, action)
}

export default rootReducers;
import React, { useState } from 'react';
import { Tab, Nav } from "react-bootstrap";
import AiVoice from './AiVoice';
import Recording from './Recording';
import UploadVoice from './UploadVoice';

const VoiceOver = ({ slideData, setDeactiveButton }) => {
    const selectedSlideIndex = slideData.findIndex(({ isSelected }) => isSelected === "1")
    const [active, setActive] = useState({
        sonority: false,
        recording: false
    })

    const handleActive = (type) => {
        if (type === "sonority" && active.sonority === false) {
            setActive({
                ...active,
                sonority: true,

            })
        }
        else if (type === "recording" && active.recording === false) {
            setActive({
                ...active,
                recording: true
            })
        }
    }


    return (
        <div className="tabInner">
            <div className="media-block">
                <Tab.Container id="left-tabs-example-2" defaultActiveKey="voice-tab">
                    <Nav variant="pills" className="inTabNav full">
                        <Nav.Item>
                            <Nav.Link eventKey="voice-tab">AI Voices</Nav.Link>
                        </Nav.Item>
                       
                        <Nav.Item>
                            <Nav.Link eventKey="upload-audio">Upload Voiceover</Nav.Link>
                        </Nav.Item>
                        <Nav.Item onClick={() => handleActive("recording")}>
                            <Nav.Link eventKey="recording-tab">Recording</Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <Tab.Content>
                        <Tab.Pane eventKey="voice-tab">
                            <AiVoice
                                slideData={slideData}
                                setDeactiveButton={setDeactiveButton}
                            />
                        </Tab.Pane>
                       
                        <Tab.Pane eventKey="upload-audio">
                            <UploadVoice
                                selectedSlideIndex={selectedSlideIndex}
                                type="voiceover"
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="recording-tab">
                            <Recording
                                selectedSlideIndex={selectedSlideIndex}
                                active={active}
                            />
                        </Tab.Pane>


                    </Tab.Content>

                </Tab.Container>
            </div>
        </div>
    )
}

export default VoiceOver
import React from "react";
import aws from "../../images/AWS.png"
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import AmazonPopup from "./AmaoznPopup";

const ConnectAmazon = (props) => {
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)
    return (
        <>
            <div className="integr-single" id="youtube">

                <div className="connection-box-main">
                    <div className={`connection-img disable-img`}>
                        <img src={aws} width="56px" alt="youtube" />
                    </div>

                    <div className="connection-txt">
                        <div className="noConnect">
                            <div className="youbtube-connection text-white d-flex align-items-center  justify-content-between">
                                <div className="youtube-content">
                                    <h6>AWS</h6>
                                    <p className="m-0">Connect AWS</p>
                                </div>
                            </div>
                            <div className="facebook-connect-account">
                                <button className="btn-change7" onClick={handleShow}>Connect</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AmazonPopup
                show={show}
                handleClose={handleClose}
                fetchIntegration={props.fetchIntegration}
            />
        </>
    )
}

export default ConnectAmazon;
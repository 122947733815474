import { commonAxios } from "../Global/CommonAxios"
import { setAlert } from "./AlertAction"

export const onFetchCountries = (setState) => (dispatch, getState) => {
    commonAxios("countries", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data.reverse())
            }
        }).catch((err) => {
            console.log(err)
        })
}

// export const onFetchNiches = (setState, loader, setLoader) => (dispatch, getState) => {
//     commonAxios("fetch-niches", {}, dispatch, getState().auth.token)
//         .then((res) => {
//             if (res.status) {
//                 setState(res.data)
//             }else{
//                 setState([])
//             }
//             setLoader({
//                 ...loader,
//                 fetch: false
//             })
//         }).catch((err) => {
//             setLoader({
//                 ...loader,
//                 fetch: false
//             })
//             console.log(err)
//         })
// }
export const onFetchKeywords = (setState) => (dispatch, getState) => {
    commonAxios("fetch-keywords", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onGetMediaInfo = (state, setState) => (dispatch, getState) => {
    commonAxios("fetch-image-video", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState({
                    ...state,
                    image: res.data[0].image,
                    video: res.data[0].video
                })
            }
        }).catch((err) => {
            console.log(err)
        })
}


export const onFetchUserNiches = (setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-user-niches", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let data = res.data.sort((a, b) => {
                    if (a.is_selected && !b.is_selected) {
                        return -1;
                    } else if (!a.is_selected && b.is_selected) {
                        return 1;
                    } else {
                        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                    }
                })
                setState(data)
            } else {
                setState([])
            }
            setLoader({
                ...loader,
                niche: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                niche: false
            })
            console.log(err)
        })
}

export const onFetchNiche = (setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-niches", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let data = res.data.sort((a, b) => {
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1
                    } else {
                        return -1
                    }
                })
                setState(data)
            } else {
                setState([])
            }
            setLoader({
                ...loader,
                niche: false,
                submitLoader: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                niche: false,
                submitLoader: false
            })
            console.log(err)
        })
}

export const onAddNiche = (data, fetchNiche, handleClose, setLoader) => (dispatch, getState) => {
    commonAxios("add-user-niches", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchNiche()
                handleClose()
                dispatch(setAlert(res.msg, "success"))
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            console.log(err)
        })
}

export const onFetchTopVideos = (setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-top-videos", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            }
            else {
                setState([])
            }
            setLoader({
                ...loader,
                topVideoLoader: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                topVideoLoader: false
            })
            console.log(err)
        })
}
export const onFetchUserKeyword = (setState) => (dispatch, getState) => {
    commonAxios("fetch-user-keywords", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = res.data.sort((a, b) => {
                    if (a.is_like === "1" && b.is_like === "0") {
                        return -1;
                    } else if (a.is_like === "0" && b.is_like === "1") {
                        return 1;
                    } else {
                        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                    }
                })
                setState(arr)
            }
            else {
                setState([])
            }
        }).catch((err) => {
            console.log(err)
        })
}
export const onFetchUserIdeas = (setState) => (dispatch, getState) => {
    commonAxios("fetch-user-dailyideas", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = res.data.sort((a, b) => {
                    if (a.is_like === "1" && b.is_like === "0") {
                        return -1;
                    } else if (a.is_like === "0" && b.is_like === "1") {
                        return 1;
                    } else {
                        return a.text.toLowerCase().localeCompare(b.text.toLowerCase());
                    }
                })
                setState(arr)
            }
            else {
                setState([])
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onChooseNiches = (ep, data, funcData) => (dispatch, getState) => {
    commonAxios(ep, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (funcData) {
                    funcData.fetchIdeas()
                    funcData.fetchTopVideos()
                    funcData.fetchKeyword()
                }
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}

export const onUserRemoveNiches = (ep, data, fetchNiches, funcData) => (dispatch, getState) => {
    commonAxios(ep, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (fetchNiches) {
                    fetchNiches()
                }

                if (funcData) {
                    funcData.fetchIdeas()
                    funcData.fetchTopVideos()
                    funcData.fetchKeyword()
                }

            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}




export const onAddData = (ep, data, fetchData, handleClose, loader, setLoader, index, keyword, setKeyword) => (dispatch, getState) => {
    commonAxios(ep, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (fetchData) {
                    fetchData()
                }
                if (handleClose) {
                    handleClose()
                }
                if (index !== undefined) {
                    let newArray = [...keyword]
                    newArray[index].is_like = "1"
                    newArray[index].id = res.data

                    let arr = newArray.sort((a, b) => {
                        if (a.is_like === "1" && b.is_like === "0") {
                            return -1;
                        } else if (a.is_like === "0" && b.is_like === "1") {
                            return 1;
                        } else {
                            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                        }
                    })
                    setKeyword(arr)
                }
            }
            setLoader({
                ...loader,
                add: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                add: false
            })
            console.log(err)
        })
}

export const onSearchKeyData = (data, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("search-user-keyword", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arrData = res.data.keywords.map((curElem) => {
                    return {
                        name: curElem.kw,
                        competition: curElem.c ? curElem.c.toString() : "0",
                        volume: curElem.n ? curElem.n.toString() : "0",
                        overall: curElem.sb ? curElem.sb.toString() : "0",
                        isSearchedData: true,
                        is_like: "0"
                    }
                })
                setState(arrData)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                search: false
            })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                search: false
            })
            console.log(err)
        })
}
// campaign Actions

export const onFetchCampaigns = (setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-campaign-all", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            } else {
                setState([])
            }
            setLoader({
                ...loader,
                campLoader: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                campLoader: false
            })
            console.log(err)
        })
}

export const onDeleteCampaign = (data, fetchCampaigns, Swal) => (dispatch, getState) => {
    commonAxios("delete-campaign", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchCampaigns()
            }
            else {
                dispatch(res.msg, "danger")
            }
            Swal.close()
        }).catch((err) => {
            Swal.close()
            console.log(err)
        })
}

export const onFetchVideoCampaigns = (data, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-video", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            } else {
                setState([])
            }
            setLoader({
                ...loader,
                pageLoader: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                pageLoader: false
            })
            console.log(err)
        })
}

export const onDeleteVideo = (data, fetchData, Swal) => (dispatch, getState) => {
    commonAxios("delete-video", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchData()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            Swal.close()
        }).catch((err) => {
            Swal.close()
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}

export const onFetchVideoTemplates = (data, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-template", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)

            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                fetchLoader: false,
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                fetchLoader: false,
            })
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}

export const onFetchSchedule = (setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-schedular", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = res.data.map((curElem) => {
                    return {
                        id: curElem.id,
                        title: curElem.selectedIdea[0].name.slice(0, 20) + "...",
                        date: curElem.publishing.youtube.publishData,
                        fullTitle: curElem.selectedIdea[0].name,
                        created: curElem.created,
                        publishing: curElem.publishing,
                    }
                })
                arr = arr.filter((curElem) => {
                    return curElem.publishing.youtube.enable
                })
                setState(arr)
            }
            if (setLoader) {
                setLoader({
                    ...loader,
                    fetchLoader: false,
                })
            }

        }).catch((err) => {
            if (setLoader) {
                setLoader({
                    ...loader,
                    fetchLoader: false,
                })
            }
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}

export const onDeleteSchadule = (data, fetchData, Swal) => (dispatch, getState) => {
    commonAxios("delete-schedular", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (fetchData) {
                    fetchData()
                }
            }
            if (Swal) {
                Swal.close()
            }
        }).catch((err) => {
            console.log(err)
            if (Swal) {
                Swal.close()
            }
        })
}

export const onCheckStatus = (data, setState, checkData, rendCheck, setCheckData, clearInterval, fetchResource) => (dispatch, getState) => {
    commonAxios("check-status", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (res.data[0].status === "6") {
                    setState(+res.data[0].status)
                    setCheckData({
                        ...checkData,
                        renderProgress: res.data[0].renderProgress,
                        renderStatus: res.data[0].renderStatus
                    })
                }
                if (res.data[0].status === "8") {
                    setState(+res.data[0].status)
                    setCheckData({
                        ...checkData,
                        renderProgress: res.data[0].renderProgress,
                        renderStatus: res.data[0].renderStatus
                    })
                }
                else if (res.data[0].status === "2") {
                    clearInterval(rendCheck)
                    fetchResource()
                }
                else if (res.data[0].status === "3") {
                    clearInterval(rendCheck)
                    fetchResource()
                }
                else if (res.data[0].status === "5" || res.data[0].status === "7" || res.data[0].status === "9") {
                    setState(+res.data[0].status)
                }
                else {
                    setState(+res.data[0].status)
                }
            } else {
                clearInterval(rendCheck)
            }
        }).catch((err) => {
            clearInterval(rendCheck)
            console.log(err)
        })
}




//No Axios Here




export const onSelectNiches = (val) => (dispatch) => {
    dispatch({ type: "PICK_NICHES", payload: val })
}
export const onRemoveNiches = (val) => (dispatch) => {
    dispatch({ type: "REMOVE_NICHES", payload: val })
}

export const onSelectLanguage = (val) => (dispatch) => {
    dispatch({ type: "PICK_LANGUAGE", payload: val })
}
import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TrainingTitles from "./TrainingTitles";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import TitleBar from "../TitleBar";

const TrainingArticles = () => {

    let location = useLocation()
    const id = queryString.parse(location.search).id;
    const art_id = queryString.parse(location.search).art_id;
    const articleData = useSelector(state => state.support.articles);

    const [filterArticle, setFilterArticle] = useState(false)

    useEffect(() => {
        if (articleData.length > 0) {
            setFilterArticle(articleData.filter(article => article.id === id)[0])
        }
    }, [articleData])


    return (
        <>
            <TitleBar title="Training" />
            <Navbar />

            <section className="siteWrap">
                <div className="tabCont">
                    <div className="row">
                        <TrainingTitles location={location} id={id} />

                        <div className="col-lg-8 tabRight" style={{paddingTop : 5}}>
                            <div className="tabepaneIn forTraining">
                                <h2 className="tabTitle">
                                    <Link className="vidoebtn" to={`/training?id=${id}`}><span className="mr-1">
                                        <i className="fas fa-arrow-left"></i></span> Back
                                    </Link>
                                </h2>

                                <div className="tab-content mt-4" id="v-pills-tabContent">
                                    <div className="tab-pane fade show active" id="v-pills-client" role="tabpanel" aria-labelledby="v-pills-client-tab">
                                        {filterArticle ?
                                            filterArticle.items.map((item, index) => {
                                                return (
                                                    item.id === +art_id ?
                                                        <React.Fragment key={index}>
                                                            <div className="tabepaneIn-main" key={index}>
                                                                <div className="tabepaneIn-cont tabepaneIn-cont-alt">
                                                                    <div className="text-white"
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: item.content
                                                                        }}>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                        : ''
                                                )
                                            })
                                            :
                                            <div className="spinner" style={{ width: '100%', height: "900px" }}>
                                                <div className="spinner_tag" style={{ position: "absolute", top: '50%', left: '50%', margin: "0 auto" }}>
                                                    <i className="fa fa-spinner fa-spin mr-2" style={{ color: "#1478d3", fontSize: "30px" }} />
                                                </div>
                                            </div>
                                        }

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default TrainingArticles;
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { onFetchSchedule } from '../../Actions/ChannelAction';
import Navbar from '../Navbar';
import TitleBar from '../TitleBar';
import { BsSearch } from 'react-icons/bs';
import Footer from '../Footer';
import Pagination from '../Pagination';
import ScheduleRow from './ScheduleRow';

const ScheduleTable = () => {

    const dispatch = useDispatch()
    const [data, setData] = useState([])
    const [scheduleData, setScheduleData] = useState([])
    const [loader, setLoader] = useState({
        fetchLoader: false
    })

    const [state, setState] = useState({
        search: ""
    })

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemonPage: 10
    });

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }


    const indexofLastTodo = pagination.currentPage * pagination.totalItemonPage;
    const indexofFirstTodo = indexofLastTodo - pagination.totalItemonPage;
    const currentTodo =
        state.search !== "" ? scheduleData : scheduleData.slice(indexofFirstTodo, indexofLastTodo);


    useEffect(() => {
        if (state.search === "") {
            return setScheduleData(data)
        } else {
            const val = data
                .filter((curElem) => {
                    let data = curElem.fullTitle.toLowerCase().includes(state.search.toLowerCase());
                    if (data) {
                        return curElem;
                    }
                })
            setScheduleData(val);
        }
    }, [data, state])

    const fetchAllSchedules = () => {
        setLoader({
            ...loader,
            fetchLoader: true
        })
        dispatch(onFetchSchedule(setData, loader, setLoader))
    }
    useEffect(() => {
        fetchAllSchedules()
    }, [])

    return (
        <>
            <TitleBar title="Schedule Content" />
            <Navbar />
            <section className='siteWrap'>
                <div className="siteWrap-in siteWrapN" style={{ paddingTop: "32px" }}>
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-xl-12">
                                <div className="business-top mb-4">
                                    <h2 className="mb-2 pb-1 p_space">View Schedules</h2>
                                    <div className="d-flex justify-content-end">
                                        <Link className='demoLink ml-2' to="/schedule"><span>Schedules</span></Link>
                                    </div>
                                </div>

                                <div className="business-list">
                                    <div className="business-search">
                                        <div className="business-search-inp">
                                            <span className="searchIcon"><BsSearch /></span>
                                            <input
                                                className="inpSearch withIcon"
                                                type="text"
                                                placeholder="Search"
                                                name="search"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table businessTable">
                                            <thead>
                                                <tr>
                                                    <th width={500}>Idea</th>
                                                    <th>Socials</th>
                                                    <th>Publish Date</th>
                                                    <th>Created</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentTodo.length > 0 ?
                                                    currentTodo.map((curElem, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <ScheduleRow
                                                                    fetchAllSchedules={fetchAllSchedules}
                                                                    curElem={curElem}
                                                                />
                                                            </React.Fragment>
                                                        )
                                                    }) :
                                                    ""}

                                            </tbody>
                                        </table>
                                        <div className="text-center my-3 text-light">
                                            {data.length === 0 ?
                                                loader.fetchLoader ?
                                                    <i className="fa fa-spinner fa-spin" style={{ color: "#219fea", fontSize: 30 }} />
                                                    : "No data found"

                                                : ""}
                                        </div>
                                    </div>

                                    <Pagination
                                        listData={scheduleData}
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        currentTodo={currentTodo}
                                        loader={loader.fetchLoader}
                                        listArr={data}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
}

export default ScheduleTable;

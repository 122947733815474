import React, { useEffect, useState } from "react";
import { AiOutlineClose, AiFillHeart } from "react-icons/ai";
import { onChooseNiches } from "../../Actions/ChannelAction";
import { useDispatch } from "react-redux";
import SweetAlertTwo from "../SweetAlertTwo";
import { BiUpArrowAlt } from "react-icons/bi"
import { useSelector } from "react-redux";
import DemoAndIdeasHeader from "./DemoAndIdeasHeader";
const DemoAndIdeas = ({ videoUrl, ideas, fetchIdeas }) => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth)
  const [loader, setLoader] = useState({
    add: false,
    selectedIndex: -1,
  });

  const [ideasData, setIdeasData] = useState([])

  const onDislike = (val) => {
    if (+auth.user.isClient !== 1) {
      let data = {
        dailyIdeaId: val.id,
      };

      const newArray = [...ideas];
      const indexToModify = newArray.findIndex(item => +item.id === +val.id);
      if (indexToModify !== -1) {
        newArray[indexToModify].is_like = "0";
        let data = newArray.sort((a, b) => {
          if (a.is_like === "1" && b.is_like === "0") {
            return -1;
          } else if (a.is_like === "0" && b.is_like === "1") {
            return 1;
          } else {
            return a.text.toLowerCase().localeCompare(b.text.toLowerCase());
          }

        })
        setIdeasData(data);
      }

      dispatch(onChooseNiches("remove-idea", data, false));
    }
  };

  const handleLike = (val, index) => {
    if (+auth.user.isClient !== 1) {
      let data = {
        dailyIdeaId: val.id,
      };

      const newArray = [...ideas];
      const indexToModify = newArray.findIndex(item => +item.id === +val.id);
      if (indexToModify !== -1) {
        newArray[indexToModify].is_like = "1";
        let data = newArray.sort((a, b) => {
          if (a.is_like === "1" && b.is_like === "0") {
            return -1;
          } else if (a.is_like === "0" && b.is_like === "1") {
            return 1;
          } else {
            return a.text.toLowerCase().localeCompare(b.text.toLowerCase());
          }
        })
        setIdeasData(data);
      }
      dispatch(onChooseNiches("like-idea", data, false));
    }
  };

  useEffect(() => {
    setIdeasData(ideas)
  }, [ideas])

  return (
    <div className="p_space">
      <div className="channel-stat pt-0">
        <div className="row">
          <div className="col-lg-4">
            <div className="info-box">
              <div className="info-box-body" style={{ height: "100%" }}>
                <div className="videoFrame">
                  <iframe
                    src={videoUrl}
                    width="640"
                    height="360"
                    frameborder="0"
                    allow="fullscreen"
                    allowfullscreen
                    title="video"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="info-box">
              <div className="info-box-head">
                <h2>
                  <img src={require("../../images/ideas.png")} alt="" /> Daily
                  Ideas
                </h2>
              </div>
              <div className="info-box-body">
                <div className="table-responsive">
                  <table
                    className="table video-table text-left daily_ideas_wrapper"
                    style={{ marginTop: "-9px" }}
                  >
                    <DemoAndIdeasHeader ideasData={ideasData} setIdeasData={setIdeasData} />
                    {ideasData.length > 0
                      ? ideasData.map((curElem, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <span>{curElem.text}</span>
                            </td>
                            <td className="text-center">
                              <span>{curElem.competition}</span>
                            </td>
                            <td className="text-center">
                              {curElem.competition_volume === "low" ?
                                <span >
                                  <BiUpArrowAlt
                                    size={30}
                                    style={{ fontStyle: 30 }}
                                    color="#3AFF89"
                                    title="Low"
                                  />
                                </span>
                                :
                                curElem.competition_volume === "medium" ?
                                  <span >
                                    <BiUpArrowAlt
                                      size={30}
                                      style={{ fontStyle: 30 }}
                                      color="#fd0"
                                      title="Medium"
                                    />
                                  </span> :
                                  curElem.competition_volume === "high" ?
                                    <span >
                                      <BiUpArrowAlt
                                        size={30}
                                        style={{ fontStyle: 30, transform: "rotate(90deg)" }}
                                        color="#ff8300"
                                        title="High"
                                      />
                                    </span> :
                                    <span >
                                      <BiUpArrowAlt
                                        size={30}
                                        style={{ fontStyle: 30, transform: "rotate(180deg)" }}
                                        color="#f95f58"
                                        title="Very High"
                                      />
                                    </span>
                              }
                            </td>
                            <td className="text-center">
                              <div className="d-flex">
                                {+curElem.is_like === 0 ? (
                                  <button
                                    className="wishIcon"
                                    title={"Add to repository"}
                                    onClick={() =>
                                      handleLike(curElem, index)
                                    }
                                  >
                                    {loader.add &&
                                      index === loader.selectedIndex ? (
                                      <i className="fa fa-spinner fa-spin" />
                                    ) : (
                                      <AiFillHeart />
                                    )}
                                  </button>
                                ) : (
                                  <span
                                    className="delIcon cursor-pointer"
                                    onClick={() => onDislike(curElem)}
                                    title="Remove from repository"
                                  >
                                    <AiFillHeart />
                                  </span>
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })
                      : ""}
                  </table>
                </div>
                <div
                  className="NO_daily_idea_wrap"
                  style={{ display: ideas.length > 0 ? "" : "block" }}
                >
                  <p style={{ fontSize: 17 }}>No daily idea generated for you yet. Please like some niches first.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <SweetAlertTwo
          show={info.enable}
          title="Are You Sure?"
          text="Do you want to remove this idea from repository!"
          icon="warning"
          showCancelButton={true}
          confirmButtonColor="#b32f3d"
          cancelButtonColor="#2a2c3a"
          confirmButtonText="Yes, remove!"
          confirmTitle="Removing..."
          confirmText="This won't be revert!"
          confirmIcon="success"
          handlePerform={dislikeIdea}
          onCancel={handleCancle}
        /> */}
      </div>
    </div>
  );
};

export default DemoAndIdeas;

import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { BiCalculator } from 'react-icons/bi'

const ProfitCalculator = () => {
    const [state, setState] = useState({
        x: 4,
        y: 7,
        maxVPD: 10000,
        engageRate: 20,
    })

    const [estimate, setEstimate] = useState({
        x: 0,
        y: 0
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }

    const formatNumber = (num, precision = 2) => {
        const map = [
            { suffix: 'T', threshold: 1e12 },
            { suffix: 'B', threshold: 1e9 },
            { suffix: 'M', threshold: 1e6 },
            { suffix: 'K', threshold: 1e3 },
            { suffix: '', threshold: 1 },
        ];

        const found = map.find((x) => Math.abs(num) >= x.threshold);
        if (found) {
            const formatted = (num / found.threshold).toFixed(precision) + found.suffix;
            return formatted;
        }

        return num;
    }

    useEffect(() => {
        let x1 = (state.x * state.engageRate) / 100
        let x2 = (state.y * state.engageRate) / 100
        let views = state.maxVPD / 1000

        setEstimate({
            ...estimate,
            x: x1 * views,
            y: x2 * views
        })

    }, [state])

    return (
        <div className="info-box" style={{ height: "auto" }}>
            <div className="info-box-head">
                <h2><BiCalculator /> Profit Calculator</h2>
            </div>
            <div className="info-box-body full" style={{ padding: '20px 20px 0 20px' }}>
                <div className="pro-clac" style={{ height: '420px' }}>
                    <div className="pro-clac-left">
                        <h6>Daily Video Views</h6>
                        <p>Drag the slider to calculate potential earnings</p>
                        <h4>{state.maxVPD} <span>Views/Day</span></h4>
                        <div>
                            <input
                                type="range"
                                className="slidern"
                                max={150000}
                                min={0}
                                value={state.maxVPD}
                                name="maxVPD"
                                onChange={(e) => handleChange(e)}
                            />
                        </div>

                        <h4>Average Engagement Rate</h4>
                        <div>
                            <input
                                type="range"
                                className="slidern"
                                max={100}
                                min={0}
                                value={state.engageRate}
                                name="engageRate"
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                        <div className="row mt-5">
                            <div className="col-6">
                                <div>
                                    <h6>{formatNumber(state.maxVPD * 30)}</h6>
                                    <p>Views per Month</p>
                                </div>
                            </div>
                            <div className="col-6">
                                <div>
                                    <h6>{formatNumber(state.maxVPD * 365)}</h6>
                                    <p>Views per Year</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pro-clac-right">
                        <div className="clac-value"><span>Estimated Daily Earnings</span> <span>${estimate.x.toFixed(1)} - ${estimate.y.toFixed(1)}</span></div>
                        <div className="clac-value"><span>Estimated Monthly Earnings</span> <span>${(estimate.x * 30).toFixed(1)} - ${(estimate.y * 30).toFixed(1)}</span></div>
                        <div className="clac-value"><span>Projected Yearly Earnings</span> <span>${(estimate.x * 365).toFixed(1)} - ${(estimate.y * 365).toFixed(1)}</span></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfitCalculator
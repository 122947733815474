import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConnectFacebook from "./ConnectFacebook";
import ConnectGoogle from "./ConnectGoogle";
import facebookIcon from "../../images/logo-facebook.svg"
import youtubeIcon from "../../images/YT-DEFAULT.png"
import aws from "../../images/AWS.png"

import Navbar from "../Navbar";
import TitleBar from "../TitleBar";
import { fetchSocialAccounts } from "../../Actions/SocialAction";
import SocialConnected from "./SocialConnected";
import ConnectAmazon from "./ConnectAmazon";

const Integration = () => {
    const dispatch = useDispatch()
    const social = useSelector(state => state.social.data)


    const fetchIntegration = () => {
        dispatch(fetchSocialAccounts())
    }

    useEffect(() => {
        fetchIntegration()
    }, [])


    return (
        <>

            <TitleBar title="Integration" />
            <Navbar />

            <div className="siteWrap">
                <div className="siteWrap-in siteWrapN" style={{ paddingTop: '32px' }}>
                    <div className="container-fluid">
                        <div className="row">
                            <section className="connection">
                                <div className="integr-wrap pt-0">
                                    <h2 className="p_space">Connected</h2>
                                </div>
                                <div className="container-fluid">
                                    <div className="integr-wrap pt-0">
                                        <div className="row">
                                            {social.facebook.length > 0 ?
                                                social.facebook.map((curElem, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <SocialConnected
                                                                type="facebook"
                                                                icon={facebookIcon}
                                                                name={curElem.name}
                                                                data={curElem}
                                                                fetchIntegration={fetchIntegration}
                                                            />
                                                        </React.Fragment>
                                                    )

                                                })
                                                : ""}
                                            {social.youtube.length > 0 ?
                                                social.youtube.map((curElem, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <SocialConnected
                                                                type="youtube"
                                                                icon={youtubeIcon}
                                                                name={curElem.username}
                                                                data={curElem}
                                                                fetchIntegration={fetchIntegration}
                                                            />
                                                        </React.Fragment>
                                                    )
                                                })
                                                : ""}
                                            {social.amazon.length > 0 ?
                                                social.amazon.map((curElem, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <SocialConnected
                                                                type="amazon"
                                                                icon={aws}
                                                                name={curElem.awsBucket}
                                                                data={curElem}
                                                                fetchIntegration={fetchIntegration}
                                                            />
                                                        </React.Fragment>
                                                    )
                                                })
                                                : ""}

                                            <div className="text-center mt-5">
                                                {
                                                    social.facebook.length === 0 &&
                                                        social.youtube.length === 0 &&
                                                        social.amazon.length === 0
                                                        ?
                                                        "You have not connected any account yet"
                                                        : ""
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="container-fluid p-0">
                                    <div className="integr-wrap " style={{ padding: "70px 0 0 0" }} >
                                        <h2 className="p_space">Available</h2>
                                    </div>
                                </div>
                                <div className="container-fluid">
                                    <div className="integr-wrap pt-0">

                                        <div className="row">
                                            <div className="col-sm-6 col-lg-4">
                                                <ConnectFacebook
                                                    fetchIntegration={fetchIntegration}
                                                />
                                            </div>

                                            <div className="col-sm-6 col-lg-4">
                                                <ConnectGoogle
                                                    fetchIntegration={fetchIntegration}
                                                />
                                            </div>

                                            <div className="col-sm-6 col-lg-4">
                                                <ConnectAmazon
                                                    fetchIntegration={fetchIntegration}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </section>

                        </div>
                    </div>
                </div >

            </div >
        </>
    )
}

export default Integration;
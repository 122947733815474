import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";

import { BsSearch } from "react-icons/bs"
import { AiOutlineEdit, AiOutlinePlus } from 'react-icons/ai';
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchSocialAccounts, saveGoogle } from "../../Actions/SocialAction";
import GoogleLogin from "react-google-login";
import { onGoogleLangFetch } from "../../Actions/contentAction";
import { onFetchCampaigns, onFetchCountries, onFetchVideoCampaigns } from "../../Actions/ChannelAction";
import CreateVideoModal from "../CreateVideo/CreateVideoModal";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import CampaignRows from "./CampaignRows";
import Pagination from "../Pagination";
import queryString from "query-string";
import VideoCampaign from "./VideoCampaign";
import { BiChevronLeft } from "react-icons/bi";

const Campaigns = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const campId = queryString.parse(location.search).id
    const cname = queryString.parse(location.search).cname
    const auth = useSelector(state => state.auth)
    const youtube = useSelector(state => state.social.data.youtube)

    const [color, setColor] = useState("#fff")
    const [id, setId] = useState(false)
    const [language, setLanguage] = useState([])
    const [countries, setCountries] = useState([])
    const [campaigns, setCampaigns] = useState([])
    const [campaignsData, setCampaignsData] = useState([])
    const [loader, setLoader] = useState({
        campLoader: false,
        pageLoader: false
    })
    const [filterObj, setFilterObj] = useState({
        key: "",
        searchKey: "",
        channelId: "all"
    })


    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemonPage: 10
    });

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const responseGoogle = (response) => {
        if (response.code) {
            connectYoutube(response.code);
        } else {
            console.log(response);
        }
    }

    const connectYoutube = (code) => {
        let data = JSON.stringify({ code: code, user_id: auth.user.id })
        dispatch(saveGoogle(data, fetchIntegration))

    }

    const handleFilter = (channelInfo) => {
        if (channelInfo === "all") {
            setFilterObj({
                ...filterObj,
                channelId: channelInfo
            })
        } else {
            setFilterObj({
                ...filterObj,
                channelId: channelInfo
            })
        }
    }

    const fetchLanguage = () => {
        dispatch(onGoogleLangFetch(setLanguage))
    }
    const fetchCountries = () => {
        dispatch(onFetchCountries(setCountries))
    }
    const fetchCampaigns = () => {
        setLoader({
            ...loader,
            campLoader: true
        })
        dispatch(onFetchCampaigns(setCampaigns, loader, setLoader))
    }

    const fetchVideoCamp = () => {
        let data = {
            id: campId
        }
        dispatch(onFetchVideoCampaigns(data, setCampaigns, loader, setLoader))
    }
    const fetchIntegration = () => {
        dispatch(fetchSocialAccounts())
    }

    useEffect(() => {
        if (campId) {
            setId(campId)
            setCampaigns([])
            setLoader({
                ...loader,
                pageLoader: true
            })
            fetchVideoCamp()
            setPagination({
                ...pagination,
                currentPage: 1,
                totalItemonPage: 10
            })
        } else {
            setPagination({
                ...pagination,
                currentPage: 1,
                totalItemonPage: 10
            })
            setCampaigns([])
            fetchCampaigns()
            setId(false)
        }
    }, [campId])

    useEffect(() => {
        fetchIntegration()
        fetchCountries()
        fetchLanguage()
    }, [])

    // pagination stuff



    useEffect(() => {
        if (filterObj.searchKey === "" && filterObj.key === "" && filterObj.channelId === "all") {
            setCampaignsData(campaigns)
        } else {
            let dataArr = campaigns.filter((item) => {
                if (item.mode === filterObj.key) {
                    return item
                }
                else if (filterObj.key === "") {
                    return item
                }
            }).filter((item1) => {
                return item1.name.toLowerCase().includes(filterObj.searchKey.toLowerCase())
            }).filter((item2) => {
                if (+item2.channel === +filterObj.channelId) {
                    return item2
                }
                else if (filterObj.channelId === "all") {
                    return item2
                }
            })
            setPagination({
                ...pagination,
                currentPage: 1,
                totalItemonPage: 10
            })
            setCampaignsData(dataArr)
        }
    }, [filterObj, campaigns])

    const indexofLastTodo = pagination.currentPage * pagination.totalItemonPage;
    const indexofFirstTodo = indexofLastTodo - pagination.totalItemonPage;
    const currentTodo =
        (filterObj.searchKey === "" && filterObj.key !== "") || (filterObj.searchKey !== "" && filterObj.key === "") || (filterObj.searchKey !== "" && filterObj.key !== "")
            ? campaignsData
            : campaignsData.slice(indexofFirstTodo, indexofLastTodo);



    return (
        loader.pageLoader ?
            <div className="loader-sec">
                <div className="loader">
                </div>
            </div>
            :
            <>
                <TitleBar title="Campaigns" />
                <Navbar />

                <section className="siteWrap">
                    <div className="siteWrap-in siteWrapN pt-3 pb-3">

                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-xl-12">
                                    <div className="business-heading pb-2 mb-3" >
                                        {id ?
                                            <Link to={`/campaigns`}>  <h2 className=""><BiChevronLeft style={{ paddingBottom: 2 }} size={40} />{cname}</h2></Link>
                                            :
                                            <h2 className="p_space">Your Channels</h2>
                                        }

                                        {+auth.user.isClient === 1 ? "" :
                                            <div className="d-flex align-items-center justify-content-center">
                                                <button className="demoLink mt-0 me-2"
                                                    onClick={handleShow}
                                                    style={{ fontSize: "14px", height: "50px" }}><AiOutlineEdit /> Create Video</button>

                                                <span
                                                    className="demoLink round mt-0 "
                                                    onMouseEnter={() => setColor("#25aaf1")}
                                                    onMouseLeave={() => setColor("#fff")}
                                                    data-tooltip-id="my-tooltip"
                                                >
                                                    <GoogleLogin
                                                        clientId="385524880993-kb22h3hvlooun9i0908bsm29bkeafhio.apps.googleusercontent.com"
                                                        render={(renderProps) => (
                                                            <button
                                                                onClick={renderProps.onClick}
                                                                type="button"
                                                                style={{
                                                                    background: "transparent",
                                                                    border: "none",
                                                                    color: color,
                                                                    display: "block",
                                                                    height: "100%",
                                                                    width: "100%"
                                                                }} >
                                                                <AiOutlinePlus />
                                                            </button>
                                                        )}

                                                        onSuccess={responseGoogle}
                                                        onFailure={responseGoogle}
                                                        scope="https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtube.force-ssl"
                                                        responseType="code"
                                                        accessType="offline"
                                                        prompt="consent"
                                                        cookiePolicy={"single_host_origin"}
                                                    />
                                                </span>
                                            </div>
                                        }
                                        <Tooltip
                                            id="my-tooltip"
                                            style={{ backgroundColor: "#424b6c", color: "#fff" }}
                                            place="bottom"
                                            content="Connect your Youtube channel"
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                        {id ? "" :
                            <div className="container-fluid p_space_1">

                                <div className="channel">
                                    <div className="row">
                                        <div className="col-sm-2 col-lg-2  mb-1 cursor-pointer" onClick={() => handleFilter("all")}>
                                            <div className="channel-single" style={{ background: filterObj.channelId === "all" ? "#167ed6" : "" }}>
                                                <div className="channel-top align-items-center justify-content-center">
                                                    <div style={{ border: "none", height: 80 }}></div>
                                                    <div >
                                                        <h2>All</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {youtube.length > 0 ?
                                            youtube.map((curElem, index) => {
                                                return (
                                                    <div className="col-sm-6 col-lg-3 mb-1 cursor-pointer" key={index} onClick={() => handleFilter(curElem.id)}>
                                                        <div className="channel-single" style={{ background: +curElem.id === +filterObj.channelId ? "#167ed6" : "" }}>
                                                            <div className="channel-top align-items-center">
                                                                <div className="channel-img"><img src={curElem.image} alt="" /></div>
                                                                <div className="channel-txt">
                                                                    <h2>{curElem.username}</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div className="col-md-12 text-center text-light mt-2">
                                                You have not connected any channel yet!
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="container-fluid p_space_1">
                            <div className="business-list">


                                {id ?
                                    <VideoCampaign
                                        currentTodo={currentTodo}
                                        loader={loader}
                                        fetchVideoCamp={fetchVideoCamp}
                                    />

                                    :
                                    <>
                                        <div className="business-search">
                                            <div className="business-search-inp">
                                                <span className="searchIcon"><BsSearch /></span>
                                                <input
                                                    className="inpSearch withIcon"
                                                    type="text"
                                                    placeholder="Search"
                                                    onChange={(e) => setFilterObj({
                                                        ...filterObj,
                                                        searchKey: e.target.value
                                                    })}
                                                />
                                            </div>
                                            <div className="business-search-inp ml-2">
                                                <select
                                                    className="inpSearch"
                                                    onChange={(e) => setFilterObj({
                                                        ...filterObj,
                                                        key: e.target.value
                                                    })}
                                                >
                                                    <option value="">Select Mode</option>
                                                    <option value="auto">Auto</option>
                                                    <option value="manual">Manual</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table businessTable">
                                                <thead>
                                                    <tr>
                                                        <th width="150"></th>
                                                        <th>Name</th>
                                                        <th className="text-center">Mode</th>
                                                        <th># of Videos</th>
                                                        <th>Created</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentTodo.length > 0 ?
                                                        currentTodo.map((curElem, index) => {
                                                            return (
                                                                <React.Fragment key={index}>
                                                                    <CampaignRows
                                                                        curElem={curElem}
                                                                        fetchCampaigns={fetchCampaigns}
                                                                    />
                                                                </React.Fragment>
                                                            )
                                                        })
                                                        :
                                                        filterObj.channelId === "all" && filterObj.key === "" && filterObj.searchKey === "" ?
                                                            <tr className="text-center table-border-remove">
                                                                <td colSpan={5}>
                                                                    {loader.campLoader ?
                                                                        <i className="fa fa-spinner fa-spin" style={{ color: "#23a3ed", fontSize: 25 }} />
                                                                        : "No Campaign generated yet!"}
                                                                </td>
                                                            </tr> : ""
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </>

                                }

                                <div className="tablepage">
                                    <Pagination
                                        listData={campaignsData}
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        currentTodo={currentTodo}
                                        listArr={campaigns}
                                        loader={loader.campLoader}
                                    />
                                </div>

                            </div>


                        </div>
                    </div>
                </section >
                <CreateVideoModal
                    show={show}
                    handleClose={handleClose}
                    language={language}
                    countries={countries}
                />
                <Footer />
            </>
    )
}

export default Campaigns;
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import waveIcon from "../../images/wave.svg";
import { addBgMusic, removeBgAudioFromSlides, setTtsData, removeTtsFromSlides } from "../../Actions/contentAction";
import SweetAlertTwo from "../SweetAlertTwo";

let audio = false;
const AudioFiles = ({ title, url, currentUrl, setCurrentUrl, type, selectedAudio, setSelectedAudio, removeMedia, id, selectedSlideIndex, voiceOverName }) => {

    const dispatch = useDispatch();

    const [play, setPlay] = useState(false);
    const [loader, setLoader] = useState(false);
    const [audioDuration, setAudioDuration] = useState(0);
    const [info, setInfo] = useState({
        id: -1,
        enable: false
    })

    let clearPause;
    const playAudio = (url) => {
        if (audioDuration > 0) {
            if (audio !== false) {
                audio.pause();
                setPlay(false);
            }
            setLoader(true);
            audio = new Audio(url);
            audio.play()
                .then(r => setLoader(false)).catch(err => console.log(err));
            setCurrentUrl(url);
            setPlay(true)
            clearPause = setTimeout(() => {
                pauseAudio(url)
            }, audioDuration * 1000)
        }
    }

    const pauseAudio = (url) => {
        if (audio !== false) {
            clearTimeout(clearPause);
            audio.pause();
            setPlay(false);
            audio = false;
        }
    }


    const selectAudio = (url, timeDur) => {
        if (audio !== false) {
            if (url === audio.src) {
                pauseAudio()
            }
        }
        let dur = audioDuration
        if (dur > parseInt(audioDuration.toFixed())) {
            dur = parseInt(audioDuration.toFixed()) + 1
        }
        if (title === "Uploaded VoiceOver") {
            const audio = {
                src: url,
                duration: parseInt(dur.toFixed()),
                meta: {
                    languageId: "",
                    text: "",
                    translateText: "",
                    voiceId: "",
                }
            }
            setSelectedAudio(url);
            dispatch(setTtsData(audio, selectedSlideIndex, true))
        }
        else {
            setSelectedAudio(url);
            dispatch(addBgMusic(url, selectedSlideIndex, timeDur))
        }

    }

    const removeAudio = () => {
        setSelectedAudio(false);
        if (title === "Uploaded VoiceOver") {
            dispatch(removeTtsFromSlides(selectedSlideIndex))
        }
        else {
            dispatch(removeBgAudioFromSlides(selectedSlideIndex))
        }
    }

    const onConfirm = async (Swal) => {
        await removeMedia(info.id, Swal);
        setInfo({
            ...info,
            id: -1,
            enable: false
        })
    }

    const deleteFile = (e) => {
        e.stopPropagation()
        setInfo({
            ...info,
            id: id,
            enable: true
        })
    }

    const handleCancel = () => {
        setInfo({
            ...info,
            id: -1,
            enable: false
        })
    }

    useEffect(() => {
        return () => {
            if (audio !== false) {
                audio.pause();
            }
        }
    }, [])

    useEffect(() => {
        let au = document.createElement('audio');
        au.src = url;

        au.addEventListener('loadedmetadata', function () {
            if (au.duration !== Infinity && au.duration !== 0) {
                setAudioDuration(Number((au.duration).toFixed(1)));
            }
            else {
                var getDuration = function (url, next) {
                    var _player = new Audio(url);
                    _player.addEventListener("durationchange", function (e) {
                        if (this.duration != Infinity) {
                            var duration = this.duration
                            _player.remove();
                            next(duration);
                        };
                    }, false);
                    _player.load();
                    _player.currentTime = 24 * 60 * 60; //fake big time
                    _player.volume = 0;
                    _player.play();
                };

                getDuration(au.src, function (duration) {
                    setAudioDuration(Number((duration).toFixed(1)));
                });
            }
        }, false);




    }, [])

    return (
        <>
            <li className="cursor-pointer"  >
                <div className={`audioList-single card ${selectedAudio === url ? 'active-audio' : ''}`}>
                    <div className="active-audio-wrapper cursor-pointer" onClick={() => removeAudio(url)}>
                        <i className="fa fa-check" aria-hidden="true"></i>
                    </div>
                    <div className="audioList-top" onClick={() => selectAudio(url, audioDuration)}>

                        {
                            type === "uploaded" ?
                                <div className="card-icon-delete" onClick={(e) => deleteFile(e)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                    </svg>
                                </div>
                                : ''
                        }
                        <img className="waveIcon" alt="" src={waveIcon} />
                        <span className="audio-time text-light">{`${audioDuration} sec`}</span>
                    </div>
                    <div className="audioList-bottom">
                        {
                            play && currentUrl === url ?
                                <i
                                    className="fa fa-pause-circle"
                                    aria-hidden="true"
                                    onClick={() => pauseAudio(url)}
                                    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                />
                                :
                                <i
                                    className={`${loader ? 'fa fa-spinner fa-spin' : 'fas fa-play-circle'} `}
                                    onClick={() => playAudio(url)}
                                    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                />
                        }
                        {
                            title === "Uploaded VoiceOver" ? <p className="mx-2 text-light">{voiceOverName}</p> : <p className="mx-1 text-light">{title}</p>

                        }

                    </div>
                </div>

                <SweetAlertTwo
                    show={info.enable}
                    title="Are You Sure?"
                    text={`Do you want to delete this campaign!`}
                    icon="warning"
                    showCancelButton={true}
                    confirmButtonColor='#b32f3d'
                    cancelButtonColor='#2a2c3a'
                    confirmButtonText='Yes, Delete!'
                    confirmTitle='Deleting...'
                    confirmText="This won't be revert!"
                    confirmIcon='success'
                    handlePerform={onConfirm}
                    onCancel={handleCancel}
                />
            </li>



        </>
    )
}

export default AudioFiles;
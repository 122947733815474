import React, { useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs"
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import { onFetchCamp, onRenderAll, onUpdateCampaign } from "../../Actions/CreateVideoAction";
import VideoRows from "./VideoRows";
import VideoTempModal from "./VideoTempModal";

const VideoPage = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()

    const createVideo = useSelector(state => state.createVideo)
    const videoId = queryString.parse(location.search).id

    const [renderCheck, setRenderCheck] = useState([])
    const [pageLoader, setPageLoader] = useState(true)
    const [saveLoader, setSaveLoader] = useState(false)

    const handleRenderAll = () => {
        if (createVideo.videos.length === createVideo.videos.filter(video => video.status === "1").length) {
            let data = { videoId: createVideo.videos.map(video => video.id), cId: createVideo.id }
            setSaveLoader(true)
            dispatch(onRenderAll(data, navigate, setSaveLoader))
        }
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const fetchCampaign = () => {
        let data = {
            campaignId: videoId
        }
        dispatch(onFetchCamp(data, setPageLoader, navigate))
    }


    useState(() => {
        if (videoId) {
            fetchCampaign()
        }
    }, [videoId])



    return (
        !pageLoader ?
            <>
                <TitleBar title="Create Campaign" />
                <Navbar />
                <section className="siteWrap">
                    <div className="siteWrap-in" style={{ paddingTop: "32px" }}>
                        <div className="conatiner-fluid">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-md-11">
                                    <div className=" business-heading " style={{ paddingBottom: "10px", marginBottom: "15px", flexFlow: "wrap" }}>
                                        <div className="row align-items-center" style={{ width: "100%" }}>
                                            <div className="col-md-6" >
                                                <h2>Videos</h2>
                                            </div>
                                            <div className="col-md-6 mt-md-0 mt-sm-2 mt-2">
                                                <div className=" d-flex justify-content-end align-items-center">
                                                    <Link className="demoLink mt-0" to={`/thumbnail?id=${videoId}`} style={{ padding: "14px 32px 14px 10px" }}> <BsArrowLeft />Go To Thumbnail</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-end" style={{ paddingRight: "29px" }}>
                                        <button className="demoLink mb-4 mt-0" onClick={handleShow}>Template</button>
                                        <button onClick={handleRenderAll} className="demoLink mb-4 ml-3 mt-0" style={{ background: createVideo.videos.length === createVideo.videos.filter(video => video.status === "1").length ? "" : "gray" }} disabled={createVideo.videos.length === createVideo.videos.filter(video => video.status === "1").length ? false : true} >Render All{saveLoader ? <i className="fa fa-spinner fa-spin mx-1" /> : ""}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="info-box" style={{ padding: "20px" }}>
                                        <div className="info-box-body alt">
                                            <div>
                                                <div className="table-responsive">
                                                    <table className="table video-table">
                                                        <tr>
                                                            <th className="text-left">Videos</th>
                                                            <th>Status</th>
                                                            <th>Edit</th>
                                                            <th>Delete</th>
                                                        </tr>
                                                        {createVideo.videos.length > 0 ?
                                                            createVideo.videos.map((curElem, index) => {
                                                                let thumb = createVideo.thumbnail.find(({ ideaId }) => +ideaId === +curElem.ideaId) ? createVideo.thumbnail.find(({ ideaId }) => +ideaId === +curElem.ideaId).thumbnail : ""
                                                                let title = createVideo.metaData.find(({ ideaId }) => +ideaId === +curElem.ideaId) ? createVideo.metaData.find(({ ideaId }) => +ideaId === +curElem.ideaId).title : ""
                                                                let publishDate = createVideo.publishing.find(({ ideaId }) => +ideaId === +curElem.ideaId) ? createVideo.publishing.find(({ ideaId }) => +ideaId === +curElem.ideaId).youtube.publishData : ""
                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        <VideoRows
                                                                            curElem={curElem}
                                                                            index={index}
                                                                            thumb={thumb}
                                                                            title={title}
                                                                            publishDate={publishDate}
                                                                            fetchCampaign={fetchCampaign}
                                                                            setRenderCheck={setRenderCheck}
                                                                        />
                                                                    </React.Fragment>
                                                                )
                                                            })

                                                            : ""}

                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </section>

                <VideoTempModal
                    show={show}
                    handleClose={handleClose}
                    fetchCampaign={fetchCampaign}
                    videoId={videoId}
                />
                <Footer />

            </> :
            <div className="loader-sec">
                <div className="loader">
                </div>
            </div>
    )
}
export default VideoPage;
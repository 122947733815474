import { commonAxios } from "../Global/CommonAxios"
import { setAlert } from "./AlertAction"

export const fetchConversation = (selectedCid, setSelectedCid, handleCreateConv, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-conversation-all", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (selectedCid === -1) {
                    setSelectedCid(res.data[0].id)
                }
                dispatch({ type: "ADD_CONVERSATION", payload: res.data })
            } else {
                handleCreateConv()
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch(err => {
            console.log(err)
            setLoader({
                ...loader,
                fetch: false
            })
        })
}


export const onDeleteConversation = (data, fetchConv, setSelectedCid, handlePerform, Swal) => (dispatch, getState) => {
    commonAxios("delete-conversation", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setSelectedCid(0)
                handlePerform()
                fetchConv()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            Swal.close()
        }).catch(err => {
            Swal.close()
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}

export const onChatEdit = (data, setIsReadOnly, fetchConversation) => (dispatch, getState) => {
    commonAxios("edit-conversation-name", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            fetchConversation()
            setIsReadOnly(true)
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setIsReadOnly(true)
            console.log(err)
        })
}

export const onCreateConv = (fetch, setId, setActiveBlink, loader, setLoader) => (dispatch, getState) => {
    commonAxios("create-conversation", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setId(res.data.cid)
                setActiveBlink(true)
                fetch()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                createConv: false
            })
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                createConv: false
            })
            console.log(err)
        })
}

export const onClearConversation = (data, fetchConv, Swal) => (dispatch, getState) => {
    commonAxios("delete-message", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchConv()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            if (Swal) {
                Swal.close()
            }
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            if (Swal) {
                Swal.close()
            }
            console.log(err)
        })
}

export const onCreateMessage = (data, setTypingActive, setActiveBlink) => (dispatch, getState) => {
    commonAxios("create-message", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let obj = {
                    messages: res.data.messages
                    .replaceAll("<","\u2039")
                    .replaceAll(">","\u203A")
                    .replaceAll("\u2039br\u203A","\n")
                    .replaceAll("```",""),

                    type: "assistant"
                }
                dispatch({ type: "ON_SET_MESSAGE", payload: obj })
                setTypingActive(false)
                setActiveBlink(true)
            } else {
                dispatch(setAlert(res.msg, "danger"))
                setTimeout(() => {
                    setTypingActive(false)
                }, 3500)
                setActiveBlink(false)
            }
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setActiveBlink(false)
            setTimeout(() => {
                setTypingActive(false)
            }, 4000)
        })
}

export const onFetchMessages = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-message", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "ADD_MESSAGES", payload: res.data })
            } else {
                dispatch({
                    type: "ADD_MESSAGES", payload: []
                })
            }
            setLoader({
                ...loader,
                messages: false
            })
        })
        .catch((err) => {
            setLoader({
                ...loader,
                messages: false
            })
            console.log(err)
        })
}

// No Axios Here

export const onSetMessage = (obj) => (dispatch) => {
    dispatch({ type: "ON_SET_MESSAGE", payload: obj })
}
import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import Webinar from "./Webinar";
import TitleBar from "../TitleBar";
import Navbar from "../Navbar";
import DashboardSection1 from "./DashboardSection1";
import DashboardSection2 from "./DashboardSection2";
import { AiOutlineEdit, AiOutlinePlus } from 'react-icons/ai';
import { useSelector } from "react-redux";
import { loadUser } from "../../Actions/AuthAction";
import { useDispatch } from "react-redux";
import { onFetchCountries, onFetchTopVideos, onFetchUserIdeas, onFetchUserKeyword } from "../../Actions/ChannelAction";
import CreateVideoModal from "../CreateVideo/CreateVideoModal";
import { onGoogleLangFetch } from "../../Actions/contentAction";
import GoogleLogin from "react-google-login";
import { fetchSocialAccounts, saveGoogle } from "../../Actions/SocialAction";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { gapi } from 'gapi-script';


const Dashboard = () => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const youtube = useSelector(state => state.social.data.youtube)
    const [language, setLanguage] = useState([])
    const [countries, setCountries] = useState([])
    const [ideas, setIdeas] = useState([])
    const [topVideos, setTopVideos] = useState([])
    const [keyword, setKeyword] = useState([])
    const [color, setColor] = useState("#fff")
    const [state, setState] = useState({
        id: "",
        name: "",
        image: ""
    })

    const [loader, setLoader] = useState({
        topVideoLoader: false
    })


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleChange = (e) => {
        if (e.target.value === "") {
            setState({
                ...state,
                id: "",
                name: auth.user.name,
                image: auth.user.image
            })
        } else {
            const ytInfo = youtube.find(({ id }) => id === e.target.value)
            setState({
                ...state,
                id: ytInfo.id,
                name: ytInfo.name,
                image: ytInfo.image
            })
        }
    }

    const responseGoogle = (response) => {
        if (response.code) {
            connectYoutube(response.code);
        } else {
            console.log(response);
        }
    }

    const connectYoutube = (code) => {
        let data = JSON.stringify({ code: code, user_id: auth.user.id })
        dispatch(saveGoogle(data, fetchIntegration))

    }

    const fetchIntegration = () => {
        dispatch(fetchSocialAccounts())
    }

    const fetchLanguage = () => {
        dispatch(onGoogleLangFetch(setLanguage))
    }
    const fetchCountries = () => {
        dispatch(onFetchCountries(setCountries))
    }

    const fetchIdeas = () => {
        dispatch(onFetchUserIdeas(setIdeas))
    }
    const fetchTopVideos = () => {
        setLoader({
            ...loader,
            topVideoLoader: true,
        });
        dispatch(onFetchTopVideos(setTopVideos, loader, setLoader));
    };

    const fetchKeyword = () => {
        dispatch(onFetchUserKeyword(setKeyword))
    }

    useEffect(() => {
        if (youtube.length > 0) {
            setState({
                ...state,
                name: youtube[0].name,
                image: youtube[0].image
            })
        } else {
            setState({
                ...state,
                name: auth.user.name,
                image: auth.user.image
            })
        }
    }, [youtube])

    useEffect(() => {
        dispatch(loadUser())
        fetchCountries()
        fetchLanguage()
        fetchIntegration()
        fetchIdeas()
        fetchTopVideos();
        fetchKeyword()
    }, [])


    useEffect(() => {
        function start() {
            gapi.client.init({
                clientId: "271760818549-ktjjocgtp3am7157efvhi073ka16khfn.apps.googleusercontent.com",
                scope: 'https://www.googleapis.com/auth/youtube',
            });
        }

        gapi.load('client:auth2', start);
    }, []);

    return (
        <>
            <TitleBar title="Dashboard" />
            <Navbar />
            <section className="siteWrap">
                <div className="user-bar">
                    <div className="user-bar-left">
                        <div className="user-bar-img"><img src={state.image} alt="" /></div>
                        <div className="user-bar-txt">
                            <h6>Welcome back,</h6>
                            <div class="user_name" >
                                <select
                                    class="form-select"
                                    style={{
                                        border: "none",
                                        backgroundColor: "#424b6c",

                                        color: "#fff", fontSize: 19,
                                        width: "100%",
                                        borderRadius: '50px'
                                    }}
                                    onChange={(e) => handleChange(e)}
                                    value={state.id}
                                >
                                    {youtube.length > 0 ?
                                        youtube.map((curElem, index) => {
                                            return (
                                                <option key={index} value={curElem.id}>{curElem.username}</option>
                                            )
                                        })
                                        :
                                        <option value="">{auth.user.name}</option>
                                    }
                                </select>
                            </div>
                        </div>
                    </div>

                    {+auth.user.isClient === 1 ? "" :
                        <div className="user-bar-right">
                            <button className="demoLink mt-0" onClick={handleShow} style={{ fontSize: "14px", height: "50px" }}><AiOutlineEdit /> Create Video</button>

                            <span
                                className="demoLink round mt-0 ml-1"
                                style={{ width: "50px", height: "50px" }}
                                onMouseEnter={() => setColor("#25aaf1")}
                                onMouseLeave={() => setColor("#fff")}
                                data-tooltip-id="my-tooltip"
                            >
                                <GoogleLogin
                                    clientId="271760818549-ktjjocgtp3am7157efvhi073ka16khfn.apps.googleusercontent.com"
                                    render={(renderProps) => (
                                        <button
                                            onClick={renderProps.onClick}
                                            type="button"
                                            style={{
                                                background: "transparent",
                                                border: "none",
                                                color: color,
                                                display: "block",
                                                height: "100%",
                                                width: "100%"
                                            }} >
                                            <AiOutlinePlus />
                                        </button>
                                    )}

                                    onSuccess={responseGoogle}
                                    onFailure={responseGoogle}
                                    scope="https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtube.force-ssl"
                                    responseType="code"
                                    accessType="offline"
                                    prompt="consent"
                                    cookiePolicy={"single_host_origin"}
                                />

                            </span>
                            <Tooltip
                                id="my-tooltip"
                                style={{ backgroundColor: "#424b6c", color: "#fff" }}
                                place="bottom"
                                content="Connect your Youtube channel"
                            />
                        </div>}
                </div>

                <DashboardSection1
                    fetchIdeas={fetchIdeas}
                    fetchTopVideos={fetchTopVideos}
                    fetchKeyword={fetchKeyword}
                />
                <DashboardSection2
                    ideas={ideas}
                    fetchIdeas={fetchIdeas}
                    fetchKeyword={fetchKeyword}
                    videoLoader={loader.topVideoLoader}
                    topVideos={topVideos}
                    setTopVideos={setTopVideos}
                    keyword={keyword}
                    setKeyword={setKeyword}
                />

                <CreateVideoModal
                    show={show}
                    handleClose={handleClose}
                    language={language}
                    countries={countries}
                />
            </section >
            <Webinar />
            <Footer />
        </>
    )
}

export default Dashboard;
import React from 'react'
import { useSelector } from 'react-redux'

const ChannelStats = () => {
    const youtube = useSelector(state => state.social.data.youtube)
    return (
        <div className="section-status-single stats">
            <div className="info-box">
                <div className="info-box-head">
                    <h2><img src={require('../../images/channel.png')} /> Channel Stats</h2>
                    <div>
                        <select name="" id="">
                            {youtube.length > 0 ?
                                youtube.map((curElem, index) => {
                                    return (
                                        <option key={index} value={curElem.id}>{curElem.username}</option>
                                    )
                                })
                                : ""}
                        </select>
                    </div>
                </div>
                <div className="info-box-body">
                    <div className="stats-box">
                        <select name="" id="">
                            <option value="">Time period: Last 7 days</option>
                            <option value="">Time period: Last 1 month</option>
                        </select>

                        <div className="stat-view">
                            <div className="table-responsive" style={{ height: "290px" }}>
                                <table className="table table-block">
                                    <tr>
                                        <td>Views</td>
                                        <td>51</td>
                                        <td>+13.3% <span className="stat-up"><img src={require('../../images/up.png')} /></span> </td>
                                    </tr>
                                    <tr>
                                        <td>Subscribers</td>
                                        <td>0</td>
                                        <td>0.0%</td>
                                    </tr>
                                    <tr>
                                        <td>Watch time (Total hours)</td>
                                        <td>51</td>
                                        <td>+13.3% <span className="stat-down"><img src={require('../../images/down.png')} /></span></td>
                                    </tr>
                                    <tr>
                                        <td>Retention (Average)</td>
                                        <td>51</td>
                                        <td>+13.3% <span className="stat-down"><img src={require('../../images/down.png')} /></span></td>
                                    </tr>
                                    <tr>
                                        <td>Watch time (Total hours)</td>
                                        <td>51</td>
                                        <td>+13.3% <span className="stat-down"><img src={require('../../images/down.png')} /></span></td>
                                    </tr>
                                    <tr>
                                        <td>Watch time (Total hours)</td>
                                        <td>51</td>
                                        <td>+13.3% <span className="stat-down"><img src={require('../../images/down.png')} /></span></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChannelStats
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import SweetAlertTwo from '../SweetAlertTwo';
import { onDeleteSchadule } from '../../Actions/ChannelAction';
import { useSelector } from 'react-redux';

const ScheduleRow = ({ curElem, fetchAllSchedules }) => {

    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)

    const [socialActive, setSocialActive] = useState({
        youtube: false,
        facebook: false
    })
    const [info, setInfo] = useState({
        data: false,
        enable: false
    })

    const onDelete = (data) => {
        let publishData = data.publishing
        publishData.youtube.enable = false
        let obj = {
            id: data.id,
            object: publishData
        }
        setInfo({
            ...info,
            data: obj,
            enable: true
        })
    }
    const handleDelete = (Swal) => {
        dispatch(onDeleteSchadule(info.data, fetchAllSchedules, Swal))
    }

    const handleCancle = () => {
        setInfo({
            ...info,
            data: false,
            enable: false
        })
    }

    useEffect(() => {
        let obj = {
            youtube: false,
            facebook: false
        }
        for (let key in curElem.publishing) {
            if (curElem.publishing.hasOwnProperty(key)) {
                if (obj.hasOwnProperty(key)) {
                    obj = {
                        ...obj,
                        [key]: curElem.publishing[key].enable
                    }
                }
            }
        }
        setSocialActive(obj)
    }, [curElem])

    return (
        <tr>
            <td className='text-capitalize'>{curElem.fullTitle}</td>
            <td>
                <div style={{ display: "flex" }}>

                    {socialActive.youtube ?
                        <span className="me-1"><img src={require('../../images/social/youtube.png')} alt="" style={{ height: 40, width: 40 }} /></span>
                        : ""}
                    {socialActive.facebook ?
                        <span><img src={require('../../images/social/facebook.png')} alt="" style={{ height: 35, width: 35 }} /></span>
                        : ""
                    }
                </div>
            </td>
            <td className='text-capitalize'>{curElem.date}</td>
            <td className='text-capitalize'>{curElem.created}</td>
            <td className=''>
                {auth.user.isClient === 1 ? "" :
                    <span
                        className='roundBox'
                        title='Delete'
                        onClick={() => onDelete(curElem)}
                    >
                        <FaTrash />
                    </span>
                }
            </td>
            <SweetAlertTwo
                show={info.enable}
                title="Are You Sure?"
                text="You won't be able to revert this!"
                icon="warning"
                showCancelButton={true}
                confirmButtonColor='#b32f3d'
                cancelButtonColor='#2a2c3a'
                confirmButtonText='Yes, delete it!'
                confirmTitle='Deleting...'
                confirmText="This won't be revert!"
                confirmIcon='success'
                handlePerform={handleDelete}
                onCancel={handleCancle}
            />
        </tr>
    )
}

export default ScheduleRow

import produce from "immer"
const initialState = {
    data: {
        facebook: [],
        youtube: [],
        amazon: []
       
    }
}

export const SocialReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_SOCIAL_ACCOUNTS":
            return produce(state, (draft) => {
                draft.data.facebook = action.payload.facebook ? action.payload.facebook : []
                draft.data.youtube = action.payload.youtube ? action.payload.youtube : []
                draft.data.amazon = action.payload.awsAccount ? action.payload.awsAccount : []
            })
        default:
            return state
    }
}
import React, { useEffect, useState } from 'react'
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { MdOutlineAutoAwesomeMotion, MdOutlineAutoAwesomeMosaic, MdOutlineError } from "react-icons/md"
import { Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import modalCloseIcon from "../../images/modal-close.png";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { onAddCampName, onCreateAutoVideo, onCreateVideo, onPickChannel, onPickMode, onSelectCountry, onSelectLang } from '../../Actions/CreateVideoAction';
import { setAlert } from '../../Actions/AlertAction';
import Swal from 'sweetalert2';
const CreateVideoModal = ({ show, handleClose, language, countries }) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const youtube = useSelector(state => state.social.data.youtube)
  const createVideo = useSelector(state => state.createVideo)

  const [loader, setLoader] = useState(false)
  const [next, setNext] = useState({
    one: true,
    two: false,
    three: false
  })

  const handlePickChannel = (curElem) => {
    dispatch(onPickChannel(curElem.id))
  }

  const handleSelectMode = (type) => {
    dispatch(onPickMode(type))
  }

  const selectLanguage = (e) => {
    const lang = language.find(({ code }) => code === e.target.value)

    if (+lang.polly_support === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Warning...',
        text: `We do not have AI voice for this language, thus the video generated will not have any voiceover.`,
        confirmButtonColor: "#d03e3e"
      })
    }
    dispatch(onSelectLang(e.target.value))

  }

  const addCampName = (e) => {
    dispatch(onAddCampName(e.target.value))
  }
  const selectCountry = (e) => {
    dispatch(onSelectCountry(e.target.value))
  }

  const handleNext = (type) => {
    if (type === "general") {
      if (createVideo.language !== "" && createVideo.country !== "" && createVideo.name !== "") {
        setNext({
          ...next,
          one: false,
          two: true,
          three: false
        })

      }
      else {
        if (createVideo.name === "") {
          dispatch(setAlert("Please enter a campaign name to continue!", "danger"))
        } else if (createVideo.country === "") {
          dispatch(setAlert("Please select a country to continue!", "danger"))
        } else {
          dispatch(setAlert("Please select a language to continue!", "danger"))
        }
      }
    }
    else if (type === "channel") {
      if (createVideo.channel.length > 0) {
        setNext({
          ...next,
          one: false,
          two: false,
          three: true
        })
      }
      else {
        if (createVideo.language === "") {
          dispatch(setAlert("Please select a language to continue!", "danger"))
        }
        else if (createVideo.country === "") {
          dispatch(setAlert("Please select a country to continue!", "danger"))
        } else {
          dispatch(setAlert("Please select the channel to continue!", "danger"))
        }
      }
    }
    else if (type === "mode") {
      if (createVideo.mode !== "" && createVideo.channel.length > 0 && createVideo.language !== "" && createVideo.country !== "" && createVideo.name !== "") {
        handleCreate()
      }
      else {
        if (createVideo.language === "") {
          dispatch(setAlert("Please select a language to continue!", "danger"))
        }
        else if (createVideo.country === "") {
          dispatch(setAlert("Please select a country to continue!", "danger"))
        }
        else if (createVideo.channel.length === 0) {
          dispatch(setAlert("Please select the channel to continue!", "danger"))
        }
        else if (createVideo.name === "") {
          dispatch(setAlert("Please enter a campaign name to continue!", "danger"))
        }
        else {
          dispatch(setAlert("Please select mode to create a video!", "danger"))
        }
      }
    }
  }

  const handleCreate = () => {
    let data = {
      mode: createVideo.mode,
      channel: createVideo.channel,
      language: createVideo.language,
      country: createVideo.country,
      name: createVideo.name
    }
    let count = countries.find(({ country_name }) => country_name === data.country)
    data = {
      ...data,
      ttsLangCode: `${data.language}-${count.code}`
    }
    setLoader(true)
    dispatch(onCreateVideo(data, setLoader, navigate))
  }

  useEffect(() => {
    if (youtube.length === 1) {
      let data = {
        id: youtube[0].id
      }
      handlePickChannel(data)
    }
  }, [youtube])

  return (
    <Modal className="VideoModal dark" show={show} onHide={handleClose} centered>
      <Modal.Body>
        <div onClick={handleClose} className="vidClose">
          <img alt="" src={modalCloseIcon} />
        </div>
        <div>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Nav variant="h" className="siteTab">
              <Nav.Item onClick={() => setNext({
                ...next,
                one: true,
                two: false,
                three: false
              })}>
                <Nav.Link className={next.one ? "active" : ''} >General</Nav.Link>
              </Nav.Item>
              <Nav.Item onClick={() => setNext({
                ...next,
                one: false,
                two: true,
                three: false
              })}>
                <Nav.Link className={next.two ? "active" : ''} >Select a Channel</Nav.Link>
              </Nav.Item>
              <Nav.Item onClick={() => setNext({
                ...next,
                one: false,
                two: false,
                three: true
              })}>
                <Nav.Link className={next.three ? "active" : ''} >Select a Mode</Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane className={next.one ? 'show active' : ""}>
                <div>
                  <div className="channel alt">
                    <div className="row mt-3">
                      <div className='col-sm-12 cursor-pointer mb-2'>
                        <label>Campaign Name</label>
                        <input
                          className="simple-inp"
                          name="name"
                          placeholder='Enter campaign name'
                          value={createVideo.name}
                          onChange={addCampName}
                          required
                        />
                      </div>
                      <div className='col-sm-6 cursor-pointer'>
                        <label> Select Country</label>
                        <select
                          className="simple-inp"
                          name="country"
                          value={createVideo.country}
                          onChange={selectCountry}
                          required
                        >
                          <option value="">Select Country</option>
                          {countries.length > 0 ?
                            countries.map((curElem, index) => {
                              return (
                                <option
                                  key={index}
                                  value={curElem.country_name}
                                >
                                  {curElem.country_name}
                                </option>
                              )
                            })
                            : ""}
                        </select>
                      </div>
                      <div className='col-sm-6 cursor-pointer'>
                        <label> Select Language</label>
                        <select
                          className="simple-inp "
                          onChange={selectLanguage}
                          style={{ marginBottom: "30px" }}
                          value={createVideo.language}
                        >
                          <option value="">Select Language</option>
                          {language.length > 0 ?
                            language.map((curElem, index) => {
                              return (
                                <option key={index} value={curElem.code}>{curElem.name}</option>
                              )
                            })
                            : ""}
                        </select>
                      </div>
                    </div>
                    <div className="text-right"><button className="demoLink" onClick={() => handleNext("general")}
                    >
                      Next
                    </button>
                    </div>

                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane className={next.two ? 'show active' : ""}>
                <div>
                  <div className="channel alt">
                    <div className="row">
                      {youtube.length > 0 ?
                        youtube.map((curElem, index) => {
                          return (
                            <div
                              className="col-sm-6 cursor-pointer"
                              key={index}
                              onClick={() => handlePickChannel(curElem)}
                            >
                              <div className={`channel-single ${+createVideo.channel === +curElem.id ? "active" : ""}`}>
                                <div className="channel-top align-items-center">
                                  <div className="channel-img"><img src={curElem.image} alt="" /></div>
                                  <div className="channel-txt">
                                    <h2>{curElem.username}</h2>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })

                        :

                        <div class="alert alert-warning text-center mt-5" role="alert">
                          You haven't connected to any channel yet. Click <Link to={'/integration'} style={{ color: "#22a0eb" }} >Here</Link> to connect to the channel!
                        </div>
                      }
                    </div>

                    <div className="text-right">
                      <button className="demoLink" onClick={() => setNext({
                        ...next,
                        one: true,
                        two: false,
                        three: false
                      })}>Previous</button>
                      <button className="demoLink ml-2" onClick={() => handleNext("channel")}>Next</button></div>

                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane className={next.three ? 'show active' : ""}>
                <div>
                  <div className="channel alt">
                    <div className="row">
                      <div className="col-sm-6 " >
                        <div className={`channel-single cursor-pointer ${createVideo.mode === "auto" ? "active" : ""}`} onClick={() => handleSelectMode("auto")}>
                          <div className="channel-top align-items-center">
                            <div className="channel-img"><MdOutlineAutoAwesomeMotion /></div>
                            <div className="channel-txt">
                              <h2>Auto</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6" >
                        <div className={`channel-single cursor-pointer ${createVideo.mode === "manual" ? "active" : ""}`} onClick={() => handleSelectMode("manual")}>
                          <div className="channel-top align-items-center">
                            <div className="channel-img"><MdOutlineAutoAwesomeMosaic /></div>
                            <div className="channel-txt">
                              <h2>Manual</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className='col-sm-12 error-message-create-modal mt-2'>
                        {message.type === "mode" ? <><MdOutlineError size={16} style={{ paddingBottom: 2 }} /> {message.text} </> : ""}
                      </div> */}
                    </div>
                    <div className="text-right">
                      <button className="demoLink" onClick={() => setNext({
                        ...next,
                        one: false,
                        two: true,
                        three: false
                      })}>Previous</button>
                      <button className="demoLink ml-2" onClick={() => handleNext("mode")} >Next {loader ? <i className="fa fa-spinner fa-spin mx-1" /> : ""}</button>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>

          </Tab.Container>
        </div>
      </Modal.Body>
    </Modal >
  )
}

export default CreateVideoModal
import { commonAxios } from "../Global/CommonAxios"
import { setAlert } from "./AlertAction"

export const onCreateVideo = (data, setLoader, navigate) => (dispatch, getState) => {
    commonAxios("create-campaign", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (data.mode === "auto") {
                    navigate(`/auto?id=${res.data}`)
                } else {
                    navigate(`/manual?id=${res.data}`)
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            dispatch(setAlert(err.msg, "danger"))
        })
}


export const onFetchCamp = (data, setLoader, navigate) => (dispatch, getState) => {
    commonAxios("fetch-campaign", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "ON_SET_CAMP_DATA", payload: res.data })
            } else {
                dispatch(setAlert(res.msg, "danger"))
                navigate('/dashboard')
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
        })
}

export const onFetchAutoCamp = (data, setState, navigate, state) => (dispatch, getState) => {
    commonAxios("fetch-auto-camp", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let obj = { ...res.data }
                if (obj.cta === "") {
                    obj.cta = {
                        text: "",
                        pos: "beginning"
                    }
                }
                setState(obj)
            } else {
                dispatch(setAlert(res.msg, "danger"))
                navigate('/dashboard')
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onFetchAutoVideo = (data, state, setState) => (dispatch, getState) => {
    commonAxios("fetch-auto-video", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let obj = { ...res.data }
                setState({
                    ...state,
                    videos: obj.noOfVideo === "" ? 0 : obj.noOfVideo,
                    status: obj.status
                }
                )
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onSubmitAuto = (data, setLoader, navigate) => (dispatch, getState) => {
    commonAxios("auto-video", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`/campaigns`)
                dispatch(setAlert(res.msg, "success"))

            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
        })
}

export const onUpdateCampaign = (ep, data, navigate, path, setLoader, setPercent, interval) => (dispatch, getState) => {
    commonAxios(ep, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setPercent(100)
                clearInterval(interval)
                navigate(`/${path}?id=${data.cId}`)
                setLoader(false)
            } else {
                dispatch(setAlert(res.msg, "danger"))
                setPercent(0)
            }
            setLoader(false)
        }).catch((err) => {
            setPercent(0)
            console.log(err)
        })
}

export const onRenderAll = (data, navigate, setLoader) => (dispatch, getState) => {
    commonAxios("render-all", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`/campaigns`)
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
        })
}

export const onGetNiches = (data, state, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("search-niches", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arrData = res.data.data.map((curElem) => {
                    return {
                        name: curElem
                    }
                })
                let arr = arrData.sort((a, b) => {
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1
                    } else {
                        return -1
                    }
                })
                setState(arr)

            } else {
                dispatch(setAlert(res.msg, "danger"))
                setState([])
            }
            setLoader({
                ...loader,
                searchLoader: false
            })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                searchLoader: false
            })
            console.log(err)
        })
}

export const onLodeMore = (ep, data, niches, setNiches, loader, setLoader) => (dispatch, getState) => {
    commonAxios(ep, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arrData
                if (ep === "load-more-ideas") {
                    arrData = res.data.data.map((curElem) => {
                        return {
                            text: curElem
                        }
                    })
                    dispatch({ type: "ADD_MORE_NEW_IDEAS", payload: arrData })
                } else {
                    arrData = res.data.data.map((curElem) => {
                        return {
                            name: curElem
                        }
                    })
                    setNiches(niches.concat(arrData))
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                searchLoader: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                searchLoader: false
            })
        })
}

export const onUploadImage = (data, fetchImages, loader, setLoader) => (dispatch, getState) => {
    commonAxios("upload-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchImages()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                imageLoader: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                imageLoader: false
            })
        })
}

export const onFetchImages = (data, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-user-uploaded-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            } else {
                setState([])
            }
            setLoader({
                ...loader,
                fetchLoader: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                fetchLoader: false
            })
            console.log(err)
        })
}

export const onDeleteImage = (data, fetchImages, Swal) => (dispatch, getState) => {
    commonAxios("delete-user-uploaded-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchImages()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            Swal.close()
        }).catch((err) => {
            console.log(err)
            Swal.close()
        })
}

export const onGenerateImage = (data, index, setActiveEditor, setLoader, state, type, canvas) => (dispatch, getState) => {
    commonAxios("save-base", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (type === "auto") {
                    index({
                        ...state,
                        thumbnail: {
                            thum: res.data,
                            data: JSON.stringify(canvas.toJSON()),
                            templateId: -1
                        }
                    })
                } else {
                    let obj = {
                        thumbnail: res.data,
                        usedIndex: index,
                        data: JSON.stringify(canvas.toJSON())
                    }
                    dispatch({ type: "ON_USE_EDITOR_THUMB", payload: obj })
                }
                setActiveEditor(false)
            } else {
                dispatch(setAlert((res.msg, "danger")))
            }
            setLoader(false)
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader(false)
            console.log(err)
        })
}

export const onApplyThumbnail = (data, fetchCampaign, loader, setLoader, handleClose) => (dispatch, getState) => {
    commonAxios("change-template-thumbnail", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchCampaign()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                apply: false
            })
            handleClose()
        })
        .catch((err) => {
            setLoader({
                ...loader,
                apply: false
            })
            handleClose()
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}

export const onApplyTemplate = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("change-template-video", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                apply: false
            })
        })
        .catch((err) => {
            setLoader({
                ...loader,
                apply: false
            })
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}

export const onVideoDelete = (data, fetchData, Swal) => (dispatch, getState) => {
    commonAxios("delete-video", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchData()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            Swal.close()
        })
        .catch((err) => {
            Swal.close()
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}

export const onGenerateThumbnailUpdate = (data, thumbIndex) => (dispatch, getState) => {
    commonAxios("save-base", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let da = res.data
                let val = { thumbIndex, da }
                dispatch({ type: "SET_THUMBNAIL", payload: val })
            } else {
                dispatch(setAlert((res.msg, "danger")))
            }
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}

export const onUploadFile = (data,  setLoader, index) => (dispatch, getState) => {
    commonAxios("upload-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let data = { index: index, image: res.data.path }
                dispatch({
                    type: "SAVE_UPLOAD_IMAGE",
                    payload: data
                })
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            console.log(err)
        })
}

export const onUploadMedia = (data, fetchMedia, loader, setLoader, setPercent, type) => (dispatch, getState) => {
    const config = {
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            setPercent(percent);
        },
        headers: {
            'Authorization': getState().auth.token
        },
    }
    setLoader({
        ...loader,
        upload: true
    })
    commonAxios("upload-file", data, dispatch, false, config)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                if (type) {
                    fetchMedia(res.data.path, type, res.data.thumbnail)
                } else {
                    fetchMedia()
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setPercent(0)
            setLoader({
                ...loader,
                upload: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                upload: false
            })
            console.log(err)
        })
}

//No Axios Here

export const onAddCampName = (name) => (dispatch) => {
    dispatch({ type: "ON_ADD_CAMP_NAME", payload: name })
}

export const onPickChannel = (data) => (dispatch) => {
    dispatch({ type: "ON_SELECT_CHANNEL", payload: data })
}
export const onPickMode = (type) => (dispatch) => {
    dispatch({ type: "ON_SELECT_MODE", payload: type })
}

export const onChangeNicheData = (name, value) => (dispatch) => {
    let data = { name, value }
    dispatch({ type: "ON_CHANGE_NICHE_DATA", payload: data })
}

export const onSetSelectedIdeas = (arr) => (dispatch) => {
    dispatch({ type: "ON_ADD_IDEAS", payload: arr })
}

export const onSelectLang = (lang) => (dispatch) => {
    dispatch({ type: "ON_SELECT_LANGUAGE", payload: lang })
}
export const onSelectCountry = (country) => (dispatch) => {
    dispatch({ type: "ON_SELECT_COUNTRY", payload: country })
}

export const onSetMetaData = (arr) => (dispatch) => {
    dispatch({ type: "ON_SET_META", payload: arr })
}

export const onUpdateMetaCtaBegin = (text, type) => (dispatch) => {
    if (type === "begin") {
        dispatch({ type: "UPDATE_META_CTA_BEGIN", payload: text })
    } else if (type === "end") {
        dispatch({ type: "UPDATE_META_CTA_END", payload: text })
    } else {
        dispatch({ type: "UPDATE_META_CTA_BEGIN", payload: text })
        dispatch({ type: "UPDATE_META_CTA_END", payload: text })
    }
}

export const onUpdateScriptText = (text, index) => (dispatch) => {
    let data = { text, index }
    dispatch({ type: "UPDATE_SCRIPT_TEXT", payload: data })
}

export const onToggleTakingHead = (checked, index) => (dispatch) => {

    dispatch({
        type: "INCLUDE_HEAD_MOVEMENT",
        payload: { index: index, isChecked: checked }
    })
    dispatch({ type: "ON_TOGGLE_TALKING_HEAD", payload: checked })
}
export const onToggleHeadMovement = (checked, index) => (dispatch) => {
    dispatch({
        type: "INCLUDE_HEAD_MOVEMENT",
        payload: { index: index, isChecked: checked }
    })
    dispatch({ type: "ON_TOGGLE_HEAD_MOVEMENT", payload: checked })
}
export const onUpdateCoverage = (value, index) => (dispatch) => {
    dispatch({
        type: "UPDATE_COVERAGE",
        payload: { index: index, value: value }
    })
    //dispatch({ type: "ON_UPDATE_COVERAGE", payload: value })
}

export const onAddingTakingHeadImage = (path, type) => (dispatch) => {
    let data = { path, type }
    dispatch({ type: "UPDATE_TALKINGHEAD_IMAGE", payload: data })
}


export const onHandleChangePublish = (name, value, index, type) => (dispatch) => {
    let data = { name, value, index, type }
    dispatch({ type: "ON_CHANGE_PUBLISH", payload: data })
}

export const onSetAutoDateTime = (dt) => (dispatch) => {
    dispatch({ type: "ON_SET_AUTO_DT", payload: dt })
}

export const onUpdateThumb = (url, index) => (dispatch) => {
    let data = { url, index }
    dispatch({ type: "ON_UPDATE_THUMB", payload: data })
}

export const onRemoveFromSavedIdeas = (index) => (dispatch) => {
    dispatch({ type: "ON_REMOVE_FROM_SAVED", payload: index })
}

export const onEditThumbnail = (obj) => (dispatch) => {
    let data = {
        templateId: obj.templateId,
        type: obj.type,
        url: obj.thumbnail,
        data: JSON.parse(obj.data),
    }
    dispatch({ type: "ADD_DESIGN_DATA", payload: data })
}

/**Toggle TOGGLE_FACE_NARRATION ~Rehan */

export const addFaceNarrationData = (index, obj) => (dispatch) => {
    dispatch({
        type: "ADD_FACE_NARRATION_DATA",
        payload: { index: index, addFaceNarrationData: obj }
    })
}
export const toggleFaceNarration = (index, isToggle) => (dispatch) => {
    dispatch({
        type: "TOGGLE_FACE_NARRATION",
        payload: { index: index, toggle: isToggle }
    })
}

export const faceNarrationData = (index, data) => (dispatch) => {
    dispatch({
        type: "FACE_NARRATION_DATA",
        payload: { index: index, data: data }
    })
}
import React, { useEffect, useState } from 'react'
import { AiFillPlusCircle, AiFillHeart } from 'react-icons/ai';
import { onChooseNiches, onFetchUserNiches, onUserRemoveNiches } from '../../Actions/ChannelAction';
import { useDispatch } from 'react-redux';
import AddNiches from './AddNiches';
import ChannelStats from './ChannelStats';
import ProfitCalculator from './ProfitCalculator';
import { useSelector } from 'react-redux';

const DashboardSection1 = ({ fetchIdeas, fetchTopVideos, fetchKeyword }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [show, setShow] = useState(false)
    const [nichesData, setNichesData] = useState([])
    const [niches, setNiches] = useState([])
    const [loader, setLoader] = useState({
        fetch: false,
        add: false,
        selectedNiche: -1
    })

    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)


    const handleAdd = (val) => {
        if (+auth.user.isClient !== 1) {
            let data = {
                nicheId: val.id,
                name: val.name
            }

            const newArray = [...nichesData];
            const indexToModify = newArray.findIndex(item => +item.id === +val.id);
            if (indexToModify !== -1) {
                newArray[indexToModify].is_selected = true;

                let data = newArray.sort((a, b) => {
                    if (a.is_selected && !b.is_selected) {
                        return -1;
                    } else if (!a.is_selected && b.is_selected) {
                        return 1;
                    } else {
                        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                    }
                })
                setNiches(data);
            }

            let funcData = {
                fetchIdeas: fetchIdeas,
                fetchTopVideos: fetchTopVideos,
                fetchKeyword: fetchKeyword
            }
            dispatch(onChooseNiches("add-liked-niches", data, funcData))
        }
    }

    const handleRemove = (val) => {
        if (+auth.user.isClient !== 1) {
            let data = {
                id: val.id,
                type: val.generated_by
            }

            const newArray = [...nichesData];
            const indexToModify = newArray.findIndex(item => +item.id === +val.id);
            if (indexToModify !== -1) {
                newArray[indexToModify].is_selected = false;

                let data = newArray.sort((a, b) => {
                    if (a.is_selected && !b.is_selected) {
                        return -1;
                    } else if (!a.is_selected && b.is_selected) {
                        return 1;
                    } else {
                        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                    }
                })
                setNiches(data);
            }

            let funcData = {
                fetchIdeas: fetchIdeas,
                fetchTopVideos: fetchTopVideos,
                fetchKeyword: fetchKeyword
            }

            dispatch(onUserRemoveNiches("remove-niches", data, false, funcData))
        }
    }

    const fetchNiches = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(onFetchUserNiches(setNichesData, loader, setLoader))
    }
    useEffect(() => {
        fetchNiches()
    }, [])
    useEffect(() => {
        setNiches(nichesData)
    }, [nichesData])

    return (
        <div className="p_space">
            <div className="section-status">
                <div className="section-status-single niches">
                    <div className="info-box" style={{ height: "auto" }}>
                        <div className="info-box-head">
                            <h2><img src={require('../../images/niche.png')} />Top Niches</h2>
                            {+auth.user.isClient === 1 ? "" :
                                <AiFillPlusCircle
                                    size={25}
                                    cursor="pointer"
                                    color='#24abf4'
                                    onClick={handleShow}
                                />}
                        </div>
                        <div className="info-box-body">
                            <div className="niche-box">
                                <ul>
                                    {niches.length > 0 ?
                                        niches.map((curElem, index) => {
                                            return (
                                                <li key={index}>
                                                    <div className="single-niche video-table">
                                                        <p>{curElem.name}</p>
                                                        {!curElem.is_selected ?
                                                            <div
                                                                title="Add to repository"
                                                                onClick={() => handleAdd(curElem)}
                                                                className='wishIcon cursor-pointer'
                                                            >
                                                                <AiFillHeart style={{ position: "relative", top: "0.6px" }} />
                                                            </div>
                                                            :
                                                            <span
                                                                className="delIcon cursor-pointer"
                                                                onClick={() => handleRemove(curElem)}
                                                                title='Remove from repository'
                                                            >
                                                                <AiFillHeart />
                                                            </span>}
                                                    </div>
                                                </li>
                                            )
                                        })
                                        :
                                        <li className='text-center text-light'>
                                            {loader.fetch ?
                                                <i className="fa fa-spinner fa-spin" style={{ fontSize: 30, color: "#219fea" }} />
                                                : "No niches found"}
                                        </li>
                                    }

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-status-single calculator">
                    <ProfitCalculator />
                </div>

                <ChannelStats />

                <AddNiches
                    show={show}
                    handleClose={handleClose}
                    fetchNiches={fetchNiches}
                />
            </div>
        </div>
    )
}

export default DashboardSection1
import React, { useState, useEffect } from 'react';
import { SketchPicker } from 'react-color';
import { useDispatch } from 'react-redux';
import rgbHex from "rgb-hex";
import hexRgb from "hex-rgb";
import { colorObj } from '../../../Global/FontFamilyData';
import { onFontBgColor, onFontColor, removeFontBgColor } from '../../../Actions/contentAction';

const ColorPicker = ({ colorCode, selectedSlide, slideIndex, type, setSlideAll }) => {
    const dispatch = useDispatch()
    const textIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)

    const [color, setColor] = useState({
        enable: false,
        r: 0,
        g: 0,
        b: 0
    });

    useEffect(() => {
        if (colorCode) {
            if (colorCode.includes('#')) {
                let hex = hexRgb(colorCode)
                setColor({
                    ...color,
                    r: hex.red,
                    g: hex.green,
                    b: hex.blue
                })
            } else {
                let hex = rgbHex(colorCode)
                hex = hexRgb(hex)
                setColor({
                    ...color,
                    r: hex.red,
                    g: hex.green,
                    b: hex.blue
                })
            }
        }
    }, [colorCode])

    const onInputColor = (e) => {
        let rgba = `rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a.toFixed(2)})`
        let hex = `#${rgbHex(rgba)}`

        if (type === 'textColor') {
            dispatch(onFontColor(hex, slideIndex, textIndex));
            setSlideAll(false);
        }
        else if (type === 'bgColor') {
            dispatch(onFontBgColor(hex, slideIndex, textIndex));
            setSlideAll(false);
        }
    }

    const onChangeColor = (color, index) => {
        if (index === 0 && type === 'bgColor') {
            removeBgColor()
            setSlideAll(false);
        } else {
            let hex = `#${rgbHex(color.val)}`
            if (type === 'textColor') {
                dispatch(onFontColor(hex, slideIndex, textIndex));
                setSlideAll(false);
            }
            else if (type === 'bgColor') {
                dispatch(onFontBgColor(hex, slideIndex, textIndex));
                setSlideAll(false);
            }
        }

    }

    const removeBgColor = () => {
        setSlideAll(false);
        setColor({
            ...color,
            r: 0,
            g: 0,
            b: 0
        })
        dispatch(removeFontBgColor(slideIndex, textIndex));
    }

    return (
        <>
            <div className="fontStyle-block">
                <div className="row">
                    <div className="col-6">
                        <div className="inpWrap">
                            <input
                                className="pl-5 text-uppercase"
                                type="text"
                                value={colorCode ? colorCode : ""}
                                readOnly
                            />
                            <span
                                className="colorValue cursor-pointer"
                                id="t-color-btn"
                                style={{ background: colorCode ? colorCode : "" }}
                                onClick={() => setColor({
                                    ...color,
                                    enable: true
                                })}
                            ></span>
                            <span className="label">HEX</span>
                            {color.enable ?
                                <>
                                    <div className="swatch-cover"
                                        onClick={(e) => setColor({
                                            ...color,
                                            enable: false
                                        })}
                                    />
                                    <SketchPicker
                                        color={colorCode} onChange={(e) => onInputColor(e)}
                                    />
                                </>
                                : null}
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="inpWrap">
                            <input className="text-center" type="text" value={color.r} readOnly />
                            <span className="label">R</span>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="inpWrap">
                            <input className="text-center" type="text" value={color.g} readOnly />
                            <span className="label">G</span>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="inpWrap">
                            <input className="text-center" type="text" value={color.b} readOnly />
                            <span className="label">B</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="color-palette">
                <ul>
                    {colorObj.map((curElem, index) => {
                        return (
                            <li key={index} style={{ position: "relative", overflow: "hidden" }}>
                                <div
                                    style={{ background: curElem.val }}
                                    className="color-palette-single cursor-pointer"
                                    onClick={() => onChangeColor(curElem, index)}

                                >
                                    {index === 0 && type === 'bgColor' ? <div className="remove_line"></div> : ""}

                                </div>
                            </li>
                        )
                    })}


                </ul>
            </div>
        </>
    );
}

export default ColorPicker;

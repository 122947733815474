import React, { useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs"
import { Link, useLocation, useNavigate } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import modalCloseIcon from "../../images/modal-close.png";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { onFetchCamp, onSetAutoDateTime, onUpdateCampaign } from "../../Actions/CreateVideoAction";
import PublishingCard from "./PublishingCard";

let interval = false
const Publishing = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    let date = new Date()
    date = date.toJSON()
    let date1 = date.slice(0, 10)
    let time1 = date.slice(11, 16)

    const createVideo = useSelector(state => state.createVideo)
    const videoId = queryString.parse(location.search).id

    const [pageLoader, setPageLoader] = useState(true)
    const [saveLoader, setSaveLoader] = useState(false)
    const [show, setShow] = useState(false);
    const [progress, setProgress] = useState(0)
    const [auto, setAuto] = useState({
        time: time1,
        date: date1
    })

    const handleClose = () => setShow(false);
    const handleSetAuto = () => {
        let dateTime = auto.date + "T" + auto.time
        dispatch(onSetAutoDateTime(dateTime))
        handleClose()
    }

    const handleAuto = (e) => {
        let { name, value } = e.target
        setAuto({
            ...auto,
            [name]: value
        })
    }

    const onGetProgress = () => {
        let prog = 0
        interval = setInterval(() => {
            prog += 1;
            if (prog > 99) {
                clearInterval(interval);
                setProgress(99)
                prog = 0
            } else {
                setProgress(prog)
            }
        }, 50)
    }

    const handleNext = () => {
        let ep = "update-publishing-campaign"
        let data = { publishing: createVideo.publishing, cId: createVideo.id }
        setSaveLoader(true)
        onGetProgress()
        dispatch(onUpdateCampaign(ep, data, navigate, "thumbnail", setSaveLoader, setProgress, interval))
    }

    const fetchCampaign = () => {
        let data = {
            campaignId: videoId
        }
        dispatch(onFetchCamp(data, setPageLoader, navigate))
    }

    useState(() => {
        if (videoId) {
            fetchCampaign()
        }
    }, [videoId])

    return (
        !pageLoader ?
            <>
                <TitleBar title="Design" />
                <Navbar />
                <section className="siteWrap">
                    <div className="siteWrap-in" style={{ paddingTop: "32px" }}>

                        <div className="conatiner-fluid">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-md-11">
                                    <div className=" business-heading " style={{ marginBottom: "15px", flexFlow: "wrap", border: "none" }}>
                                        <div className="row align-items-center" style={{ width: "100%" }}>
                                            <div className="col-md-6" >
                                                <h2>Publishing</h2>
                                            </div>

                                            <div className="col-md-6 mt-md-0 mt-sm-2 mt-2">
                                                <div className="d-flex justify-content-end align-items-center">
                                                    <Link className="demoLink mt-0" to={`/review-scripts?id=${videoId}`} style={{ padding: "14px 32px 14px 10px" }}> <BsArrowLeft />Go To Scripts</Link>
                                                    <button onClick={handleNext} className="demoLink ml-2 mt-0" style={{ padding: "14px 16px 14px 32px" }}> Go To Thumbnail {saveLoader ? <i className="fa fa-spinner fa-spin mx-1" /> : <BsArrowRight />} </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="progress" style={{ height: 5, background: "#424b6c", marginBottom: "15px" }}>
                                        <div class="progress-bar" role="progressbar" aria-valuenow={progress}
                                            aria-valuemin="0" aria-valuemax="100" style={{ width: `${progress}%`, background: "#22a0eb" }}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-12">

                                    <div className="site-accordion">
                                        <Accordion defaultActiveKey="0">
                                            {createVideo.publishing.length > 0 ?
                                                createVideo.publishing.map((curElem, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <PublishingCard curElem={curElem} index={index} />
                                                        </React.Fragment>
                                                    )
                                                })
                                                : ""}
                                        </Accordion>
                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>
                </section >
                <Footer />

                <Modal className="VideoModal dark" show={show} onHide={handleClose} centered>
                    <Modal.Body>
                        <div onClick={handleClose} className="vidClose">
                            <img alt="" src={modalCloseIcon} />
                        </div>
                        <div>
                            <div className="inp-group alt mt-0">
                                <label htmlFor="">Date</label>
                                <div className="position-relative">
                                    <input
                                        className="simple-inp"
                                        type="date"
                                        name="date"
                                        placeholder="DD/MM/YYYY"
                                        value={auto.date}
                                        onChange={handleAuto}

                                    />
                                </div>
                            </div>
                            <div className="inp-group alt">
                                <label htmlFor="">Time</label>
                                <div className="position-relative">
                                    <input
                                        className="simple-inp"
                                        type="time"
                                        name="time"
                                        placeholder="HH:MM"
                                        value={auto.time}
                                        onChange={handleAuto}
                                    />
                                </div>
                            </div>
                            <div className="text-right foot-button">
                                <button className="demoLink ml-2" onClick={handleSetAuto}>Apply</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

            </>
            : <div className="loader-sec">
                <div className="loader">
                </div>
            </div>
    )
}
export default Publishing;
import produce from "immer"
const initialState = {
    data: false
}

export const DesignReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_DESIGN_DATA":
            return produce(state, (draft) => {
                let obj = { ...action.payload }
                obj.data = JSON.stringify(obj.data)
                obj.data = obj.data.replaceAll(`"crossOrigin":null`, `"crossOrigin" : "*"`)
                obj.data = JSON.parse(obj.data)
                draft.data = obj
            })

        case "ON_UNMOUNT_DESIGN":
            return produce(state, (draft) => {
                draft.data = false
            })
        default:
            return state
    }
}
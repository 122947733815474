import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import { onDeleteMediaUpload, onFetchUploads, onUploadImagesFile } from '../../Actions/DesignActions'
import { BsTrash3 } from 'react-icons/bs'
import SweetAlertTwo from '../SweetAlertTwo'

const UploadSearch = ({ addImage, canvas, }) => {
    const dispatch = useDispatch()
    const [imageList, setImageList] = useState([])
    const [selected, setSelected] = useState(false)
    const [loader, setLoader] = useState({
        upload: false,
        fetch: false
    })

    const [info, setInfo] = useState({
        id: -1,
        enable: false
    })

    const fetchImageList = () => {
        let data = {
            type: "images",
        }
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(onFetchUploads(data, setImageList, loader, setLoader))
    }
    const onUploadFile = (e) => {
        let imageType = ['image/png', 'image/jpg', 'image/jpeg']
        const fileData = e.target.files[0]
        if (imageType.includes(fileData.type)) {
            if (fileData.size < 5000000) {
                setLoader({
                    ...loader,
                    upload: true
                })
                const formData = new FormData()
                formData.append('upload_type', "images")
                formData.append('file', fileData)

                dispatch(onUploadImagesFile(formData, fetchImageList, loader, setLoader))
            } else {
                Swal.fire({
                    icon: "error",
                    title: 'Oops...',
                    text: "Max allowed size is 5MB!",
                    confirmButtonColor: "#d03e3e"
                })
            }
        } else {
            Swal.fire({
                icon: "error",
                title: 'Oops...',
                text: "This file type is not valid. Please use JPG, JPEG or PNG file only!",
                confirmButtonColor: "#d03e3e"
            })
        }
    }

    const onImageClick = (url, index) => {
        setSelected(index)
        addImage(canvas, url)
    }

    const deleteMedia = (e, iId) => {
        e.stopPropagation()
        setInfo({
            ...info,
            id: iId,
            enable: true
        })
    }

    const onPerformDelete = (Swal) => {
        let formData = new FormData()
        formData.append("id", info.id)
        dispatch(onDeleteMediaUpload(formData, fetchImageList, Swal))
        setInfo({
            ...info,
            id: -1,
            enable: false
        })
    }

    const handleCancel = () => {
        setInfo({
            ...info,
            id: -1,
            enable: false
        })
    }

    useEffect(() => {
        fetchImageList()
    }, [])


    return (
        <>
            <div className="uplaod-img mt-3" style={{ cursor: "pointer", height: "52px", position: "relative" }}>
                <div className="upload_text">
                    {loader.upload ? <i style={{ fontSize: "20px", color: "#fff" }} className="fa fa-spinner fa-spin mr-2" /> : <><i className="fa fa-cloud-upload mr-2" /> Upload an image</>}
                </div>
                <input
                    readOnly
                    onChange={(e) => onUploadFile(e)}
                    id="upload"
                    className={`img-upload-input ${loader.imageLoader ? 'hide-social' : ''}`}
                    type="file"
                    accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG"
                />
            </div>
            <hr style={{ color: "#767474", opacity: "1" }} />
            {
                imageList.length > 0 ?
                    <div className="Image_upload_search" >
                        <div className="row mt-2">
                            {
                                imageList.map((item, index) => {
                                    return (
                                        <div className="col-md-4 mt-1 p-1" key={index} >
                                            <div className="img-part cursor-pointer" onClick={() => onImageClick(item.url, index)} style={{ position: "relative", height: "100px", width: "100%", border: "1px solid #1882d9", borderRadius: 5 }}>
                                                <BsTrash3
                                                    size={15}
                                                    style={{ position: "absolute", top: 1, right: 1, zIndex: 1, }}
                                                    className='remove-media'
                                                    onClick={(e) => deleteMedia(e, item.id)}
                                                />
                                                <img src={item.url} style={{ width: "100%", height: "100%", objectFit: "contain", borderRadius: 10 }} alt="" />
                                                {
                                                    selected === index ?
                                                        <div
                                                            style={{
                                                                color: "#1882d9",
                                                                top: 0,
                                                                fontSize: '25px',
                                                                position: 'absolute',
                                                                height: "100%",
                                                                width: "100%",
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}
                                                        >
                                                            <i className="far fa-check-circle" />
                                                        </div> : ''
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div >
                    :
                    <div className='text-center'>
                        {loader.fetch ?
                            <i style={{ fontSize: "30px", color: "#25aaf1" }} className="fa fa-spinner fa-spin" /> :
                            <p className=" no_upload">You do not have any uploads</p>}
                    </div>
            }
            <SweetAlertTwo
                show={info.enable}
                title="Are You Sure?"
                text="You won't be able to revert this!"
                icon="warning"
                showCancelButton={true}
                confirmButtonColor='#b32f3d'
                cancelButtonColor='#2a2c3a'
                confirmButtonText='Yes, delete it!'
                confirmTitle='Deleting...'
                confirmText="This won't be revert!"
                confirmIcon='success'
                handlePerform={onPerformDelete}
                onCancel={handleCancel}
            />
        </>
    )
}

export default UploadSearch
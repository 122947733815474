import React, { useEffect, useMemo, useState } from 'react'
import { AbsoluteFill, Video, Img, Sequence, OffthreadVideo } from 'remotion';
import 'animate.css/animate.min.css';
import { Animation } from 'remotion-animation';

const VideoLayer = ({ src, layer, multiplyNum }) => {

    const [loader, setLoader] = useState(false)

    const increaseSize = (size) => {
        if (size) {
            let matches = size.match(/(\d+)/);
            let actualSize = parseInt(matches[0]) * multiplyNum;
            return `${actualSize}px`
        }
    }


    useEffect(() => {
        if (layer.type === "video") {
            setLoader(true)
        }
    }, [layer.type]);

    return (
        <Sequence from={Number(layer.animation.delay.toString().replace("s", "")) * 30 + 30}>
            <AbsoluteFill>
                {
                    loader ?
                        <div className='loader-center-temp1' style={{ position: "absolute", left: layer.position.left * multiplyNum, top: layer.position.top * multiplyNum, height: layer.size.height * multiplyNum, width: layer.size.width * multiplyNum }} >
                            <i className="fa fa-spinner fa-spin" style={{ fontSize: "25px" }} />
                        </div> : ""
                }
                <Animation style={{ position: "absolute", height: layer.size.height * multiplyNum, width: layer.size.width * multiplyNum, left: layer.position.left * multiplyNum, top: layer.position.top * multiplyNum }} duration={Number(layer.animation.duration.toString().replace("s", ""))} animateName={layer.animation.name.replace("animate__", "")} >
                    {layer.type === "video" ?
                        <Video
                            key={src}
                            style={{ objectFit: "contain", height: "100%", width: "100%" }}
                            src={src}
                            // startFrom={0}
                            // endAt={duration}
                            volume={1}
                            playbackRate={2} //speed
                            // quality="draft"
                            muted={true}
                            loop={true}
                            onError={(event) => {
                                // Handle image loading error here
                                console.log("error")
                            }}
                            onLoadedData={() => setLoader(false)}
                        />
                        :
                        < Img
                            key={src}
                            style={{
                                height: "100%",
                                width: "100%",
                                borderRadius: `${layer.style.borderRadius?.includes("%") ? layer.style.borderRadius : increaseSize(layer.style.borderRadius)}`,
                                border: `${layer.style.border}`,
                                borderWidth: `${increaseSize(layer.style.border)}`
                            }}
                            src={src}
                            onError={(event) => {
                                // Handle image loading error here
                                console.log("error")
                            }}
                        />
                    }
                </Animation>
            </AbsoluteFill >
        </Sequence>
    )
}

export default VideoLayer

import React, { useEffect } from 'react'
import { Accordion } from 'react-bootstrap'
import { onHandleChangePublish } from '../../Actions/CreateVideoAction'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import { useSelector } from 'react-redux'

const PublishingCard = ({ curElem, index }) => {

    const social = useSelector(state => state.social.data)

    const dispatch = useDispatch()
    let date = new Date()
    date = date.toJSON()
    let dateTime = date.slice(0, 16)

    const [toggle, setToggle] = useState(false)
    const [showPop, setShowPop] = useState("")

    const handleShowPop = (e) => {
        setShowPop(e.target.value)
    }

    const handleToggle = () => {
        setToggle(!toggle)

    }

    const handleChange = (e, index, type) => {
        let { name, value, checked } = e.target
        if (name === "enable") {
            value = checked
        }
        dispatch(onHandleChangePublish(name, value, index, type))
    }

    return (
        <>
            <Accordion.Item eventKey={index}>
                <Accordion.Header>Video #{index + 1}</Accordion.Header>
                <Accordion.Body>
                    <div className="publish-group">
                        <ul>
                            <li className="d-block" style={{ border: "none" }}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <span className={`publish-icon ${social.youtube.length > 0 ? curElem.youtube.enable ? "" : "disable-img" : "disable-img"} `}><img src={require('../../images/social/youtube.png')} /> Youtube</span>
                                    <div className="modal-txt-mid mb-3">
                                        <div className="d-flex mode_wrap">
                                            <label className="switch">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="enable"
                                                    checked={social.youtube.length > 0 ? curElem.youtube.enable : false}
                                                    disabled={social.youtube.length > 0 ? false : true}
                                                    onChange={(e) => handleChange(e, index, "yt")}
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <hr style={{ opacity: "1", color: "#424b6c", position: "relative", top: "-14px" }} />
                                {social.youtube.length > 0 ? curElem.youtube.enable ?
                                    <>
                                        <div className="d-flex justify-content-between align-items-center" style={{ marginTop: "-21px", padding: "0 41px" }}>
                                            <span style={{ color: "#fff" }}>Publish Date</span>
                                            <span className="publish-input " >
                                                <input
                                                    className="simple-inp cursor-pointer"
                                                    type="datetime-local"
                                                    placeholder="19/04/2023"
                                                    name="publishData"
                                                    min={dateTime}
                                                    onChange={(e) => handleChange(e, index, "yt")}
                                                    value={curElem.youtube.publishData !== "" ? curElem.youtube.publishData : dateTime}
                                                />
                                            </span>


                                        </div>

                                        {/* add playlist */}

                                        <div className=" inpLabel d-flex locateBox justify-content-between" style={{ padding: "0 41px" }}>
                                            <label htmlFor="" style={{ color: "#fff", fontSize: "14px", fontWeight: "500" }}>Add to Playlist</label>

                                            <div className="d-flex mode_wrap">
                                                <label className="switch">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={toggle}
                                                        onChange={handleToggle}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>

                                        </div>
                                        <hr style={{ opacity: "1", color: "#fff", padding: "0 41px", margin: "0 40px" }} />
                                        <div className="d-flex align-items-center" style={{ paddingRight: "40px" }}>
                                            {toggle ?
                                                <>
                                                    <select class="form-select  publish_from simple-inp mt-3 mb-3" aria-label=".form-select-lg example" onChange={handleShowPop} value={showPop} style={{ margin: "0 41px" }}>
                                                        <option value="">Open this select menu</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3" >Create new Playlist</option>
                                                    </select>

                                                    {showPop === "3" ?

                                                        <div style={{ width: "50%" }}>

                                                            <input
                                                                className="publish_from simple-inp"
                                                                type="text"
                                                                placeholder="Enter your playlist name "
                                                            />
                                                        </div>

                                                        : ""}
                                                </>

                                                : ""
                                            }

                                        </div>
                                    </>

                                    : "" : <div className='text-center'> <span className='text-white'>Your Youtube Account Is Not Connected Please Connect Your Youtube Account</span></div>
                                }
                            </li>

                            <li className="d-block" style={{ border: "none" }}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <span className={`publish-icon ${social.facebook.length > 0 ? curElem.facebook.enable ? "" : "disable-img" : "disable-img"} `}><img src={require('../../images/social/facebook.png')} /> Facebook</span>
                                    <div className="modal-txt-mid mb-3">

                                        <div className="d-flex mode_wrap">
                                            <label className="switch">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="enable"
                                                    checked={social.facebook.length > 0 ? curElem.facebook.enable : false}
                                                    disabled={social.facebook.length > 0 ? false : true}
                                                    onChange={(e) => handleChange(e, index, "fb")}

                                                />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <hr style={{ opacity: "1", color: "#424b6c", position: "relative", top: "-14px" }} />
                                {social.facebook.length > 0 ? curElem.facebook.enable ?
                                    <div className="d-flex justify-content-between align-items-center" style={{ marginTop: "-21px", padding: "0 41px" }}>
                                        <span style={{ color: "#fff" }}>Publish Date</span>
                                        <span className="publish-input">
                                            <input
                                                className="simple-inp cursor-pointer"
                                                type="datetime-local"
                                                placeholder="19/04/2023"
                                                name="publishData"
                                                onChange={(e) => handleChange(e, index, "fb")}
                                                value={curElem.facebook.publishData !== "" ? curElem.facebook.publishData : dateTime}
                                            />
                                        </span>
                                    </div> : ""
                                    : <div className='text-center'> <span className='text-white'>Your Facebook Account Is Not Connected Please Connect Your Facebook Account</span></div>
                                }
                            </li>
                        </ul>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </>
    )
}

export default PublishingCard

import React, { useEffect, useState } from 'react'
import TitleBar from '../../TitleBar'
import Navbar from '../../Navbar'
import Footer from '../../Footer'
import { BsArrowRight } from "react-icons/bs"
import { BiChevronLeft } from "react-icons/bi"

import Form from 'react-bootstrap/Form';
import { setAlert } from '../../../Actions/AlertAction'
import { useDispatch } from 'react-redux'
import AutoCreateTwo from './AutoCreateTwo'
import { onFetchAutoCamp } from '../../../Actions/CreateVideoAction'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from "query-string"


const AutoCreateVideo = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()

    const id = queryString.parse(location.search).id

    const [next, setNext] = useState(false)
    const [state, setState] = useState({
        name: "",
        language: "",
        country: "",
        channel: "",
        nicheName: "",
        noOfVideo: 1,
        thumbnail: {
            id: -1,
            thumb: "",
            data: false
        },
        videoTemplate: {
            id: -1,
            image: ""
        },
        cta: {
            text: "",
            pos: "beginning"
        }
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (state.noOfVideo > 0) {
            setNext(true)
        } else {
            dispatch(setAlert("Please provide no of videos more than 0", "danger"))
        }
    }

    const fetchAutoCamp = () => {
        let data = {
            id: id
        }
        dispatch(onFetchAutoCamp(data, setState, navigate))
    }


    useEffect(() => {
        fetchAutoCamp()
    }, [])
    return (
        <>
            <TitleBar title="Create Campaign" />
            <Navbar />

            <div className="siteWrap">
                <div className="siteWrap-in siteWrapN" style={{ paddingTop: '32px' }}>
                    <div className="container-fluid p_space">
                        <div className="row">
                            <div className="col-md-12">

                                <section className="connection">
                                    <div className="integr-wrap pt-0">
                                        <h2 onClick={() => setNext(false)} className='cursor-pointer'>{next ? <BiChevronLeft size={40} /> : ""}Auto Create Video</h2>
                                    </div>
                                </section>
                                {!next ?
                                    <div className="container-fluid">
                                        <div className="row">
                                            <form className="col-md-12" onSubmit={handleSubmit}>

                                                <div className="Auto_create_wrapper">
                                                    <section className="connection">
                                                        <div className="integr-wrap pt-0">
                                                            <h4>Input your niche</h4>
                                                        </div>
                                                    </section>

                                                    <div className="col-md-12 mt-3">
                                                        <div className="auto_niche_input">
                                                            <input
                                                                className="simple-inp"
                                                                type="text"
                                                                name="nicheName"
                                                                value={state.nicheName}
                                                                placeholder="Enter Niche"
                                                                onChange={handleChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>

                                                    <section className="connection mt-4">
                                                        <div className="integr-wrap pt-0">
                                                            <h4 >How many videos you want to create this month </h4>
                                                        </div>
                                                    </section>

                                                    <div className="col-md-12 mt-3">
                                                        <div className="auto_niche_input">
                                                            <Form.Control
                                                                type="number"
                                                                placeholder="Enter number of videos"
                                                                name='noOfVideo'
                                                                style={{ background: "#1f2437" }}
                                                                onChange={handleChange}
                                                                value={state.noOfVideo}
                                                                min={1}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="auto_nich_btn text-end">
                                                        <button
                                                            className="demoLink ml-2 mt-0"
                                                            style={{ padding: "14px 16px 14px 32px" }}
                                                            type='submit'
                                                        > Next
                                                            <BsArrowRight />
                                                        </button>
                                                    </div>
                                                </div>

                                            </form>
                                        </div>


                                    </div>
                                    :

                                    <AutoCreateTwo
                                        state={state}
                                        setState={setState}
                                    />
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div >


            < Footer />
        </>
    )
}

export default AutoCreateVideo

import React, { useState, useEffect } from 'react'
import modalCloseIcon from "../../images/modal-close.png";
import { Modal, Nav, Tab } from "react-bootstrap";
import { BsCheckCircle } from 'react-icons/bs';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import UploadImage from './UploadImage';
import { onSearchMedia } from '../../Actions/contentAction';
import { onUpdateThumb } from '../../Actions/CreateVideoAction';

const UploadModal = ({ show, handleClose, activeIndex, setState, state, type }) => {
    const dispatch = useDispatch()
    let date = new Date()
    let h = date.getHours() <= 9 ? `0${date.getHours()}` : date.getHours()
    let m = date.getMinutes() <= 9 ? `0${date.getMinutes()}` : date.getMinutes()
    let s = date.getSeconds() <= 9 ? `0${date.getSeconds()}` : date.getSeconds()
    let time = `${h}:${m}:${s}`

    const [imageList, setImageList] = useState([])
    const [msg, setMsg] = useState("")
    const [search, setSearch] = useState({
        keyword: '',
        search_type: 'image',
        page: 1
    });
    const [selected, setSelected] = useState({
        data: {
            url: ""
        },
        index: -1
    })
    const [loader, setLoader] = useState({
        saveLoader: false,
        fetchLoader: false
    })

    const handleSearch = (val) => {
        setSearch({
            ...search,
            keyword: val
        })

        if (msg !== "") {
            setMsg("")
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (imageList.length > 0) {
            setImageList([])
        }
        fetchImages()
    }

    const fetchImages = (page = 1) => {
        let data = {
            keyword: search.keyword,
            search_type: search.search_type,
            page_number: page
        }
        setLoader({
            ...loader,
            fetchLoader: true
        })

        dispatch(onSearchMedia(data, imageList, setImageList, setLoader, search, setSearch, page, setMsg))
    }

    const onChooseImage = (curElem, index) => {
        setSelected({
            ...selected,
            data: curElem,
            index: index
        })
    }
    const handleSave = () => {
        if (selected.data.url !== "") {
            if (type === "auto") {
                setState({
                    ...state,
                    thumbnail: {
                        thum: selected.data.url,
                        data: false,
                        id: -1
                    }
                })

                handleClose()
            } else {
                let data = selected.data.url + `?${time}not-from-cache-please`
                dispatch(onUpdateThumb(data, activeIndex))
                handleClose()
            }
        }
    }

    useEffect(() => {
        return () => {
            setImageList([])
            setSelected({
                ...selected,
                data: {
                    url: ""
                },
                index: -1
            })
            setSearch({
                ...search,
                keyword: '',
                search_type: 'image',
                page: 1
            })
        }
    }, [handleClose])

    return (
        <Modal className="VideoModal dark" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div onClick={handleClose} className="vidClose">
                    <img alt="" src={modalCloseIcon} />
                </div>

                <div className="imgUp">
                    <Tab.Container defaultActiveKey="first">
                        <Nav variant="h">
                            <Nav.Item>
                                <Nav.Link eventKey="first">Library</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second">Upload</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <div className="imgUp-main">
                                    <form className="img-search" onSubmit={handleSubmit}>
                                        <input
                                            type="text"
                                            placeholder="Search Image"
                                            value={search.keyword}
                                            onChange={(e) => handleSearch(e.target.value)}
                                        />
                                    </form>
                                    <InfiniteScroll
                                        dataLength={imageList.length} //This is important field to render the next data
                                        next={() => fetchImages(search.page + 1)}
                                        hasMore={true}
                                        scrollableTarget={`scrollableImageModal`}
                                    >
                                        <div className="img-search-scroll" style={{ minHeight: "350px" }} id='scrollableImageModal'>
                                            <ul >
                                                {imageList.length > 0 ?
                                                    imageList.map((curElem, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <div
                                                                    className="imgUp-single cursor-pointer"
                                                                    style={{ border: selected.index === index ? "2px solid #22a2ec" : "" }}
                                                                    onClick={() => onChooseImage(curElem, index)}
                                                                >
                                                                    <img src={curElem.thumbnail} alt={` ${search.keyword} Image ${index}`} />
                                                                    <div className="imgStat"><BsCheckCircle /></div>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                    : <div className='text-center pt-3' style={{ margin: "auto" }}>
                                                        {msg}
                                                    </div>}
                                            </ul>
                                            {loader.fetchLoader ?
                                                <div className='text-center mt-3'>
                                                    <i className="fa fa-spinner fa-spin" style={{ fontSize: "30px", color: "#1478d3" }} />
                                                </div>
                                                : ""}
                                        </div>
                                    </InfiniteScroll>

                                </div>
                                <div className="btnTab">
                                    <button
                                        className="create-modal-btn"
                                        onClick={handleSave}
                                        disabled={selected.data.url === "" ? true : false}
                                        style={{ background: selected.data.url === "" ? "gray" : "" }}
                                    > {loader.saveLoader ? <>Using <i className="fa fa-spinner fa-spin mx-1" /></> : "Use"}</button>
                                    <button className="create-modal-btn ml-2" onClick={handleClose}>Cancel</button>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <UploadImage
                                    handleClose={handleClose}
                                    activeIndex={activeIndex}
                                    selected={selected}
                                    setSelected={setSelected}
                                    setState={setState}
                                    state={state}
                                    type={type}
                                />
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default UploadModal
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { onTemplateFetch, onUpdateTemplate } from '../../../Actions/contentAction'

const Template = ({ setLoader }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const auth = useSelector(state => state.auth)
    const campaign = useSelector(state => state.content.campaign)

    const [memberShip, setMemberShip] = useState([])
    const [temp, setTemp] = useState([])
    const [tempLoader, setTempLoader] = useState(false)


    const fetchTemp = () => {
        if (memberShip.length > 0) {
            setTempLoader(true)
            dispatch(onTemplateFetch(setTemp, setTempLoader, memberShip))
        }
    }

    const handleSelectTemplate = (id) => {
        if (campaign.templateId !== id) {
            let data = {
                videoId: campaign.id,
                newTemplateId: id,
            }
            dispatch(onUpdateTemplate(data, navigate, setLoader))
        }
    }

    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split("__"))
        }
    }, [auth.user])

    useEffect(() => {
        fetchTemp()
    }, [memberShip])


    return (
        <div className="add-block-single">
            <div className="add-block-main text-center">
                <div className="add-block-title mb-4">
                    <h2>Templates</h2>
                </div>
                {!tempLoader ?
                    <ul className='tempList'>
                        {
                            temp.length > 0 ?
                                temp.map((curElem, index) => {
                                    return (
                                        <li
                                            key={index}
                                            title={curElem.name}
                                            style={{ cursor: "pointer", position: "relative", textAlign: "center" }}
                                            onClick={() => handleSelectTemplate(curElem.id)}
                                        >
                                            <div className='tempList-single p-0' style={{ border: +campaign.templateId === +curElem.id ? "2px solid #23a5ee" : "1px solid #05060a" }}>
                                                <div className='tempList-img' >
                                                    <img src={curElem.previewImage} alt={curElem.name} /></div>
                                                {curElem.id === campaign.templateId ?
                                                    <div className="slide-active">
                                                        <i className="fa fa-check" aria-hidden="true"></i>
                                                    </div>
                                                    : ''
                                                }
                                            </div>
                                            <p style={{ color: "#ffffff", textTransform: "capitalize" }} className="mb-2">{curElem.name}</p>
                                        </li>
                                    )
                                }) : ""
                        }
                    </ul> :
                    <i className="fa fa-spinner fa-spin" style={{ color: "#24a7ef", fontSize: 25 }} />
                }


            </div>
        </div >
    )
}

export default Template
import React, { useEffect, useState } from 'react'
import modalCloseIcon from "../../images/modal-close.png";
import { Modal } from "react-bootstrap";
import { BsSearch } from 'react-icons/bs';
import { onFetchTemplates } from '../../Actions/DesignActions';
import { useDispatch } from 'react-redux';
import DesignTemplates from '../Design/DesignTemplates';
import { onApplyThumbnail } from '../../Actions/CreateVideoAction';

const ThumbModal = (props) => {
    const dispatch = useDispatch()
    const [template, setTemplate] = useState([])
    const [loader, setLoader] = useState({
        fetchLoader: false,
        apply: false
    })

    const [state, setState] = useState({
        name: "",
        templateId: -1,
        type: "thumbnail"
    })

    const [searchKey, setSearchKey] = useState("")

    const fetchThumbnail = () => {
        setLoader(true)
        let data = {
            type: "thumbnail"
        }
        dispatch(onFetchTemplates(data, setTemplate, loader, setLoader))
    }
    const handleApply = (tempId) => {
        setState({
            ...state,
            templateId: tempId
        })
        if (props.type === "auto") {
            let imageObj = template.find(({ id }) => +id === +tempId)
            if (imageObj) {
                props.setState({
                    ...props.state,
                    thumbnail: {
                        ...props.state.thumbnail,
                        data: imageObj.data,
                        id: tempId,
                        thum: imageObj.preview
                    }

                })
                props.handleClose()
            }
        } else {
            setLoader({
                ...loader,
                apply: true
            })
            let data = {
                cId: props.videoId,
                templateId: tempId
            }
            dispatch(onApplyThumbnail(data, props.fetchCampaign, loader, setLoader, props.handleClose))
        }
    }

    useEffect(() => {
        fetchThumbnail()
    }, [])

    useEffect(() => {
        if(!props.show){
            setTimeout(() => {
                setSearchKey("")
            },1000)
        }
    },[props.show])


    return (
        <Modal className="VideoModal dark thumb_modal" show={props.show} onHide={props.handleClose} centered>
            <Modal.Body >
                <div onClick={props.handleClose} className="vidClose">
                    <img alt="" src={modalCloseIcon} />
                </div>

                <div>
                    <div className="d-flex justify-content-between align-items-center">
                        <h4>Change Template</h4>
                        <div className="business-search-inp">
                            <span className="searchIcon"><BsSearch /></span>
                            <input className="inpSearch withIcon" type="text" onChange={(e) => setSearchKey(e.target.value)} placeholder="Search" />
                        </div>
                    </div>

                    <div className="list-template mt-1" style={{
                        maxHeight: 600,
                        overflowY: 'auto',
                        padding: 28,
                        minHeight: 400
                    }}>
                        <div className="row">
                            {template.length > 0 ?
                                template.filter((item) => {
                                    return item.name.toLowerCase().includes(searchKey.toLowerCase())
                                }).length > 0 ?
                                    template.filter((item) => {
                                        return item.name.toLowerCase().includes(searchKey.toLowerCase())
                                    }).map((curElem, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <DesignTemplates
                                                    firstTempId={props.tempId}
                                                    curElem={curElem}
                                                    setState={setState}
                                                    state={state}
                                                    loader={loader.apply}
                                                    handleSubmit={handleApply}
                                                />
                                            </React.Fragment>
                                        )
                                    })
                                    :
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 40 }}>
                                        No template available with this name!
                                    </div>
                                : loader.fetchLoader ?
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 40, fontSize: 30, color: "#23a4ee" }}>
                                        <i className="fa fa-spinner fa-spin" />
                                    </div> : ""
                            }
                        </div>
                    </div>

                    {/* <div className="text-right foot-button">
                        <button className="demoLink ml-2" onClick={handleApply}>{loader.apply ? <>Applying <i className="fa fa-spinner fa-spin mx-1" /></> : "Apply"}</button>
                    </div> */}
                </div>

            </Modal.Body>
        </Modal >
    )
}

export default ThumbModal
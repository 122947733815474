import React, { useState, useEffect } from 'react'
import { BsPlayCircle, BsPauseCircle } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { setAlert } from '../../../Actions/AlertAction'
import { aiVolumeChange, bgVolumeChange } from "../../../Actions/contentAction";

let ttsAudio = false, bgAudio = false
const VolumeControl = () => {
    const dispatch = useDispatch()
    const campaign = useSelector(state => state.content.campaign)
    const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")

    const [play, setPlay] = useState(false)
    const [tts, setTts] = useState({
        src: "",
    })
    const [bgMusic, setBgMusic] = useState({
        source: "",
    })

    useEffect(() => {
        setTts(selectedSlide.audio)
    }, [selectedSlide.audio])

    useEffect(() => {
        setBgMusic(campaign.backgroundAudio)
    }, [campaign.backgroundAudio])


    const handlePlay = () => {
        if (tts.enable || bgMusic.enable) {
            if (tts.enable) {
                ttsAudio = new Audio(tts.src)
                ttsAudio.volume = tts.volume / 100
                ttsAudio.play()
            }
            if (bgMusic.enable) {
                bgAudio = new Audio(bgMusic.source)
                bgAudio.volume = bgMusic.volume / 100
                bgAudio.play()
            }
            setPlay(true)
        }
        else {
            dispatch(setAlert("Please add Music and VoiceOver in your slide", 'warning'))
        }
    }

    const handlePause = () => {
        if (ttsAudio) {
            ttsAudio.pause()
        }
        if (bgAudio) {
            bgAudio.pause()
        }
        setPlay(false)
    }

    useEffect(() => {
        handlePause()
    }, [selectedSlideIndex])

    const handleVolumeChange = (e, type) => {
        handlePause()
        switch (type) {
            case 'music':
                const bgAction = 'CHANGE_BG_VOLUME'
                dispatch(bgVolumeChange(bgAction, e.target.value))
                break;

            case 'voiceover':
                const voiceAction = 'CHANGE_VOICE_VOLUME'
                dispatch(aiVolumeChange(voiceAction, e.target.value, selectedSlideIndex))
                break;

            default:
                break;
        }

    }

    useEffect(() => {
        return () => handlePause() 
    }, [])

    return (
        <div className="add-block-single">
            <div className="add-block-main">
                <div className="add-block-title mb-4">
                    <h2>Volume Control</h2>
                    <span style={{ color: "#fff" }}>
                        {play ?
                            <BsPauseCircle
                                fontSize={23}
                                onClick={() => handlePause()}
                                className="cursor-pointer"
                            />
                            :
                            <BsPlayCircle
                                fontSize={23}
                                onClick={() => handlePlay()}
                                className="cursor-pointer"


                            />
                        }
                    </span>

                </div>
                <div>
                    <h2 className="pb-2">Voiceover <span style={{ float: 'right' }}>
                        {`${selectedSlide.audio.volume}%`}
                    </span></h2>
                    <input
                        type="range"
                        min={1}
                        className="slidern cursor-pointer"
                        name="voiceOver"
                        id="myRange"
                        value={selectedSlide.audio.volume}
                        onChange={e => handleVolumeChange(e, 'voiceover')}
                    />

                    <h2 className="pb-2 mt-4">Music  <span style={{ float: 'right' }}>
                        {`${campaign.backgroundAudio.volume}%`}
                    </span></h2>
                    <input
                        type="range"
                        min={1}
                        className="slidern cursor-pointer"
                        id="myRange"
                        name="music"
                        value={campaign.backgroundAudio.volume}
                        onChange={e => handleVolumeChange(e, 'music')}
                    />
                </div>
            </div>
        </div>
    )
}

export default VolumeControl
import React from 'react'
import VideoCampRow from './VideoCampRow'
import TitleBar from '../TitleBar'

const VideoCampaign = ({ currentTodo, loader, fetchVideoCamp }) => {
    return (
        <>
        <TitleBar title={"Videos"}/>
            <div className="table-responsive">
                <table className="table businessTable">
                    <thead>
                        <tr>
                            <th width="150"></th>
                            <th>Name</th>
                            <th>Status</th>
                            {/* <th>Created</th> */}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentTodo.length > 0 ?
                            currentTodo.map((curElem, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <VideoCampRow
                                            curElem={curElem}
                                            fetchData={fetchVideoCamp}
                                        />
                                    </React.Fragment>
                                )
                            })
                            :
                            <tr className="text-center table-border-remove">
                                <td colSpan={5}>
                                    {loader.campLoader ?
                                        <i className="fa fa-spinner fa-spin" style={{ color: "#23a3ed", fontSize: 25 }} />
                                        : "No video available!"}
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default VideoCampaign
import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";

import { Link } from "react-router-dom";
import iconRight from "../../images/icon-arrow.svg";

import { BsDisplay, BsSearch } from "react-icons/bs"
import { VscSymbolColor } from "react-icons/vsc"
import { RiProfileLine } from "react-icons/ri"

import { onFetchTableData } from "../../Actions/DesignActions";
import { useDispatch } from "react-redux";
import DesignTableRows from "./DesignTableRows";
import Pagination from "./Pagination";
import { useSelector } from "react-redux";

const Design = () => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [data, setData] = useState([])
    const [designData, setDesignData] = useState([])
    const [loader, setLoader] = useState(false)

    const [state, setState] = useState({
        filter: "",
        search: ""
    })

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemonPage: 10
    });

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }
    const fetchTableData = () => {
        setLoader(true)
        dispatch(onFetchTableData(setData, setLoader))
    }

    const indexofLastTodo = pagination.currentPage * pagination.totalItemonPage;
    const indexofFirstTodo = indexofLastTodo - pagination.totalItemonPage;
    const currentTodo = designData.slice(indexofFirstTodo, indexofLastTodo);


    useEffect(() => {
        if (state.search === "" && state.filter === "") {
            return setDesignData(data)
        } else {
            const val = data
                .filter((curElem) => {
                    if (state.filter === "") {
                        return curElem;
                    } else {
                        let data = curElem.type === state.filter;
                        return data;
                    }
                })
                .filter((curElem) => {
                    let data = curElem.type.toLowerCase().includes(state.search.toLowerCase());
                    if (data) {
                        return curElem;
                    }
                })
            setDesignData(val);
        }
    }, [data, state])

    useEffect(() => {
        fetchTableData()
    }, [])

    return (
        <>
            <TitleBar title="Design" />
            <Navbar />
            <section className="siteWrap">
                <div className="container">
                    <div className="business-wrap">

                        {+auth.user.isClient !== 1 ?
                            <div className="hero-banner-right">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <Link to="/create-page?type=cover" className="bannerLink">
                                            <div className="bannerLink-left"><span><BsDisplay /></span> Create Cover</div>
                                            <div className="bannerLink-right"><img alt="" src={iconRight} /></div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-4">
                                        <Link to="/create-page?type=logo" className="bannerLink">
                                            <div className="bannerLink-left"><span><VscSymbolColor /></span> Create Logo</div>
                                            <div className="bannerLink-right"><img alt="" src={iconRight} /></div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-4">
                                        <Link to="/create-page?type=profile" className="bannerLink">
                                            <div className="bannerLink-left"><span><RiProfileLine /></span> Create Profile</div>
                                            <div className="bannerLink-right"><img alt="" src={iconRight} /></div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            : ""}

                        <div className="business-top mt-5">
                            <h2>Lists:</h2>
                        </div>

                        <div className="business-list">
                            <div className="business-search">
                                <div className="business-search-inp">
                                    <span className="searchIcon"><BsSearch /></span>
                                    <input
                                        className="inpSearch withIcon"
                                        type="text"
                                        placeholder="Search"
                                        name="search"
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="business-search-inp ml-2">
                                    <select
                                        className="inpSearch"
                                        name="filter"
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Type</option>
                                        <option value="cover">Cover</option>
                                        <option value="logo">Logo</option>
                                        <option value="profile">Profile</option>
                                    </select>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table businessTable">
                                    <thead>
                                        <tr>
                                            <th width="150"></th>
                                            <th>Name</th>
                                            <th>Type</th>
                                            <th>Status</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentTodo.length > 0 ?
                                            currentTodo.map((curElem, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <DesignTableRows
                                                            curElem={curElem}
                                                            fetchTableData={fetchTableData}
                                                        />
                                                    </React.Fragment>
                                                )
                                            }) :
                                            ""}

                                    </tbody>
                                </table>
                                <div className="text-center my-3 text-light">
                                    {data.length === 0 ?
                                        loader ?
                                            <i className="fa fa-spinner fa-spin" style={{ color: "#219fea", fontSize: 30 }} />
                                            : "No data found"

                                        : ""}
                                </div>
                            </div>

                            <Pagination
                                designData={designData}
                                pagination={pagination}
                                setPagination={setPagination}
                                currentTodo={currentTodo}
                                data={data}
                                loader={loader}
                                state={data}
                            />

                        </div>

                    </div>

                </div>
            </section>

            <Footer />
        </>
    )
}

export default Design;
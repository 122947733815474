import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { addTransition, removeTransition } from '../../../Actions/contentAction'

const TransCards = ({ selectedSlide, curElem, selectedSlideIndex }) => {
    const dispatch = useDispatch()

    const [state, setState] = useState({
        active: false,
        loader: false
    })

    const handleTransition = (name) => {
        dispatch(addTransition(name, selectedSlideIndex))
    }
    const handleRemove = (e) => {
        e.stopPropagation()
        dispatch(removeTransition(selectedSlideIndex))
    }

    const handleActive = () => {
        setState({
            ...state,
            loader: true,
            active: true
        })

    }

    const handleLeave = () => {
        setState({
            ...state,
            loader: false,
            active: false
        })
    }

    return (
        <li>
            <div
                className='mediaList-single cursor-pointer'
                onClick={() => handleTransition(curElem.name)}
                onMouseEnter={() => handleActive()}
                onMouseLeave={() => handleLeave()}

            >
                <img
                    className="mediaImg"
                    src={state.active ? curElem.preview : curElem.thumbnail}
                    alt={curElem.name}
                    onLoad={() => setState({
                        ...state,
                        loader: false,
                    })}
                />

                <div className='transition-txt text-light text-capitalize' style={{ fontWeight: 600 }}>
                    {state.loader ?
                        <i className='fa fa-spinner fa-spin' style={{ fontSize: "26px" }} />
                        : curElem.name
                    }
                </div>
                {selectedSlide.transitionName === curElem.name ?
                    <div className="slide-active" onClick={handleRemove}>
                        <i className="fa fa-check" aria-hidden="true"></i>
                    </div>
                    : ''
                }
            </div>


        </li>
    )
}

export default TransCards
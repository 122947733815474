import './App.css';
import './css/design-fonts.css'
import 'react-calendar/dist/Calendar.css';
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Login from './Components/Auth/Login';
import ForgetPassword from './Components/Auth/ForgetPassword';
import ResetPassword from './Components/Auth/ResetPassword';
import MasterLogin from './Components/Auth/MasterLogin';
import { PrivateRoute } from './Components/PrivateRoute';
import { HelmetProvider } from 'react-helmet-async';
import Dashboard from './Components/Dashboard/Dashboard';
import Profile from './Components/User/Profile';
import Privacy from './Components/User/Privacy';
import AccountManagement from './Components/User/AccountManagement';
import Upgrades from './Components/User/Upgrades';
import HelpSupport from './Components/Support/HelpSupport';
import Training from './Components/Support/Training';
import TrainingArticles from './Components/Support/TrainingArticles';
import Integration from './Components/Integration/Integration';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadUser } from './Actions/AuthAction';
import { removeAlert } from './Actions/AlertAction';
import Alert from './Components/Alert';
import Chat from './Components/Chat/Chat';
import Design from './Components/Design/Design';
import CreatePage from './Components/Design/CreatePage';
import ImageEditor from './Components/Design/ImageEditor';
import Repository from './Components/Repository/Repository';
import Campaigns from './Components/Campaigns/Campaigns';
import Manual from './Components/CreateVideo/Manual';
import VideoIdeas from './Components/CreateVideo/VideoIdeas';
import ReviewMetaData from './Components/CreateVideo/ReviewMetaData';
import ReviewScripts from './Components/CreateVideo/ReviewScripts';
import Publishing from './Components/CreateVideo/Publishing';
import Thumbnail from './Components/CreateVideo/Thumbnail';
import VideoPage from './Components/CreateVideo/VideoPage';
import Schedules from './Components/Schedules/Schedules';
import Editor from './Components/editor/Editor';
import AutoCreateVideo from './Components/CreateVideo/AutoMode/AutoCreateVideo';
import ScheduleTable from './Components/Schedules/ScheduleTable';
function App() {

  const dispatch = useDispatch()
  const alert = useSelector(state => state.alert)

  useEffect(() => {
    dispatch(loadUser())
  }, [])

  useEffect(() => {
    if (alert !== undefined) {
      if (alert.message !== "") {
        setTimeout(() => {
          dispatch(removeAlert())
        }, 4000);
      }
    }
  }, [alert, dispatch])

  return (
    <div className="App">
      <Alert />
      <HelmetProvider>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path='/login' element={<Login />} />
            <Route exact path='/forget-password' element={<ForgetPassword />} />
            <Route exact path='/reset-password' element={<ResetPassword />} />
            <Route exact path='/master-login' element={<MasterLogin />} />

            <Route exact path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route exact path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
            <Route exact path="/privacy" element={<PrivateRoute><Privacy /></PrivateRoute>} />
            <Route exact path="/account-management" element={<PrivateRoute><AccountManagement /></PrivateRoute>} />
            <Route exact path="/upgrades" element={<PrivateRoute><Upgrades /></PrivateRoute>} />
            <Route exact path="/help-and-support" element={<PrivateRoute><HelpSupport /></PrivateRoute>} />
            <Route exact path="/training" element={<PrivateRoute><Training /></PrivateRoute>} />
            <Route exact path="/support-article" element={<PrivateRoute><TrainingArticles /></PrivateRoute>} />
            <Route exact path="/integration" element={<PrivateRoute><Integration /></PrivateRoute>} />

            <Route exact path="/ai-coach" element={<PrivateRoute><Chat /></PrivateRoute>} />

            <Route exact path="/design" element={<PrivateRoute><Design /></PrivateRoute>} />
            <Route exact path="/create-page" element={<PrivateRoute><CreatePage /></PrivateRoute>} />
            <Route exact path="/image-editor" element={<PrivateRoute><ImageEditor /></PrivateRoute>} />

            <Route exact path="/repository" element={<PrivateRoute><Repository /></PrivateRoute>} />
            <Route exact path="/campaigns" element={<PrivateRoute><Campaigns /></PrivateRoute>} />
            <Route exact path="/auto" element={<PrivateRoute><AutoCreateVideo /></PrivateRoute>} />
            <Route exact path="/manual" element={<PrivateRoute><Manual /></PrivateRoute>} />
            <Route exact path="/video-idea" element={<PrivateRoute><VideoIdeas /></PrivateRoute>} />
            <Route exact path="/review-meta-data" element={<PrivateRoute><ReviewMetaData /></PrivateRoute>} />
            <Route exact path="/review-scripts" element={<PrivateRoute><ReviewScripts /></PrivateRoute>} />
            <Route exact path="/publishing" element={<PrivateRoute><Publishing /></PrivateRoute>} />
            <Route exact path="/thumbnail" element={<PrivateRoute><Thumbnail /></PrivateRoute>} />
            <Route exact path="/video-page" element={<PrivateRoute><VideoPage /></PrivateRoute>} />

            <Route exact path="/schedule" element={<PrivateRoute><Schedules /></PrivateRoute>} />
            <Route path="/schedule-content" element={<PrivateRoute><ScheduleTable /></PrivateRoute>} />

            <Route exact path="/editor" element={<PrivateRoute><Editor /></PrivateRoute>} />

            <Route path="*" element={<Login />} />
          </Routes>
        </BrowserRouter>
      </HelmetProvider>
    </div>
  );
}

export default App;

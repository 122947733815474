import React from 'react'
import vooduuIcon from "../../images/fav-new.png"
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { FaDownload, FaEdit, FaGlobe, FaTrash } from 'react-icons/fa'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import SweetAlertTwo from '../SweetAlertTwo'
import { onCheckStatus, onDeleteVideo } from '../../Actions/ChannelAction'
import PublishModal from './PublishModal'
import { useSelector } from 'react-redux'

let rendCheck
const VideoCampRow = ({ curElem, fetchData }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [video, setVideo] = useState(curElem)
    const [state, setState] = useState(false)
    const [info, setInfo] = useState({
        enable: false,
        id: -1
    })
    const [show, setShow] = useState(false)

    const [checkData, setCheckData] = useState({
        renderProgress: curElem.renderProgress,
        renderStatus: ""
    })

    const handleCancel = () => {
        setInfo({
            ...info,
            enable: false,
            id: -1
        })
    }

    const handleDelete = () => {
        setInfo({
            ...info,
            enable: true
        })
    }

    const handlePerform = (Swal) => {
        let data = {
            id: curElem.id
        }
        dispatch(onDeleteVideo(data, fetchData, Swal))
        setInfo({
            ...info,
            enable: false,
            id: -1
        })
    }

    const checkStatus = () => {
        let data = {
            id: curElem.id,
        }
        dispatch(onCheckStatus(data, setState, checkData, rendCheck, setCheckData, clearInterval, fetchData))

    }

    const handleDownload = (url) => {
        let a = document.createElement('a')
        a.href = url
        a.download = "download.mp4"
        a.target = "_blank"
        a.click()
    }

    useEffect(() => {
        if (+state === 6 || +state === 8 || +state === 7 || +state === 9 || +state === 5) {
            rendCheck = setInterval(() => {
                checkStatus()
            }, 5000)
        }
    }, [state])




    useEffect(() => {
        setState(+curElem.status)
        setVideo(curElem)
    }, [curElem])

    useEffect(() => {
        return () => {
            clearInterval(rendCheck)
        }
    }, [])

    return (
        <>
            <tr>
                <td>
                    <span className="proSpace"><img src={curElem.thumbnailUrl === "" ? vooduuIcon : curElem.thumbnailUrl} alt="" /></span>
                </td>
                <td style={{ maxWidth: 350 }}>
                    <h6>{video.ideaName}</h6>
                    <p style={{ fontSize: 14, paddingLeft: 5 }}>{video.metaDescription}</p>
                </td>
                <td>
                    <span
                        className="stat"
                        style={{
                            background: +state === 3 ? "#dc3545" : +state === 5 ? "#4169E1" : +state === 2 ? "#05772b" : +state === 6 ? "#a4a427" : +state === 8 ? "#a4a427" : +state === 4 || +state === 1 ? "rgb(22, 126, 215)" : "#ff9933",
                            display: "inline-flex", width: "auto", alignItems: "center"
                        }}
                    >
                        {
                            +state === 0 ?
                                <>
                                    <div className="typing me-1" >
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                    Generating Scenes
                                </>
                                :
                                +state === 1
                                    ? "Awaiting Customization"
                                    : +state === 2
                                        ? "Render Successful"
                                        : +state === 3
                                            ? "Render Failed"
                                            : +state === 6
                                                ?
                                                <>
                                                    <div className="typing me-1" >
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                    </div>
                                                    Rendering {checkData.renderProgress}%
                                                </>
                                                : +state === 8
                                                    ?
                                                    <>
                                                        <div className="typing me-1" >
                                                            <span></span>
                                                            <span></span>
                                                            <span></span>
                                                        </div>
                                                        {checkData.renderProgress}
                                                    </>
                                                    : ""
                        }
                    </span>
                </td>

                {/* <td >{video.created}</td> */}
                <td>
                    {+auth.user.isClient === 1 ? "" :
                        <>
                            <Link className="roundBox" title='View' to={`/editor?id=${curElem.id}`} ><FaEdit /></Link>
                            <span className="roundBox" title='Delete' onClick={handleDelete} ><FaTrash /></span>

                        </>
                    }
                    {curElem.videoUrl !== "" ?
                        <>
                            <span className="roundBox" title='Download' onClick={() => handleDownload(curElem.videoUrl)} ><FaDownload /></span>
                            <span className="roundBox" title='Publish' onClick={() => setShow(true)} ><FaGlobe /></span>
                        </> : ""}

                </td>

                <SweetAlertTwo
                    show={info.enable}
                    title="Are You Sure?"
                    text={`Do you want to delete this video!`}
                    icon="warning"
                    showCancelButton={true}
                    confirmButtonColor='#b32f3d'
                    cancelButtonColor='#2a2c3a'
                    confirmButtonText='Yes, Delete!'
                    confirmTitle='Deleting...'
                    confirmText="This won't be revert!"
                    confirmIcon='success'
                    handlePerform={handlePerform}
                    onCancel={handleCancel}
                />
            </tr>
            <PublishModal
                curElem={curElem}
                show={show}
                setShow={setShow}
                videoUrl={curElem.videoUrl}
            />
        </>
    )
}

export default VideoCampRow
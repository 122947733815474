import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { AiFillHeart, AiOutlinePlus } from 'react-icons/ai';
import { AiOutlineClose } from "react-icons/ai"
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { onFetchUserKeyword, onFetchUserIdeas, onUserRemoveNiches, onFetchNiche, onSearchKeyData } from "../../Actions/ChannelAction";
import { useDispatch } from "react-redux";
import AddRepoModal from "./AddRepoModal";
import DashboardSectionHeader from "../Dashboard/DashboardSectionHeader";
import SweetAlertTwo from "../SweetAlertTwo";
import { BiUpArrowAlt } from "react-icons/bi";
import { useSelector } from "react-redux";
import DemoAndIdeasHeader from "../Dashboard/DemoAndIdeasHeader";

const Repository = () => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [niches, setNiches] = useState([])
    const [keyword, setKeyword] = useState([])
    const [topic, setTopic] = useState([])
    const [show, setShow] = useState(false)
    const [state, setState] = useState({
        key: "",
        type: ""
    })
    const [loader, setLoader] = useState({
        niche: false,
    })

    const handleShow = (val) => {
        setState({
            ...state,
            type: val
        })
        setShow(true)
    }
    const handleClose = () => setShow(false)

    const handleRemove = (val, type) => {
        if (auth.user.isClient === "1") {
            return
        }
        let ep = ""
        let data = {}
        let func

        if (type === "keyword") {
            ep = "remove-keyword"
            func = fetchKeyword
            data = {
                id: val.id
            }
            const updatedArray = keyword.filter(obj => obj.id !== val.id);
            setKeyword(updatedArray)
        } else if (type === "topic") {
            func = fetchIdeas
            ep = "remove-idea"
            data = {
                dailyIdeaId: val.id
            }
            const updatedArray = topic.filter(obj => obj.id !== val.id);
            setTopic(updatedArray)
        } else {
            ep = "remove-niches"
            func = fetchNiches
            data = {
                id: val.id,
                type: val.generated_by
            }
            const updatedArray = niches.filter(obj => obj.id !== val.id);
            setNiches(updatedArray)
        }
        dispatch(onUserRemoveNiches(ep, data, false))
    }

    const fetchNiches = () => {
        dispatch(onFetchNiche(setNiches, loader, setLoader))
    }
    const fetchKeyword = () => {
        dispatch(onFetchUserKeyword(setKeyword))
    }
    const fetchIdeas = () => {
        dispatch(onFetchUserIdeas(setTopic))
    }

    useEffect(() => {
        setLoader({
            ...loader,
            niche: true,
        })
        fetchNiches()
        fetchKeyword()
        fetchIdeas()
    }, [])

    return (
        <>
            <TitleBar title="Repository" />
            <Navbar />

            <section className="siteWrap">
                <div className="siteWrap-in siteWrapN pt-5">
                    <div className="container-fluid p_space_1">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Nav variant="h" className="siteTab">
                                <Nav.Item><Nav.Link eventKey="first">My Niches</Nav.Link></Nav.Item>
                                <Nav.Item><Nav.Link eventKey="second">My Keywords</Nav.Link></Nav.Item>
                                <Nav.Item><Nav.Link eventKey="third">My Ideas</Nav.Link></Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <div className="pt-2">
                                        {+auth.user.isClient === 1 ? "" :
                                            <div className="text-right mb-2" >
                                                <button className="demoLink mt-0" onClick={() => handleShow("niche")} style={{ fontSize: "14px", height: "50px" }}><AiOutlinePlus /> Add Niche</button>
                                            </div>}
                                        <div className="niche-box niche_box_repository alt">
                                            <ul>
                                                {niches.length > 0 ?
                                                    niches.map((curElem, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <div className="single-niche">
                                                                    <p>{curElem.name}</p>
                                                                    <span
                                                                        className="add-niche cursor-pointer delIcon "
                                                                        style={{ background: "transparent" }}
                                                                        onClick={() => handleRemove(curElem, "niche")}
                                                                        title="Remove"
                                                                    >
                                                                        <AiFillHeart />
                                                                    </span>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                    :
                                                    ""
                                                }
                                            </ul>
                                        </div>
                                        <div className="text-center text-light">
                                            {niches.length === 0 ?
                                                loader.niche ?
                                                    <i className="fa fa-spinner fa-spin" style={{ color: "#219fea", fontSize: 30 }} />
                                                    : "No niches selected yet!"
                                                : ""}
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <div className="text-right">
                                        {+auth.user.isClient === 1 ? "" :
                                            <button className="demoLink mt-2 mb-0" onClick={() => handleShow("keyword")} style={{ fontSize: "14px", height: "50px" }}><AiOutlinePlus /> Add keyword</button>
                                        }
                                        <div className="info-box-body alt pt-0" >
                                            <div className="mt-3">
                                                <div className="table-responsive">

                                                    <table className="table video-table" style={{ marginTop: "-9px" }}>
                                                        <DashboardSectionHeader
                                                            keyword={keyword}
                                                            setKeyword={setKeyword}
                                                        />
                                                        {keyword.length > 0 ?
                                                            keyword.filter((item) => {
                                                                return +item.is_like === 1
                                                            }).length > 0 ?
                                                                keyword.filter((item) => {
                                                                    return +item.is_like === 1
                                                                }).map((curElem, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td className="text-left">
                                                                                {curElem.name}
                                                                            </td>
                                                                            <td>{curElem.volume}</td>
                                                                            <td>{curElem.competition}</td>
                                                                            <td><span className="keyColor green">{curElem.overall}</span></td>
                                                                            <td><span
                                                                                className="add-niche repository_del_icon  delIcon cursor-pointer p-0"

                                                                                onClick={() => handleRemove(curElem, "keyword")}
                                                                                title="Remove"
                                                                            >
                                                                                <AiFillHeart />

                                                                            </span></td>
                                                                        </tr>
                                                                    )
                                                                })
                                                                :
                                                                <tr>
                                                                    <td className="text-center" colSpan={5} style={{ background: "none" }}>
                                                                        No keyword selected yet!
                                                                    </td>
                                                                </tr>
                                                            : ""}

                                                    </table>
                                                    <div className="text-center text-light mt-2">
                                                        {keyword.length === 0 ?
                                                            "No keyword selected yet!"
                                                            : ""}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <div className="text-right">
                                        {+auth.user.isClient === 1 ? "" :
                                            <button className="demoLink mt-2 mb-0" onClick={() => handleShow("idea")} style={{ fontSize: "14px", height: "50px" }}><AiOutlinePlus /> Add idea</button>
                                        }
                                        <div className="info-box-body alt">
                                            <div className="table-responsive">
                                                <table className="table video-table text-left" style={{ marginTop: "-9px" }}>
                                                    <DemoAndIdeasHeader ideasData={topic} setIdeasData={setTopic} />
                                                    {topic.length > 0 ?
                                                        topic.filter((idea) => {
                                                            return +idea.is_like === 1
                                                        }).length > 0 ?
                                                            topic.filter((idea) => {
                                                                return +idea.is_like === 1
                                                            }).map((curElem, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <span className="">{curElem.text}</span>
                                                                        </td>

                                                                        <td className="text-center" style={{ width: "100px" }}>
                                                                            <span>{curElem.competition}</span>
                                                                        </td>
                                                                        <td className='text-center'>
                                                                            {curElem.competition_volume === "low" ?
                                                                                <span >
                                                                                    <BiUpArrowAlt
                                                                                        size={30}
                                                                                        style={{ fontStyle: 30 }}
                                                                                        color="#3AFF89"
                                                                                        title="Low"
                                                                                    />
                                                                                </span>
                                                                                :
                                                                                curElem.competition_volume === "medium" ?
                                                                                    <span >
                                                                                        <BiUpArrowAlt
                                                                                            size={30}
                                                                                            style={{ fontStyle: 30 }}
                                                                                            color="#fd0"
                                                                                            title="Medium"
                                                                                        />
                                                                                    </span> :
                                                                                    curElem.competition_volume === "high" ?
                                                                                        <span >
                                                                                            <BiUpArrowAlt
                                                                                                size={30}
                                                                                                style={{ fontStyle: 30, transform: "rotate(90deg)" }}
                                                                                                color="#ff8300"
                                                                                                title="High"
                                                                                            />
                                                                                        </span> :
                                                                                        <span >
                                                                                            <BiUpArrowAlt
                                                                                                size={30}
                                                                                                style={{ fontStyle: 30, transform: "rotate(180deg)" }}
                                                                                                color="#f95f58"
                                                                                                title="Very High"
                                                                                            />
                                                                                        </span>
                                                                            }
                                                                        </td>
                                                                        <td className="text-right">
                                                                            <span
                                                                                className="delIcon cursor-pointer"
                                                                                onClick={() => handleRemove(curElem, "topic")}
                                                                                title="Remove"
                                                                            >
                                                                                <AiFillHeart />
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                            :
                                                            <tr >
                                                                <td colSpan={5} className="text-center" style={{ background: "none" }}>No idea liked yet!</td>
                                                            </tr>
                                                        : ""}
                                                </table>
                                                <div className="text-center text-light mt-2">
                                                    {keyword.length === 0 ?
                                                        "No idea liked yet!"
                                                        : ""}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </section>
            <AddRepoModal
                show={show}
                handleClose={handleClose}
                type={state.type}
                fetchKeyword={fetchKeyword}
                fetchIdeas={fetchIdeas}
                fetchNiches={fetchNiches}
            />
            <Footer />
        </>
    )
}

export default Repository;
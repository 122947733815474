import React, { useEffect, useState } from 'react'
import modalCloseIcon from "../../images/modal-close.png";
import { Modal } from "react-bootstrap";
import { BsSearch } from 'react-icons/bs';
import { onFetchTemplates } from '../../Actions/DesignActions';
import { useDispatch } from 'react-redux';
import DesignTemplates from '../Design/DesignTemplates';
import { onApplyTemplate } from '../../Actions/CreateVideoAction';
import { onFetchVideoTemplates } from '../../Actions/ChannelAction';
import { IoEye } from 'react-icons/io5';
import { HiHand } from 'react-icons/hi';
import { useSelector } from 'react-redux';

const VideoTempModal = ({ show, videoId, handleClose, fetchCampaign, type, data, setData }) => {

    const dispatch = useDispatch()

    const videos = useSelector(state => state.createVideo.videos)

    const [template, setTemplate] = useState([])
    const [searchKey, setSearchKey] = useState("")
    const [loader, setLoader] = useState({
        fetchLoader: false,
        apply: false
    })

    const [state, setState] = useState({
        templateId: type === "auto" ? data.videoTemplate.id : videos[0]?.templateId ? videos[0].templateId : "-1",
        previewImage: ""
    })

    const fetchThumbnail = () => {
        setLoader({
            ...loader,
            fetchLoader: true
        })
        dispatch(onFetchVideoTemplates({}, setTemplate, loader, setLoader))
    }
    const handleApply = (curElem) => {
        setState({
            ...state,
            templateId: curElem.id,
            previewImage: curElem.previewImage
        })

        if (type === "auto") {
            setData({
                ...data,
                videoTemplate: {
                    ...data.videoTemplate,
                    id: curElem.id,
                    image: curElem.previewImage
                }
            })
        } else {
            setLoader({
                ...loader,
                apply: true
            })
            let data = {
                cId: videoId,
                templateId: curElem.id
            }
            dispatch(onApplyTemplate(data, loader, setLoader))
        }
        handleClose()

    }

    useEffect(() => {
        fetchThumbnail()
    }, [])

    return (
        <Modal className="VideoModal dark" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div onClick={handleClose} className="vidClose">
                    <img alt="" src={modalCloseIcon} />
                </div>

                <div>
                    <div className="d-flex justify-content-between align-items-center">
                        <h4>Change Template</h4>
                        <div className="business-search-inp">
                            <span className="searchIcon"><BsSearch /></span>
                            <input
                                className="inpSearch withIcon"
                                type="text"
                                placeholder="Search"
                                onChange={(e) => setSearchKey(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="list-template">
                        <div className="row">
                            {template.length > 0 ?
                                template.filter((item) => {
                                    return item.name.toLowerCase().includes(searchKey.toLowerCase())
                                }).length > 0 ?
                                    template.filter((item) => {
                                        return item.name.toLowerCase().includes(searchKey.toLowerCase())
                                    }).map((curElem, index) => {
                                        return (
                                            <div className={`col-lg-4 col-md-6 col-sm-12 col-xs-12 cursor-pointer`} key={index}>
                                                <div className="list-template-wrap text-center">
                                                    <div className="list_template_business" style={{ border: +curElem.id === +state.templateId ? "1px solid #1478d3" : "" }}>
                                                        <img src={curElem.previewImage} alt="" />
                                                        <div className="list-template-btn">
                                                            <div>
                                                                {loader.apply ?
                                                                    <i className="fa fa-spinner fa-spin mx-1" style={{ fontSize: "35px", color: "#1478D3" }} />
                                                                    :
                                                                    <span className="demoLink" title="Use this template" onClick={() => handleApply(curElem)}><HiHand /></span>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p>{curElem.name}</p>

                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 40 }}>
                                        No template available with this name!
                                    </div>
                                : loader.fetchLoader ?
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 40, fontSize: 30, color: "#23a4ee" }}>
                                        <i className="fa fa-spinner fa-spin" />
                                    </div> : ""
                            }
                        </div>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    )
}

export default VideoTempModal
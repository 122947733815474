import React, { useState } from "react";

import { BsExclamationCircle } from "react-icons/bs";
import { BsTagFill } from "react-icons/bs";
import TopNichesVideosHeader from "./TopNichesVideosHeader";

const TopNichesVideos = ({ imageUrl, topVideos, setTopVideos, videoLoader }) => {

  const [more, setMore] = useState({
    limit: 200,
    selectedIndex: -1,
  });

  const handleLimit = (index) => {
    setMore({
      ...more,
      selectedIndex: index,
    });
  };



  return (
    <div className="p_space">
      <div className="channel-stat">
        <div className="row">
          <div className="col-lg-8">
            <div className="info-box">
              <div className="info-box-head">
                <h2>
                  <img src={require("../../images/trophy.png")} alt="" />
                  Top videos from your niches
                </h2>
                <span>
                  <span className="toolTip ml-1">
                    <BsExclamationCircle style={{ fontWeight: "600" }} />
                    <span className="tooltiptext">
                      Top Youtube videos based on your selected Niches
                    </span>
                  </span>
                </span>
              </div>
              <div className="info-box-body">
                <div>
                  <div className="table-responsive Desh_niches_wrapper">
                    <table
                      className="table video-table Desh_niches_table"
                      style={{ marginTop: "-9px" }}
                    >
                      <TopNichesVideosHeader topVideos={topVideos} setTopVideos={setTopVideos} />
                      <tbody>
                        {topVideos.length > 0 ?
                          topVideos.map((curElem, index) => {
                            let isTagArray = curElem.tags ? curElem.tags.length > 0 ? curElem.tags : [] : []
                            let tagArray = more.selectedIndex === index ? isTagArray : isTagArray.slice(0, 3)
                            return (
                              <React.Fragment key={index}>
                                <tr>
                                  <td className="text-left">
                                    <div className="container">
                                      <div className="row ">
                                        <div className="col-xl-6 col-lg-6 col-md-12 mb-1 ">
                                          <span className="videoThumb">
                                            <img
                                              src={curElem.thumbnail}
                                              alt=""
                                              className="img-fluid"
                                              style={{ height: "100%" }}
                                            />
                                          </span>
                                        </div>
                                        <div className=" col-xl-6  col-lg-6 col-md-12 ">
                                          <div className="videoDesc Desh_niches_description ">
                                            <h6>
                                              <a
                                                className="text-light"
                                                href={`https://www.youtube.com/channel/${curElem.channel_id}`}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {curElem.title}
                                              </a>
                                            </h6>

                                            <p>
                                              {curElem.description ?
                                                curElem.description.length > 200 ?
                                                  <>
                                                    {more.selectedIndex === index ?
                                                      <>
                                                        {curElem.description}
                                                      </>
                                                      :
                                                      <>
                                                        {curElem.description.slice(0, 200)}<span style={{ fontSize: 20, color: "#fff" }}>...</span>
                                                      </>
                                                    }
                                                  </>
                                                  :
                                                  curElem.description
                                                :
                                                ""
                                              }
                                            </p>
                                            <ul className="d-flex flex-wrap list-inline">
                                              {tagArray.length > 0 ?
                                                tagArray.map((tag, tagIndex) => {
                                                  return (
                                                    <li
                                                      key={tagIndex}
                                                      style={{
                                                        backgroundColor: "rgb(255, 255, 255)",
                                                        margin: "3px",
                                                        padding: "3px",
                                                        color: "rgb(0, 0, 0)",
                                                        boxShadow: "rgba(0, 0, 0, 0.5) 0px 0px 5px",
                                                        fontSize: "10px",
                                                        borderRadius: "5px",
                                                        fontWeight: "bolder",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "10px",
                                                          marginRight: "3px",
                                                        }}
                                                      >
                                                        <BsTagFill
                                                          style={{ marginRight: 0, }}
                                                        />
                                                      </span>
                                                      {tag}

                                                    </li>
                                                  );
                                                }
                                                )
                                                : ""
                                              }
                                            </ul>
                                          </div>

                                          {
                                            curElem.description ?
                                              curElem.description.length > 200 ?
                                                more.selectedIndex === index ?
                                                  <span
                                                    className="ml-1"
                                                    style={{
                                                      color: "#25aaf1",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => handleLimit(-1)}
                                                  >
                                                    less
                                                  </span>
                                                  :
                                                  <span
                                                    className="ml-1"
                                                    style={{
                                                      color: "#25aaf1",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => handleLimit(index)}
                                                  >
                                                    more...
                                                  </span>
                                                : "" : ""
                                          }
                                        </div>

                                      </div>

                                    </div>

                                  </td>
                                  <td style={{ paddingTop: "25px" }}>
                                    <strong>{curElem.stats.views}</strong>
                                  </td>
                                  <td style={{ paddingTop: "25px" }}>
                                    <strong>{curElem.stats.like}</strong>
                                  </td>
                                  <td style={{ paddingTop: "25px" }}>
                                    <strong>{curElem.stats.comments}</strong>
                                  </td>

                                </tr>
                              </React.Fragment>
                            )
                          }
                          ) : (
                            <tr className="text-center mt-3 text-light ">
                              <td colSpan={5} style={{ background: "none" }}>
                                {topVideos.length === 0 ? (
                                  videoLoader ?
                                    <i
                                      className="fa fa-spinner fa-spin"
                                      style={{ color: "#1f99e7", fontSize: 30 }}
                                    />
                                    : (
                                      "No videos found!"
                                    )
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          )}
                      </tbody>
                      <div
                        className="NO_daily_idea_wrap"
                        style={{ display: topVideos.length > 0 ? "" : "block" }}
                      >
                        <p style={{ fontSize: 17 }}>No video generated for you yet. Please like some niches first.</p>
                      </div>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="info-box">
              <div className="info-box-body">
                <div className="banner-img">
                  <img src={imageUrl} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopNichesVideos;

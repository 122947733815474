import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { BsArrowRight, BsArrowLeft, BsUpload } from "react-icons/bs"
import { Link, useLocation, useNavigate } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { onFetchCamp, onUpdateCampaign, onUpdateScriptText, onUploadFile, onUpdateCoverage, onToggleTakingHead, onToggleHeadMovement, toggleFaceNarration, addFaceNarrationData } from "../../Actions/CreateVideoAction";
import { Modal } from "react-bootstrap";
import { AiOutlineCheck } from 'react-icons/ai'
import face from '../../images/do.png';
import face2 from '../../images/donts.png';
import thumb from '../../images/thumb.svg';
import unthumb from '../../images/Unthumb.svg';
import dummyImg from "../../images/default_image.jpg"

import Swal from "sweetalert2";
// import FaceNarrationCreate from "./FaceNarrationCreate";

let interval = false
const ReviewScripts = () => {

   const dispatch = useDispatch()
   const navigate = useNavigate()
   const location = useLocation()

   const createVideo = useSelector(state => state.createVideo)
   const videoId = queryString.parse(location.search).id

   const [pageLoader, setPageLoader] = useState(true)
   const [saveLoader, setSaveLoader] = useState(false)
   const [progress, setProgress] = useState(0)
   
   const [uploadLoader, setUploadLoader] = useState(false)
   const [handleModelPopup, setHandleModelPopup] = useState(false)
   
   const handleScript = (e, index) => {
      dispatch(onUpdateScriptText(e.target.value, index))
   }
   const handleChange = (e, index) => {
      dispatch(toggleFaceNarration(index, e.target.checked))
   }

   const onInputFile = (e, index) => {

      let allowedExt = ['image/png', 'image/jpg', 'image/jpeg'];
      let myType = "images"
      let allowedSize = 5000000;
      const formData = new FormData()
      if (allowedExt.includes(e.target.files[0].type)) {
         if (e.target.files[0].size < allowedSize) {
            formData.append('upload_type', myType)
            formData.append('file', e.target.files[0])
            setUploadLoader(true)
            dispatch(onUploadFile(formData, setUploadLoader, index))
         } else {
            Swal.fire({
               icon: 'error',
               title: 'Oops...',
               text: 'Max allowed size is 5MB!',
               confirmButtonColor: "#1478D3"
            })
         }
      } else {
         Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'You have Selected Invalid File Type!',
            confirmButtonColor: "#1478D3"
         })
      }
   }
   const handleNext = () => {
      let ep = "update-script-campaign"
      let data = { scripts: createVideo.scripts, cId: createVideo.id, faceNarration: createVideo.faceNarration }
      setSaveLoader(true)
      onGetProgress()
      dispatch(onUpdateCampaign(ep, data, navigate, "publishing", setSaveLoader, setProgress, interval))
   }

   const fetchCampaign = () => {
      let data = {
         campaignId: videoId
      }
      dispatch(onFetchCamp(data, setPageLoader, navigate))
   }

   const onGetProgress = () => {
      let prog = 0
      interval = setInterval(() => {
         prog += 1;
         if (prog > 99) {
            clearInterval(interval);
            setProgress(99)
            prog = 0
         } else {
            setProgress(prog)
         }
      }, 50)
   }

   const handleChange2 = (index, e) => {
      const { name, value, checked } = e.target
      if (name === "coverage") {
         dispatch(onUpdateCoverage(value, index))
      } else if (name === "enable") {
         dispatch(onToggleTakingHead(checked, index))
      } else if (name === "headMovement") {
         dispatch(onToggleHeadMovement(checked, index))
      }
   }

   const handlePopup = () => {
      setHandleModelPopup(!handleModelPopup)
   }



   useEffect(() => {
      if (videoId) {
         fetchCampaign()
      }
   }, [videoId])
   return (
      !pageLoader ?
         <>
            <TitleBar title="Create Campaign" />
            <Navbar />
            <section className="siteWrap">
               <div className="siteWrap-in" style={{ paddingTop: "32px" }}>
                  <div className="conatiner-fluid">
                     <div className="row justify-content-center align-items-center">
                        <div className="col-md-11">
                           <div className=" business-heading" style={{ marginBottom: "15px", flexFlow: "wrap", border: "none" }}>
                              <div className="row align-items-center" style={{ width: "100%" }}>
                                 <div className="col-md-6" >
                                    <h2>Review Scripts</h2>
                                 </div>
                                 <div className="col-md-6
                                 
                                  
                                  mt-md-0 mt-sm-2 mt-2">
                                    <div className=" d-flex justify-content-end align-items-center">
                                       <Link className="demoLink mt-0" to={`/review-meta-data?id=${videoId}`} style={{ padding: "14px 32px 14px 10px" }}> <BsArrowLeft />Go To Meta Data</Link>
                                       <button onClick={handleNext} className="demoLink ml-2 mt-0" style={{ padding: "14px 16px 14px 32px" }}> Go To Publishing {saveLoader ? <i className="fa fa-spinner fa-spin mx-1" /> : <BsArrowRight />} </button>
                                       {/* <Link to="/publishing" className="demoLink ml-2">Next <FaGreaterThan style={{ fontSize: "18px", marginLeft: "5px" }} /></Link> */}
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="progress" style={{ height: 5, background: "#424b6c", marginBottom: "15px" }}>
                              <div class="progress-bar" role="progressbar" aria-valuenow={progress}
                                 aria-valuemin="0" aria-valuemax="100" style={{ width: `${progress}%`, background: "#22a0eb" }}>
                              </div>
                           </div>
                        </div>

                     </div>
                  </div>
                  {/* <FaceNarrationCreate /> */}


                  <div className="container">
                     <div className="row justify-content-center">
                        <div className="col-lg-12">
                           <div className="integr-wrap pt-0"><h2 className="p_space">Scripts</h2></div>
                           <div className="site-accordion">
                              <Accordion defaultActiveKey="0">
                                 {createVideo.scripts.length > 0 ?
                                    createVideo.scripts.map((curElem, index) => {
                                       return (
                                          <Accordion.Item eventKey={index} key={index}>
                                             <Accordion.Header>Video #{index + 1}</Accordion.Header>
                                             <Accordion.Body>
                                                <div className="inp-group mt-0">
                                                   <label htmlFor="">Scripts</label>
                                                   <textarea
                                                      placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                                                      className="simple-inp"
                                                      name=""
                                                      value={curElem.script}
                                                      onChange={(e) => handleScript(e, index)}
                                                   />
                                                </div>

                                                <div className=" inpLabel d-flex locateBox justify-content-between">
                                                   <label htmlFor="" style={{ color: "#fff", fontSize: "16px" }}>Add face narration</label>

                                                   <div className="d-flex mode_wrap">
                                                      <label className="switch">
                                                         <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={curElem?.faceNarration?.enable}
                                                            onChange={(e) => { handleChange(e, index) }}
                                                         />
                                                         <span className="slider round"></span>
                                                      </label>
                                                   </div>

                                                </div>
                                                <hr className="m-0" style={{ opacity: "1", color: "#fff" }} />
                                                <h5 onClick={handlePopup} style={{ cursor: "pointer", marginTop: "15px" }}>Face Narration instruction </h5>
                                                {curElem.faceNarration.enable ?
                                                   <>
                                                      <div className="upload-box " style={{ padding: 0, height: "148px", overflow: "hidden" }}>

                                                         <div className="upload-box-in" style={{ margin: "42px", height: "50%", width: "100%", display:"flex", justifyContent: "space-between", alignItems: "center" }} >
                                                            <div>
                                                               <BsUpload />
                                                               <p>Upload your face Image </p>
                                                               <input
                                                                  className="cursor-pointer"
                                                                  type="file"
                                                                  onChange={(e) => { onInputFile(e, index) }}
                                                               />
                                                            </div>
                                                            {uploadLoader ?
                                                               <i className="fa fa-spinner fa-spin" style={{ fontSize: "30px", color: "#1478D3" }} />
                                                               :
                                                               <div style={{ width: "85px", height: "auto" }}>
                                                                  <img src={curElem.faceNarration.media.url ? curElem.faceNarration.media.url : dummyImg} alt="" className="img-fluid" style={{ width: "100%" }} />
                                                               </div>
                                                            }
                                                         </div>
                                                      </div>
                                                      <div className="inpx-wrap">
                                                         <label className="pb-2 d-flex" htmlFor={`headMovement${index}`} style={{ cursor: "pointer", color: "#fff" }}>
                                                            <input
                                                               type='checkbox'
                                                               name='headMovement'
                                                               id={`headMovement${index}`}
                                                               onChange={(e) => { handleChange2(index, e) }}
                                                               className='mx-2'
                                                               checked={curElem?.faceNarration?.headMovement}
                                                               style={{ height: 20, width: 20 }}
                                                            />
                                                            Include head movement.</label>

                                                      </div>
                                                      <div className="form-group form-group-alt mt-0">
                                                         <div>
                                                            <span className="mb-2" htmlFor="" style={{ color: "#fff", display: "inline-block" }}>Coverage</span>
                                                            <select
                                                               className="form-control pt-0"
                                                               name="coverage"
                                                               value={curElem.faceNarration?.coverage}
                                                               style={{ borderColor: "#007bff" }}
                                                               onChange={(e) => { handleChange2(index, e) }}
                                                            >
                                                               <option value={10}>10%</option>
                                                               <option value={25}>25%</option>
                                                               <option value={50}>50%</option>
                                                            </select>

                                                            <div className={`alert alert-${+curElem.faceNarration?.coverage === 10 ? 'success' :
                                                               +curElem.faceNarration?.coverage === 25 ? 'warning' :
                                                                  +curElem.faceNarration?.coverage === 50 ? 'danger' : ''} mt-2 text-center `} role="alert">
                                                               {+curElem.faceNarration?.coverage === 10 ? 'Video Rendering will take normal time' :
                                                                  +curElem.faceNarration?.coverage === 25 ? 'Rendering will take slightly more time then normal' :
                                                                     +curElem.faceNarration?.coverage === 50 ? 'Rendering will take longer time then normal.' : ''}
                                                            </div>

                                                         </div>
                                                      </div>
                                                   </>
                                                   : ""
                                                }
                                             </Accordion.Body>
                                          </Accordion.Item>
                                       )
                                    })
                                    : ""}
                              </Accordion>
                           </div>
                        </div>
                     </div>


                  </div>
               </div>
               <Modal className="VideoModal script_modal dark" show={handleModelPopup} onHide={handlePopup} centered>
                  <Modal.Body>
                     <div className="Face_narration">
                        <h2>Instructions for adding talking head</h2>
                        {/* <h2>Do's and Don't Checklist</h2> */}
                        <div className="col-lg-12 col-12" style={{ margin: "0 auto" }}>
                           <div className="Face_narration_section">
                              <ul className='Face_narration_do'>
                                 <div className='Thumb'>  <img src={thumb} alt="Thumb" /> <span>The Do's</span></div>
                                 <li> <div><AiOutlineCheck className="green" /></div><span>Use a single face in the frame  </span></li>
                                 <li> <div><AiOutlineCheck className="green" /></div><span>Opt for a close-up shot of your full face.</span></li>
                                 <li> <div><AiOutlineCheck className="green" /></div><span>Position your face in the top half of the frame.</span></li>
                                 <li> <div><AiOutlineCheck className="green" /></div><span>Ensure the image is in HD quality.</span></li>
                                 <li> <div><AiOutlineCheck className="green" /></div><span>Choose a solid, clean background.</span></li>
                                 <li> <div><AiOutlineCheck className="green" /></div><span>Make sure the lighting is bright and even.</span></li>
                                 <li> <div><AiOutlineCheck className="green" /></div><span>Make sure the face is of a human being.</span></li>
                                 <div className="Face_narration_img">
                                    <img src={face} alt="" />
                                 </div>
                              </ul>

                              <ul className='Face_narration_dont'>
                                 <div className='Thumbs'>   <img src={unthumb} alt="" />  <span>The Don'ts</span></div>
                                 <li> <div><AiOutlineCheck className="red" /></div><span>Avoid using multiple faces in one image.</span></li>
                                 <li> <div><AiOutlineCheck className="red" /></div><span>Do not use distant or partial face shots.</span></li>
                                 <li> <div><AiOutlineCheck className="red" /></div><span>Keep your face away from the bottom of the frame.</span></li>
                                 <li> <div><AiOutlineCheck className="red" /></div><span>Don't use blurry or low-quality pictures.</span></li>
                                 <li> <div><AiOutlineCheck className="red" /></div><span>Refrain from using busy or patterned backgrounds.</span></li>
                                 <li> <div><AiOutlineCheck className="red" /></div><span>Stay away from dimly lit or shadowy lighting.</span></li>
                                 <li> <div><AiOutlineCheck className="red" /></div><span>Do not use face of any pet or any other animal.</span></li>

                                 <div className="Face_narration_img">
                                    <img src={face2} alt="" />
                                 </div>
                              </ul>
                           </div>
                        </div>

                     </div>
                  </Modal.Body>
               </Modal>
            </section>
            <Footer />

         </>
         : <div className="loader-sec">
            <div className="loader">
            </div>
         </div>
   )
}
export default ReviewScripts;